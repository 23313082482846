import { updateField } from 'vuex-map-fields';
import {
    DELETE_BOOKMARK,
    SET_BOOKMARK,
    SET_BOOKMARKS_LIST,
    SET_BOOKMARKS_LIST_LOADMORE
} from './mutation-types'

export const mutations = {
    updateField,
    [SET_BOOKMARKS_LIST](state, payload) {
        state.bookmarks = [...payload.data]
        state.total = payload.total
        state.last_page = payload.last_page
    },
    [SET_BOOKMARKS_LIST_LOADMORE](state, payload) {
        state.bookmarks = [...state.bookmarks, ...payload.data]
    },
    [SET_BOOKMARK](state, payload) {
        state.bookmarks = [...payload, ...state.bookmarks]
        state.total += payload.length
    },
    [DELETE_BOOKMARK](state, payload) {
        state.bookmarks = state.bookmarks.filter((item) => item.id !== payload.id)
        state.total -= 1
    }
}
