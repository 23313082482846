export const SET_MAILING_LIST = 'SET_MAILING_LIST'
export const SET_MEMBERS_GROUP = 'SET_MEMBERS_GROUP'
export const CREATE_MAILING = 'CREATE_MAILING'
export const DELETE_MAILING = 'DELETE_MAILING'
export const SET_POPULATE_PRESETS = 'SET_POPULATE_PRESETS'
export const SET_MEMBERS_LIST = 'SET_MEMBERS_LIST'
export const SET_MAILING_LIST_TOTALS_LOADING = 'SET_MAILING_LIST_TOTALS_LOADING'
export const SET_MAILING_LIST_TOTAL = 'SET_MAILING_LIST_TOTAL'
// TEMPLATES
export const SET_MAILING_TEMPLATES_LIST = 'SET_MAILING_TEMPLATES_LIST'
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE'
// LOG
export const SET_MAILS_LOG_LIST = 'SET_MAILS_LOG_LIST'
export const SET_MAILS_LOG_TOTAL = 'SET_MAILS_LOG_TOTAL'
export const SET_MAILS_LOG_TOTALS_LOADING = 'SET_MAILS_LOG_TOTALS_LOADING'
