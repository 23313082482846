import { updateField } from 'vuex-map-fields';
import { cloneDeep } from 'lodash';
import { findAndReplaceInArray } from '@/helpers/utils/index.js'
import {
    SET_FEEDBACKS_LIST,
    SET_PRESETS_LIST,
    UPDATE_FEEDBACK,
    UPDATE_FEEDBACK_STATUS,
    CLEAR_FEEDBACKS_LIST,
    SET_TOTALS_LOADING,
    SET_FEEDBACKS_SUMMARY,
    SET_FEEDBACKS_LIST_PAGES,
    SET_FEEDBACKS_LIST_LOADING
} from './mutation-types'

import { initialState } from './state'

export const mutations = {
    updateField,
    [CLEAR_FEEDBACKS_LIST](state) {
        Object.assign(state, cloneDeep(initialState))
    },
    [SET_FEEDBACKS_LIST](state, payload) {
        state.feedbacksList = payload.data
    },
    [SET_FEEDBACKS_LIST_LOADING](state, payload) {
        state.feedbacksListLoading = payload
    },
    [SET_TOTALS_LOADING](state, payload) {
        state.totalsLoading = payload
    },
    [SET_FEEDBACKS_SUMMARY](state, payload) {
        state.summary = { ...payload }
    },
    [SET_FEEDBACKS_LIST_PAGES](state, payload) {
        state.lastPage = payload.last_page
        state.total = payload.total
    },
    [UPDATE_FEEDBACK](state, { payload, id }) {
        findAndReplaceInArray(state.feedbacksList, id, 'id', payload)
    },
    [UPDATE_FEEDBACK_STATUS](state, { payload, id }) {
        id.forEach((feedbackId) => {
            findAndReplaceInArray(state.feedbacksList, feedbackId, 'id', { status: payload.status })
        });
    },
    [SET_PRESETS_LIST](state, payload) {
        state.preset = payload
    }
}
