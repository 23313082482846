export const SET_ADMIN = 'SET_ADMIN'
export const UPDATE_SELF = 'UPDATE_SELF'

export const CHANGE_AVATAR = 'CHANGE_AVATAR'
export const LOGOUT_ADMIN = 'LOGOUT_ADMIN'

export const SET_TOUCH_INTERVAL = 'SET_TOUCH_INTERVAL'
export const CLEAR_TOUCH_INTERVAL = 'CLEAR_TOUCH_INTERVAL'

export const SET_TWOFA_STATUS = 'SET_TWOFA_STATUS'
