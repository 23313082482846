export default [
    {
        path: '/support',
        // component: () => import(/* webpackChunkName: "support" */ '@/views/Dashboard/Support'),
        meta: {
            requiresAuth: true,
            name: 'Support',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        }
    },
    {
        path: '/support/forum',
        name: 'support-forum',
        component: () => import(/* webpackChunkName: "support" */ '@/views/Support/SupportForum'),
        meta: {
            requiresAuth: true,
            name: 'Support',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        }
    },
    {
        path: '/support/client',
        name: 'support-client',
        component: () => import(/* webpackChunkName: "support" */ '@/views/Support/SupportClientTable'),
        meta: {
            requiresAuth: true,
            name: 'Client tickets',
            layout: 'dashboard-layout',
            breadcrumb: 'Client',
            scrollToTop: true,
            permissions: [],
            keepAlive: true
        }
    },
    {
        path: '/support/writer',
        name: 'support-writer',
        component: () => import(/* webpackChunkName: "support" */ '@/views/Support/SupportWriterTable'),
        meta: {
            requiresAuth: true,
            name: 'Writer tickets',
            layout: 'dashboard-layout',
            breadcrumb: 'Writer',
            scrollToTop: true,
            permissions: [],
            keepAlive: true
        }
    },
    {
        path: '/support/:type/:id',
        name: 'support-details',
        component: () => import(/* webpackChunkName: "support" */ '@/views/Support/SupportDetails'),
        meta: {
            requiresAuth: true,
            name: 'Support',
            layout: 'dashboard-layout',
            breadcrumb() {
                const { id, type } = this.$route.params;

                return {
                    label: id,
                    parent: type === 'client' ? 'support-client' : 'support-writer'
                };
            },
            permissions: [],
            keepAlive: true,
            scrollToTop: true
        }
    }
]
