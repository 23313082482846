export default [
    {
        path: '/promocodes',
        name: 'promocodes',
        component: () => import(/* webpackChunkName: "promocodes" */ '@/views/Promocodes/Index'),
        meta: {
            requiresAuth: true,
            name: 'Promocodes',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: [],
            keepAlive: true
        }
    }
]
