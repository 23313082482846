import Api from '@/helpers/api/index.js'
import {
    ACTION_GET_DOMAINS
} from './action-types'

import {
    SET_DOMAINS
} from './mutation-types'

export const actions = {
    // GET CLIENTS
    async [ACTION_GET_DOMAINS]({ commit }) {
        const { data } = await Api.get('/api/data/domains')
        commit(SET_DOMAINS, data.list)
    }
}
