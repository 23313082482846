import Api from '@/helpers/api/index.js'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'

import {
    ACTION_ASIGN_WRITER,
    ACTION_CREATE_NOTE,
    ACTION_DELETE_NOTE,
    ACTION_EDIT_NOTE,
    ACTION_GET_NOTES,
    ACTION_GET_ORDER,
    ACTION_GET_ORDER_FILES,
    ACTION_GET_ORDER_LIST,
    ACTION_GET_ORDER_LIST_WITHOUT_PRESET,
    ACTION_GET_ORDER_LIST_WITH_PRESET,
    ACTION_GET_ORDER_PRESETS,
    ACTION_GET_ORDER_TOTALS,
    ACTION_GET_WRITERS_LIST_COMPLETED,
    ACTION_ORDER_DEADLINE,
    ACTION_ORDER_DROP,
    ACTION_ORDER_FEE,
    ACTION_ORDER_LIST_LIVE_UPDATE,
    ACTION_ORDER_REVISION,
    ACTION_UPLOAD_ORDER_FILE,
    ACTION_NOTIFY_COMPLETE_ORDER,
    ACTION_NOTIFY_UNPAID_ORDER,
    ACTION_ORDER_CHANGE_STATUS
} from './action-types'

import {
    DELETE_NOTE,
    EDIT_NOTE,
    ORDER_DROP,
    POST_NOTE,
    REVISION_ORDER,
    SET_FILES_LIST,
    SET_NOTES,
    SET_ORDERS_COMPLETED_LIST,
    SET_ORDER_LIST,
    SET_ORDER_LIST_LIVE,
    SET_ORDER_LIST_LOADING,
    SET_ORDER_LIST_PAGES,
    SET_ORDER_SUMMARY,
    SET_PAYLOAD,
    SET_PRESETS,
    SET_PRESETS_LOADING,
    SET_SW_TOTAL,
    SET_TIMER,
    SET_TOTALS_LOADING,
    SET_WRITER,
    UPDATE_FILES_LIST,
    SET_DEADLINE,
    SET_ORDER_STATUS
} from './mutation-types'

export const actions = {
    // GET ORDER LIST
    async [ACTION_GET_ORDER_LIST]({ commit, dispatch }, payload) {
        // GET LIST
        commit(SET_ORDER_LIST_LOADING, true)
        let list = null
        if (payload.preset) {
            list = await dispatch(ACTION_GET_ORDER_LIST_WITH_PRESET, payload)
        } else {
            list = await dispatch(ACTION_GET_ORDER_LIST_WITHOUT_PRESET, payload)
        }
        commit(SET_PAYLOAD, removeEmptyParamsFromRequest({ ...payload }))
        commit(SET_ORDER_LIST, {
            data: list.data.data,
            current_page: list.data.current_page,
            total: list.data.data.length
        })
        commit(SET_ORDER_LIST_LOADING, false)
    },

    async [ACTION_GET_ORDER_LIST_WITH_PRESET](_, payload) {
        const list = await Api.get('/api/order/list-by-preset', removeEmptyParamsFromRequest({ ...payload, mode: 'list' }))
        return list
    },

    async [ACTION_GET_ORDER_LIST_WITHOUT_PRESET](_, payload) {
        const list = await Api.get('/api/order/list-by-params', removeEmptyParamsFromRequest({ ...payload, mode: 'list' }))
        return list
    },

    // LIVE UPDATE
    async [ACTION_ORDER_LIST_LIVE_UPDATE]({ commit, getters, dispatch }) {
        if (getters.getterRequestPayload.preset) {
            await Api.get('/api/order/list-by-preset', getters.getterRequestPayload, {}, false)
                .then(({ data }) => {
                    commit(SET_ORDER_LIST_LIVE, data)
                    const timerid = setTimeout(() => {
                        dispatch(ACTION_ORDER_LIST_LIVE_UPDATE)
                    }, 1000 * 60);
                    commit(SET_TIMER, timerid)
                })
        } else {
            await Api.get('/api/order/list-by-params', getters.getterRequestPayload, {}, false)
                .then(({ data }) => {
                    commit(SET_ORDER_LIST_LIVE, data)
                    const timerid = setTimeout(() => {
                        dispatch(ACTION_ORDER_LIST_LIVE_UPDATE)
                    }, 1000 * 60);
                    commit(SET_TIMER, timerid)
                })
        }
    },

    // GET PRESETS
    async [ACTION_GET_ORDER_PRESETS]({ commit }) {
        // commit(SET_PRESETS_LOADING, true)
        const presets = await Api.get('/api/order/list-presets')
        const tmp = presets.data.map((item) => ({
            ...item,
            preset: item.value,
            count: item.counters?.count || 0
        }))
        commit(SET_PRESETS, {
            data: tmp
        })
        commit(SET_PRESETS_LOADING, false)
    },

    // GET TOTALS
    async [ACTION_GET_ORDER_TOTALS]({ commit }, payload) {
        commit(SET_TOTALS_LOADING, true)
        let totals
        const payload2 = removeEmptyParamsFromRequest({ ...payload })
        if (payload2?.preset) {
            totals = await Api.get('/api/order/list-by-preset', { ...payload2 }, {}, false)
        } else {
            totals = await Api.get('/api/order/list-by-params', { ...payload2 }, {}, false)
        }
        commit(SET_ORDER_SUMMARY, {
            total: totals.data.count,
            total_sum: totals.data.total,
            total_sw_sum: totals.data.sw_total
        })
        commit(SET_ORDER_LIST_PAGES, {
            last_page: Math.ceil(totals.data.count / payload.per_page),
            total: totals.data.count
        })

        commit(SET_TOTALS_LOADING, false)
    },

    // GET ORDER
    async [ACTION_GET_ORDER](ctx, payload) {
        const { data } = await Api.get('/api/order/fetch-order', payload, {}, false)
        return data
    },
    // GET FILES LIST
    async [ACTION_GET_ORDER_FILES]({ commit }, payload) {
        const { data } = await Api.get('/api/files/order/get-list', payload, { dialog: false }, false)
        const clientFiles = data.client.map((file) => ({
            ...file,
            owner_type: 'client'
        }))
        const writerFiles = data.writer.map((file) => ({
            ...file,
            owner_type: 'writer'
        }))
        commit(SET_FILES_LIST, clientFiles.concat(writerFiles))
    },
    // UPLOAD FILES
    async [ACTION_UPLOAD_ORDER_FILE]({ commit }, { formData, callback }) {
        const { data } = await Api.post('/api/file-storage/order/upload-file', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: callback
        })
        commit(UPDATE_FILES_LIST, data)
        return data
    },
    // ASIGN WRITER
    async [ACTION_ASIGN_WRITER]({ commit }, payload) {
        const { data } = await Api.put('/api/order/action/assign', { ...payload })
        commit(SET_WRITER, data)
        return data
    },
    // FEE
    async [ACTION_ORDER_FEE]({ commit }, payload) {
        const { data } = await Api.put('/api/order/action/set-fee', { ...payload })
        commit(SET_SW_TOTAL, payload)
        return data
    },
    // DROP
    async [ACTION_ORDER_DROP]({ commit }, payload) {
        const { data } = await Api.put('/api/order/action/drop', { ...payload })
        commit(ORDER_DROP, data)
        // return data
    },
    // DEADLINE
    async [ACTION_ORDER_DEADLINE]({ commit }, payload) {
        const { data } = await Api.put('/api/order/action/set-deadline', { ...payload })
        commit(SET_DEADLINE, data)
        return data
    },

    // Revision request
    async [ACTION_ORDER_REVISION]({ commit }, payload) {
        const { data } = await Api.post('/api/order-message/create-revision', { ...payload })
        commit(REVISION_ORDER, data)
        return data
    },

    // GET NOTE LIST
    async [ACTION_GET_NOTES]({ commit }, payload) {
        const { data } = await Api.get('/api/order-note/list-by-params', payload)
        commit(SET_NOTES, data)
    },

    // CREATE NOTE
    async [ACTION_CREATE_NOTE]({ commit, rootGetters }, payload) {
        const { data } = await Api.post('/api/order-note/create', payload)
        const admin = rootGetters['client/getterMainInfo']
        data.admin = {
            id: admin.id,
            avatar_url: admin.avatar_url,
            avatar: admin.avatar_url,
            firstname: admin.firstname,
            lastname: admin.lastname
        }
        commit(POST_NOTE, data)
    },

    // DELETE NOTE
    async [ACTION_DELETE_NOTE]({ commit }, id) {
        await Api.delete('/api/order-note/delete', { id })
        commit(DELETE_NOTE, id)
    },

    // EDIT NOTE
    async [ACTION_EDIT_NOTE]({ commit }, payload) {
        const { data } = await Api.put('/api/order-note/update', payload)
        commit(EDIT_NOTE, payload)
        return data
    },

    // GET WRITERS ORDERS COMPLETE
    async [ACTION_GET_WRITERS_LIST_COMPLETED]({ commit }, payload) {
        const { data } = await Api.get('/api/order/list-complete', payload)
        commit(SET_ORDERS_COMPLETED_LIST, data)
    },

    async [ACTION_NOTIFY_COMPLETE_ORDER](ctx, payload) {
        await Api.get('/api/client/notify/rate-completed-order', payload)
    },

    async [ACTION_NOTIFY_UNPAID_ORDER](ctx, payload) {
        await Api.get('/api/client/notify/unpaid-order', payload)
    },

    // ORDER CHANGE STATUS
    async [ACTION_ORDER_CHANGE_STATUS]({ commit }, payload) {
        const { data } = await Api.put('/api/order/action/cancel/credit', payload)
        commit(SET_ORDER_STATUS, data)
    }
}
