<template>
    <div
        v-if="getterMainInfo"
        class="d-flex main-header"
        style="position:relative"
    >
        <search />
        <bookmark-menu />
        <notification-list />

        <v-menu
            bottom
            offset-y
            class="header_menu"
        >
            <template v-slot:activator="{ on, attrs }">
                <div
                    v-bind="attrs"
                    class="px-4 header_avatar"
                    v-on="on"
                >
                    <span style="position: relative; padding-right: 10px">
                        <avatar-rolles
                            access-status="admin"
                            :firstname="getterMainInfo.firstname"
                            :lastname="getterMainInfo.lastname"
                            :avatar-url="getterMainInfo.avatar_url"
                            :avatar-fallback="getterMainInfo.avatar_fallback"
                            avatar-size="45"
                        />
                    </span>
                </div>
            </template>

            <v-list class="header_mnu">
                <v-list-item
                    v-for="(item, index) in menu"
                    :key="index"
                >
                    <v-list-item-title>
                        <v-hover
                            v-slot="{ hover }"
                        >
                            <router-link
                                v-if="!item.list"
                                :to="{name: item.route_name}"
                                class="py-2 d-flex align-center  text-decoration-none"
                                :class="hover ? 'accent--text' : 'black--text'"
                            >
                                <img
                                    :src="item.icon"
                                    alt=""
                                    class="mr-2"
                                    style="width: 24px; height: 24px"
                                >
                                <span class="font-weight-medium">{{ item.title }}</span>
                            </router-link>

                            <v-list-group
                                v-else
                                class="menu"
                                :value="false"
                                @click.prevent.stop
                            >
                                <template v-slot:activator>
                                    <div class="d-flex align-center">
                                        <img
                                            :src="item.icon"
                                            alt=""
                                            class="mr-2"
                                            style="width: 24px; height: 24px"
                                        >
                                        <span
                                            class="font-weight-medium"
                                            style="position: relative;"
                                        >
                                            {{ item.title }}
                                        </span>
                                    </div>
                                </template>
                                <v-list-item
                                    v-for="(subitem, subindex) in item.list"
                                    :key="subindex"
                                >
                                    <v-hover
                                        v-slot="{ hover: subHover }"
                                    >
                                        <router-link
                                            :to="{name: subitem.route_name, query: { preset: subitem.query } }"
                                            class="py-2 font-weight-medium d-flex align-center  text-decoration-none subitem"
                                            :class="subHover ? 'accent--text' : 'black--text'"
                                        >
                                            {{ subitem.text }}
                                            <span
                                                v-if="subitem.counter === 0 || subitem.counter"
                                                class="count"
                                                :style="`background-color: ${statusBgColor(subitem.text)}; color: ${statusTextColor(subitem.text)}`"
                                            >
                                                {{ subitem.counter }}
                                            </span>
                                        </router-link>
                                    </v-hover>
                                </v-list-item>
                            </v-list-group>
                        </v-hover>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item style="cursor: pointer">
                    <v-list-item-title>
                        <v-hover
                            v-slot="{ hover }"
                        >
                            <span
                                class="font-weight-medium mr-2 d-flex align-center"
                                :class="hover ? 'accent--text' : 'black--text'"
                                @click="logout()"
                            >
                                <!-- <v-icon
                                    :color="hover ? 'accent' : 'black'"
                                    class="mr-2"
                                >
                                    mdi-logout
                                </v-icon> -->
                                <img
                                    src="@/assets/images/svg/logout 3.svg"
                                    class="mr-2"
                                    style="width: 24px; height: 24px"
                                >
                                SIGN OUT
                            </span>
                        </v-hover>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';

import BookmarkMenu from '@/components/Header/BookmarkMenu'
import Search from '@/components/Header/Search'
import NotificationList from '@/components/Header/NotificationList'
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles'

import Auth from '~/helpers/auth/index.js'
import { statusBgColor, statusTextColor } from '@/helpers/order/statusHelper'
// import { MENU_LIST } from '@/constants/SidebarMenu'
import { DashboardMenuBuilder } from '@/services/dashboardService';

// import {
//     ACTION_GET_COUNTS
// } from '@/store/modules/dashboard/action-types'

const {
    mapActions: mapDashboardActions
} = createNamespacedHelpers('dashboard')

export default {
    name: 'MainHeader',
    components: {
        BookmarkMenu,
        AvatarRolles,
        NotificationList,
        Search
    },
    data() {
        return {
            bookmarks: [],
            loading: true,
            menu: null
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterMainInfo',
            'getterAdminId'
        ])
        // ...mapGetters('dashboard', [
        //     'getFullMenu'
        // ]),
        // menuItems() {
        //     return MENU_LIST
        // }
    },
    async created() {
        if (this.$route.name !== 'dashboard') {
            // await this[ACTION_GET_COUNTS]()
        }
        const dashboard = new DashboardMenuBuilder()
        // await dashboard.getCounters()
        this.menu = dashboard.fullMenu(dashboard.userMenu)
    },
    methods: {
        statusBgColor,
        statusTextColor,
        ...mapDashboardActions([
            // ACTION_GET_COUNTS
        ]),
        async logout() {
            Auth.logout()
            Auth.logoutVuex()
            window.location.href = '/login'
        }
    }
}
</script>

<style lang="scss">
    .menu .v-list-item__icon:last-of-type:not(:only-child) {
        position: absolute;
        right: 0;
    }
    .bookmark_mnu{
        width: 420px;
    }
    .header_menu{
       z-index: 99;
    }
    .notification-menu{
        z-index: 99;
        width: 320px;
    }
    .bookmark_details{
        background: rgb(221, 241, 255);
        border-radius: 0 10px 10px 10px;
        margin-top: 10px;
    }
    .v-list-group__header {
        padding: 0!important;
    }
    .status{
        &--success {
            background: rgba(89, 199, 77, 0.1);
            color: #59c74d;
        }
        &--free {
            background: rgba(17, 97, 224, 0.1);
            color: rgba(17, 97, 224, 1);;
        }
        &--attention {
            background: rgba(255, 105, 12, 0.1);
            color: #82b1ff;
        }
        &--revision {
            background: rgba(136, 86, 242, 0.1);
            color: #8856F2;
        }
        &--error {
            background: rgba(212, 28, 28, 0.1);
            color: #d41c1c;
        }
    }
    span.count {
        margin-left: 10px;
        padding: 4px;
        border-radius: 4px;
        position: relative;

    }
    .subitem{
        justify-content: space-between;
        width: 100%;
        text-transform: capitalize;
        font-size: 15px;
    }
    .new {
        position: absolute;
        top: -5px;
        right: -5px;
        z-index: 2;
        background: #DD3E3E;
        border-radius: 50%;
        width: 13px;
        height: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 10px;
        font-weight: bold;
    }
</style>
