import Vue from 'vue'

import date from '@/helpers/date'

export default {
    install: () => {
        Vue.prototype.$date = date
        Vue.$date = date
    }
}
