// import { SchemaAdmin } from '@/models/SchemaAdmin.js'
import { cloneDeep } from 'lodash'

export const initialState = {
    form_data: {},
    mailingList: [],
    mailingTotal: 0,
    mailingCurrentPage: 0,
    mailingLastPage: 0,
    mailingTotalsLoading: true,
    populatePresets: [],
    membersList: [],
    // TEMPLATES
    mailingTemplatesList: [],
    // LOG
    mailsloglist: [],
    mailslogTotal: 0,
    logCurrentPage: 0,
    logLastPage: 0,
    logTotalsLoading: true
}

export const state = cloneDeep(initialState)
