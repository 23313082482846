// import { SchemaAdmin } from '@/models/SchemaAdmin.js'

export const initialState = {
    writersList: [],
    currentPage: 0,
    lastPage: 0,
    total: 0,
    feedbacks: [],
    noteList: [],
    writer: null,
    presets: [],
    summary: {},
    totalsLoading: true,
    writersListLoading: true,
    applicant_presets: [],
    writersShortList: [],
    payouts: [],
    payoutsListLoading: true,
    payoutsCurrentPage: 0,
    payoutsTotal: 0,
    payoutsLastPage: 0,
    payoutsSummary: {},
    payoutsTotalsLoading: true,
    payoutPresetsLoading: true,
    payoutPresets: []
}

export const state = { ...initialState }
