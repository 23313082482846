import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'

function dhm(ms) {
    let year;
    let month;
    let day;
    let hour;
    let minute;
    let second;

    second = Math.floor(ms / 1000);
    minute = Math.floor(second / 60);
    second %= 60;
    hour = Math.floor(minute / 60);
    minute %= 60;
    day = Math.floor(hour / 24);
    hour %= 24;
    month = Math.floor(day / 30);
    day %= 30;
    // eslint-disable-next-line prefer-const
    year = Math.floor(month / 12);
    month %= 12;

    const obj = {
        year, month, day, hour, minute
    }
    let result = ''
    let counter = 0
    Object.entries(obj).forEach((item) => {
        if (item[1] > 0 && counter < 2) {
            result += `${item[1]} ${item[1] > 1 ? `${item[0]}s` : item[0]} `
            counter += 1
        }
    })
    return result
}

dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(duration)

const humanizeDate = (dateString) => dayjs(dayjs(dateString)).format('MMMM D, YYYY h:mm A')

const fullDate = (dateString) => dayjs(dayjs(dateString)).format('ddd, MMM DD, YYYY h:mm A')

const shortFormat = (dateString) => dayjs(dayjs(dateString)).format('MMM D, YYYY')

const shortDate = (dateString) => dayjs(dayjs(dateString)).format('D MMM')

const diffBetween = (dateStart, dateEnd) => dayjs(dayjs(dateStart)).diff(dayjs(dateEnd), 'minute')

const diffBetweenNow = (dateEnd) => {
    const date2 = dayjs();
    const diff = date2.diff(dayjs(dateEnd), 'day', true);
    const days = Math.floor(diff);
    const hours = Math.floor((diff - days) * 24);
    return `${days > 0 ? `${days} days` : ''} ${hours} hours ago`;
}

const relativeTimeFromNow = (date) => {
    const clientDay = dayjs(date)
    const dayjsNow = dayjs().toISOString()
    return clientDay.from(dayjsNow)
}

const dateFromNow = (date) => dayjs(dayjs(date)).fromNow()

const fromWithText = (date) => dayjs(dayjs(date)).fromNow()

const shortText = (date) => dayjs(dayjs(date)).format('ddd, MMM D, YY, h:mm A')

const shortTextSymbol = (date) => dayjs(dayjs(date)).format('h[h] m[m]')

const shortDateSymbol = (date) => dayjs(date).format('D MMM YY')
const time = (date) => dayjs(dayjs(date)).format('h:mm A')

const fromNowFullText = (dateEnd) => {
    const date2 = dayjs();
    const diff = dayjs(dateEnd).diff(dayjs(date2), true);
    return diff <= 0 ? `${dhm(Math.abs(diff)) ? dhm(Math.abs(diff)) : 'few seconds'} ago` : dhm(diff)
}

const dateColorDue = (date) => {
    const now = dayjs()
    const parse_date = dayjs(date)

    if (parse_date < now) {
        return 'red'
    }
    if (parse_date <= now.add(6, 'day')) {
        return 'orange'
    }

    return 'black'
}

function formatDuration(dateString) {
    const date = dayjs(dateString);
    const now = dayjs();

    const years = now.diff(date, 'year');
    const months = now.diff(date, 'month') % 12;
    const days = now.diff(date, 'day') % 30;
    const hours = now.diff(date, 'hour') % 24;

    if (months === 0 && years === 0) {
        return `${hours}h ${days}d`;
    }
    if (months !== 0 && years === 0) {
        return `${days}d ${months}m`;
    }
    if (months === 0 && years !== 0) {
        return `${days}d ${months}m ${years}y`;
    }
    return `${days}d ${months}m ${years}y`
}
export default {
    humanizeDate,
    shortDate,
    diffBetween,
    relativeTimeFromNow,
    dateFromNow,
    diffBetweenNow,
    fromWithText,
    shortText,
    fullDate,
    shortFormat,
    dateColorDue,
    fromNowFullText,
    time,
    shortTextSymbol,
    shortDateSymbol,
    formatDuration
}
