import { updateField } from 'vuex-map-fields';
import {
    SET_PAYROLL_LIST
} from './mutation-types'

export const mutations = {
    updateField,
    [SET_PAYROLL_LIST](state, payload) {
        state.payrollList = payload.data
        state.currentPage = payload.from
        state.lastPage = payload.last_page
        state.total = payload.total
    }
}
