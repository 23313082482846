export default [
    {
        path: '/writer-credits',
        component: () => import(/* webpackChunkName: "writers" */ '@/views/Credits/CreditsWriter'),
        meta: {
            requiresAuth: true,
            name: 'CreditsWriter',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        },
        name: 'writer-credits'

    },
    {
        path: '/client-credits',
        component: () => import(/* webpackChunkName: "client" */ '@/views/Credits/CreditsClient'),
        meta: {
            requiresAuth: true,
            name: 'CreditsClient',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        },
        name: 'client-credits'
    }
]
