<template>
    <div class="breadcrumbs">
        <v-breadcrumbs :items="breadcrumbs" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            breadcrumbs: []
        }
    },
    watch: {
        $route() {
            this.breadcrumbsCreate()
        }
    },
    created() {
        this.breadcrumbsCreate()
    },
    methods: {
        breadcrumbsCreate() {
            if (this.$route.name === 'dashboard') {
                this.breadcrumbs = [
                    {
                        text: 'Dashboard',
                        disabled: false,
                        exact: true,
                        to: { name: 'dashboard' }
                    }
                ]
                return
            }
            this.breadcrumbs = this.$breadcrumbs.map((item) => {
                let tmp = null
                if (typeof item.meta.breadcrumb === 'function') {
                    tmp = item.meta.breadcrumb.call(this, this.$route.params)
                }
                return {
                    text: tmp ? tmp.label : item.meta.name,
                    disabled: false,
                    exact: true,
                    to: { name: item.name }
                }
            })
            if (this.$route.name !== 'dashboard') {
                this.breadcrumbs.unshift({
                    text: 'Dashboard',
                    disabled: false,
                    exact: true,
                    to: { name: 'dashboard' }
                })
            }

            this.breadcrumbs = this.breadcrumbs.filter((i) => i.text)
            this.breadcrumbs[this.breadcrumbs.length - 1].disabled = true
        }
    }
}
</script>

<style lang="scss">
    .breadcrumbs{
        .v-breadcrumbs{
            padding-top: 24px !important;
            padding-bottom: 0 !important;
            li {
                & > a {
                    color: #1F2939;
                }
                &:first-child {
                    & > a {
                        font-weight: bold;
                        color: #1F2939!important;
                    }
                }
                &.v-breadcrumbs__divider{
                    padding: 0 4px;
                }
            }
        }
    }
</style>
