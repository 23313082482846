import { updateField } from 'vuex-map-fields';
import { cloneDeep } from 'lodash';
import { initialState } from '@/store/modules/promo/state';
import { findAndReplaceInArray } from '@/helpers/utils/index.js'
import {
    CREATE_PROMO,
    SET_PROMO_LIST,
    SET_STATUS_PROMO,
    SET_PRESETS,
    SET_PROMO_LIST_LOADING,
    SET_TOTALS_LOADING,
    SET_PROMO_LIST_PAGES,
    CLEAR_PROMO_LIST,
    SET_PROMO_SUMMARY
} from './mutation-types'

export const mutations = {
    updateField,
    [CLEAR_PROMO_LIST](state) {
        Object.assign(state, cloneDeep(initialState))
    },
    [SET_PROMO_LIST](state, payload) {
        state.promoList = payload.data
        state.currentPage = payload.current_page
    },
    [SET_PROMO_LIST_PAGES](state, payload) {
        state.lastPage = payload.last_page
        state.total = payload.total
    },
    [CREATE_PROMO](state, payload) {
        state.promoList.unshift(payload)
    },
    [SET_STATUS_PROMO](state, payload) {
        findAndReplaceInArray(state.promoList, payload.discount_code, 'discount_code', { status_active: payload.new })
    },
    [SET_PROMO_LIST_LOADING](state, payload) {
        state.promoListLoading = payload
    },
    [SET_TOTALS_LOADING](state, payload) {
        state.totalsLoading = payload
    },
    [SET_PRESETS](state, payload) {
        state.presets = payload.data
    },
    [SET_PROMO_SUMMARY](state, payload) {
        state.summary = { ...payload }
    }
}
