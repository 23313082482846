import Vue from 'vue'
import VueRouter from 'vue-router'

import Auth from '@/helpers/auth/index.js'
import { eventBus } from '@/helpers/event-bus/'
import routesAuth from './routes/auth.js'
import routesDashboard from './routes/dashboard.js'
import routesOrder from './routes/order.js'
import routesSupport from './routes/support.js'
import routesClient from './routes/client.js'
import routesWriters from './routes/writers'
// import routesApplicant from './routes/applicant'
import routesUserSetting from './routes/user_setting.js'
import routesPayroll from './routes/payroll.js'
import routesAccess from './routes/access.js'
import routesFeedbacks from './routes/feedbacks.js'
import routesEmails from './routes/emails.js'
import routesPromocodes from './routes/promocodes.js'
import routesBatch from './routes/batch.js'
import routesCrud from './routes/crud.js'
import routesCredits from './routes/credits'
import routesSiteSettings from './routes/sites_settings'
import routesLoginSession from './routes/login_session'
import routesMessageLog from './routes/message_log'
import routesClientInvitation from './routes/client_invitation'

Vue.use(VueRouter)
export const routes = [
    ...routesAuth,
    ...routesDashboard,
    ...routesOrder,
    ...routesSupport,
    ...routesClient,
    ...routesWriters,
    ...routesUserSetting,
    ...routesPayroll,
    ...routesAccess,
    // ...routesApplicant,
    ...routesFeedbacks,
    ...routesEmails,
    ...routesPromocodes,
    ...routesBatch,
    ...routesCrud,
    ...routesCredits,
    ...routesSiteSettings,
    ...routesLoginSession,
    ...routesMessageLog,
    ...routesClientInvitation
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.meta.scrollToTop) {
            return { x: 0, y: 0 }
        }
        if (savedPosition) {
            return savedPosition
        }
        return {}
    }
})

function shouldRedirectToAccount(toRoute) {
    const blockedRoutes = ['login', 'register', 'forgot-password']
    const index = blockedRoutes.findIndex((route) => route === toRoute)
    return index !== -1 && Auth.userAuth()
}

router.beforeEach(async (to, from, next) => {
    // set true for loading property so that we can display loader animation until a route is fully loaded
    router.app.loading = true

    if (to.path === '/') next({ name: 'login' })

    // redirect logged in user to account page, in case an irrelevant route is accessed
    if (shouldRedirectToAccount(to.name)) {
        next({ name: 'dashboard' })
    }

    const storage = JSON.parse(localStorage.getItem('vuex'))
    /* very important, roles and permissions */
    const user_roles = storage?.client?.data?.roles?.map((it) => it.permissions.map((it) => it.name)).flat()
    /* end */
    if (to.meta.permissions.every((val) => user_roles.includes(val)) === false) {
        if (!from.name) {
            next({ name: 'dashboard' })
        } else {
            eventBus.$emit('showSnackBar', 'You do not have permissions to view this page', 'error')
            next({ name: from.name })
        }
    }
    // all routes processing logic
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (Auth.isLogged()) {
            next()
        } else {
            Auth.logout()
            next({ name: 'login' })
        }
    } else {
        next();
    }
})

router.afterEach(() => {
    // remove loading animation, once our route is fully loaded
    router.app.loading = false
});

export default router
