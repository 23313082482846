// import { SchemaAdmin } from '@/models/SchemaAdmin.js'
import { cloneDeep } from 'lodash'

export const initialState = {
    isEdit: false,
    currentStep: 'address_book',
    activeNextBtn: false,
    activeSaveBtn: false,
    form_data: {
        mail_template: 'newsletter-01',
        mail_start_at: '',
        mail_subject: '',
        'header-01': '',
        'section-01': '',
        button: {
            text: '',
            url: ''
        },
        subcopy: '',
        search: {
            domain: 'customessaymeister.com',
            from: '',
            to: ''
        }
    }
}

export const state = cloneDeep(initialState)
