import { createHelpers } from 'vuex-map-fields';

import { state } from './state.js';
import { getters } from './getters.js';
import { mutations } from './mutations.js';
import { actions } from './actions.js';

export const {
    mapFields: mapAdminFields,
    mapFields: mapAdminFormFields
} = createHelpers({
    getterType: 'client/getField',
    mutationType: 'client/updateField'
});

export const client = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
