import Api from '@/helpers/api/index.js'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'

import {
    ACTION_GET_MAILING_LIST,
    ACTION_FETCH_MAILING,
    ACTION_DELETE_MAILING,
    ACTION_CREATE_MAILING,
    ACTION_GET_MEMBERS_GROUP,
    ACTION_POPULATE_MEILING,
    ACTION_SEND_MEILING,
    ACTION_ADD_MEMBERS,
    ACTION_REMOVE_MEMBER,
    ACTION_GET_MAILING_LIST_TOTALS,
    // TEMPLATES
    ACTION_GET_MAILING_TEMPLATES_LIST,
    ACTION_GET_MAILING_TEMPLATE,
    ACTION_DELETE_TEMPLATE,
    ACTION_CREATE_OR_UPDATE_TEMPLATE,
    // LOG
    ACTION_GET_MAILS_LOG_LIST,
    ACTION_GET_MAILS_LOG_FETCH,
    ACTION_GET_MAILS_LOG_TOTALS,
    ACTION_CREATE_MAIL_LIST
} from './action-types'

import {
    SET_MAILING_LIST,
    CREATE_MAILING,
    DELETE_MAILING,
    SET_MEMBERS_GROUP,
    SET_MEMBERS_LIST,
    SET_POPULATE_PRESETS,
    SET_MAILING_LIST_TOTALS_LOADING,
    SET_MAILING_LIST_TOTAL,
    // TEMPLATES
    SET_MAILING_TEMPLATES_LIST,
    DELETE_TEMPLATE,
    // LOG
    SET_MAILS_LOG_LIST,
    SET_MAILS_LOG_TOTAL,
    SET_MAILS_LOG_TOTALS_LOADING
} from './mutation-types'

export const actions = {
    async [ACTION_GET_MAILING_LIST]({ commit }, payload) {
        const { data } = await Api.get('/api/mail/mailing-list/list-by-params', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        commit(SET_MAILING_LIST, data.lists)
    },
    async [ACTION_FETCH_MAILING]({ commit }, payload) {
        const { data } = await Api.get('/api/mail/mailing-list/fetch-paginated', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        commit(SET_MEMBERS_LIST, data.members)
        return data
    },
    async [ACTION_CREATE_MAILING]({ commit }, payload) {
        await Api.post('/api/mail/mailing-list/create', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        commit(CREATE_MAILING, payload)
    },
    async [ACTION_DELETE_MAILING]({ commit }, payload) {
        await Api.delete('/api/mail/mailing-list/delete', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        commit(DELETE_MAILING, payload)
    },
    async [ACTION_GET_MEMBERS_GROUP]({ commit }, payload) {
        const { data } = await Api.get('api/mail/mailing-list/populate-presets', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        commit(SET_POPULATE_PRESETS, data)
    },
    async [ACTION_POPULATE_MEILING]({ commit }, payload) {
        const { data } = await Api.post('/api/mail/mailing-list/populate', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        commit(SET_MEMBERS_GROUP, { count: data.added_count, ...payload })
    },
    async [ACTION_ADD_MEMBERS](ctx, payload) {
        await Api.put('/api/mail/mailing-list/member/add-many', removeEmptyParamsFromRequest({ ...payload }), {}, false)
    },
    async [ACTION_SEND_MEILING](ctx, payload) {
        await Api.post('/api/mail/mailing-list/send-template', removeEmptyParamsFromRequest({ ...payload }), {}, false)
    },
    async [ACTION_REMOVE_MEMBER](ctx, payload) {
        await Api.delete('/api/mail/mailing-list/member/remove', removeEmptyParamsFromRequest({ ...payload }), {}, false)
    },
    async [ACTION_GET_MAILING_LIST_TOTALS]({ commit }, payload) {
        commit(SET_MAILING_LIST_TOTALS_LOADING, true)
        const { data } = await Api.get('/api/mail/mailing-list/list-by-params', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        commit(SET_MAILING_LIST_TOTAL, {
            last_page: Math.ceil(data.count / payload.per_page),
            total: data.count
        })
        commit(SET_MAILING_LIST_TOTALS_LOADING, false)
    },

    // TEMPLATES

    async [ACTION_GET_MAILING_TEMPLATES_LIST]({ commit }, payload) {
        const { data } = await Api.get('/api/mail/mailing-list-template/list-by-params', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        commit(SET_MAILING_TEMPLATES_LIST, data)
        return data
    },
    async [ACTION_GET_MAILING_TEMPLATE](ctx, payload) {
        const { data } = await Api.get('/api/mail/mailing-list-template/fetch', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        return data
    },
    async [ACTION_DELETE_TEMPLATE]({ commit }, payload) {
        await Api.delete('/api/mail/mailing-list-template/delete', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        commit(DELETE_TEMPLATE, payload)
    },
    async [ACTION_CREATE_OR_UPDATE_TEMPLATE](ctx, payload) {
        await Api.post('/api/mail/mailing-list-template/create-or-update', removeEmptyParamsFromRequest({ ...payload }), {}, false)
    },
    // LOG
    async [ACTION_GET_MAILS_LOG_LIST]({ commit }, payload) {
        const { data } = await Api.get('/api/mail/mailing-list-log/list-by-params', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        commit(SET_MAILS_LOG_LIST, data)
    },
    async [ACTION_GET_MAILS_LOG_FETCH](ctx, payload) {
        const { data } = await Api.get('/api/mail/mailing-list-log/fetch', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        return data
    },
    async [ACTION_GET_MAILS_LOG_TOTALS]({ commit }, payload) {
        commit(SET_MAILS_LOG_TOTALS_LOADING, true)
        const { data } = await Api.get('/api/mail/mailing-list-log/list-by-params', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        commit(SET_MAILS_LOG_TOTAL, {
            last_page: Math.ceil(data.count / payload.per_page),
            total: data.count
        })
        commit(SET_MAILS_LOG_TOTALS_LOADING, false)
    },
    async [ACTION_CREATE_MAIL_LIST](ctx, payload) {
        const { data } = await Api.post('/api/mail/mailing-list/create-n-populate', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        return data
    }
}
