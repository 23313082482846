import { cloneDeep } from 'lodash'

export const initialState = {
    supportsListLoading: true,
    supportsList: [],
    currentPage: 0,
    lastPage: 0,
    total: 0,
    requestPayload: null,
    timerID: null,
    presets_loading: true,
    clients_presets: [],
    writers_presets: [],
    support_chat: [],
    support_chat_loading: false,
    support_chat_message_sending: false
}

export const state = cloneDeep(initialState)
