export default [
    {
        path: '/client-invitation',
        component: () => import('@/views/ClientInvitation/ClientInvitation.vue'),
        meta: {
            requiresAuth: true,
            name: 'ClientInvitation',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        },
        name: 'client-invitation'
    }
]
