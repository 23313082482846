import { getField } from 'vuex-map-fields'

export const getters = {
    getField,
    getterSupportList(state) {
        return state.supportsList
    },
    getterLastPage(state) {
        return state.lastPage
    },
    getterTotal(state) {
        return state.total
    },
    getterCurrentPage(state) {
        return state.currentPage
    },
    getterRequestPayload(state) {
        return state.requestPayload
    },
    getterPresetsLoading(state) {
        return state.presets_loading
    },
    getterClientPresets(state) {
        return state.clients_presets
    },
    getterWriterPresets(state) {
        return state.writers_presets
    },
    getterChat(state) {
        return state.support_chat
    },
    getterChatLoading(state) {
        return state.support_chat_loading
    },
    getterMessageIsSending(state) {
        return state.support_chat_message_sending
    },
    getterSupportLoading(state) {
        return state.supportsListLoading
    },
    getterSummary(state) {
        return {
            total: state.total
        }
    }
}
