export const SET_FEEDBACKS_LIST = 'SET_FEEDBACKS_LIST'

export const UPDATE_FEEDBACK = 'UPDATE_FEEDBACK'

export const UPDATE_FEEDBACK_STATUS = 'UPDATE_FEEDBACK_STATUS'

export const SET_PRESETS_LIST = 'SET_PRESETS_LIST'

export const CLEAR_FEEDBACKS_LIST = 'CLEAR_FEEDBACKS_LIST'

export const SET_TOTALS_LOADING = 'SET_PRESETS_LOADING'

export const SET_FEEDBACKS_SUMMARY = 'SET_FEEDBACKS_SUMMARY'

export const SET_FEEDBACKS_LIST_PAGES = 'SET_FEEDBACKS_LIST_PAGES'

export const SET_FEEDBACKS_LIST_LOADING = 'SET_FEEDBACKS_LIST_LOADING'
