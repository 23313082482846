import { cloneDeep } from 'lodash'
import { updateField } from 'vuex-map-fields';
import { findAndReplaceInArray } from '@/helpers/utils/index.js'
import { initialState } from './state'
import {
    CLEAR_TIMER,
    CLOSE_TICKET,
    CLOSE_TICKET_MASS,
    SET_CLIENT_PRESETS,
    SET_PAYLOAD,
    SET_SUPPORT_LIST,
    SET_SUPPORT_LIST_LIVE,
    SET_TIMER,
    SET_WRITER_PRESETS,
    UPDATE_SUPPORT_LIST,
    UPDATE_TICKET,
    SET_SUPPORT_CHAT,
    SET_SUPPORT_CHAT_LOADING,
    SET_SUPPORT_CHAT_MESSAGE,
    SET_MESSAGE_SENDING_LOADING,
    RESET_STATE,
    SET_SUPPORT_TOTALS,
    SET_SUPPORT_LIST_LOADING,
    SET_PRESETS_LOADING
} from './mutation-types'

export const mutations = {
    updateField,
    [RESET_STATE](state) {
        Object.assign(state, cloneDeep(initialState))
    },
    [SET_SUPPORT_LIST](state, payload) {
        state.supportsList = payload.data
        state.currentPage = payload.current_page
    },
    [SET_SUPPORT_LIST_LOADING](state, payload) {
        state.supportsListLoading = payload
    },
    [SET_SUPPORT_TOTALS](state, payload) {
        state.lastPage = payload.last_page
        state.total = payload.count
    },
    [UPDATE_TICKET](state, payload) {
        findAndReplaceInArray(state.supportsList, payload.id, 'id', payload)
    },
    [CLOSE_TICKET](state, payload) {
        findAndReplaceInArray(state.supportsList, payload.id, 'ticketid', { status_support: 'CLOSED' })
    },
    [CLOSE_TICKET_MASS](state, payload) {
        payload.forEach((el) => {
            findAndReplaceInArray(state.supportsList, el, 'id', { status_support: 'CLOSED' })
        });
    },
    [SET_PAYLOAD](state, payload) {
        state.requestPayload = payload
    },
    [SET_SUPPORT_LIST_LIVE](state, payload) {
        state.currentPage = payload.current_page
        state.supportsList = payload.data.map((element) => {
            const find = state.supportsList.find((item) => item.ticketid === element.ticketid)
            const isDiff = (JSON.stringify({ ...find }) !== JSON.stringify({ ...element }))
            if (find && isDiff) {
                element = {
                    ...element,
                    isUpdated: true
                }
            }
            if (!find) {
                element = {
                    ...element,
                    isUpdated: true,
                    isNew: true
                }
            }
            return element
        });
    },
    [SET_TIMER](state, payload) {
        state.timerID = payload
    },
    [CLEAR_TIMER](state) {
        clearTimeout(state.timerID);
    },
    [SET_PRESETS_LOADING](state, payload) {
        state.presets_loading = payload
    },
    [SET_CLIENT_PRESETS](state, payload) {
        state.clients_presets = payload
    },
    [SET_WRITER_PRESETS](state, payload) {
        state.writers_presets = payload
    },
    [UPDATE_SUPPORT_LIST](state, payload) {
        state.supportsList = [
            payload,
            ...state.supportsList
        ]
    },
    [SET_SUPPORT_CHAT](state, payload) {
        state.support_chat = payload
    },
    [SET_SUPPORT_CHAT_LOADING](state, payload) {
        state.support_chat_loading = payload
    },
    [SET_SUPPORT_CHAT_MESSAGE](state, payload) {
        state.support_chat = [...state.support_chat, payload]
        const index = state.supportsList.findIndex((item) => item.ticketid === payload.ticketid)
        state.supportsList.splice(index, 1, {
            ...state.supportsList[index],
            messages_count: state.supportsList[index]?.messages_count + 1,
            last_message: {
                ...payload
            }
        })
    },
    [SET_MESSAGE_SENDING_LOADING](state, payload) {
        state.support_chat_message_sending = payload
    }
}
