import { updateField } from 'vuex-map-fields';
import {
    SET_COUNTS,
    SET_LOADING_FULL_MENU
} from './mutation-types'

export const mutations = {
    updateField,
    [SET_COUNTS](state, payload) {
        state.fullMenu = payload
    },
    [SET_LOADING_FULL_MENU](state, payload) {
        state.loadingFullMenu = payload
    }
}
