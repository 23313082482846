import Api from '@/helpers/api/index.js'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'

import {
    ACTION_GET_WRITERS_LIST,
    ACTION_GET_FETCH_WRITER,
    ACTION_WRITER_ACTIVATE,
    ACTION_WRITER_DEACTIVATE,
    ACTION_WRITER_POST_NOTE,
    ACTION_WRITER_EDIT,
    ACTION_SET_STATUS_TRUSTED_TO_TRUSTED,
    ACTION_SET_STATUS_TRUSTED_TO_NEW,
    ACTION_DELETE_WRITER_FILE,
    ACTION_UPLOAD_WRITER_FILE,
    ACTION_GET_WRITER_NOTE_LIST,
    ACTION_DELETE_WRITER_NOTE,
    ACTION_EDIT_WRITER_NOTE,
    ACTION_GET_EXAM_LIST,
    ACTION_EDIT_UPDATE,
    ACTION_GET_PRESETS_WRITER,
    ACTION_GET_WRITER_SHORT_LIST,
    ACTION_GET_WRITERS_LIST_WITH_PRESET,
    ACTION_GET_WRITERS_LIST_WITHOUT_PRESET,
    ACTION_GET_WRITERS_TOTALS,
    ACTION_REFRESH_STATISTIC,
    ACTION_GET_PAYABLE,
    ACTION_CREATE_BATCH,
    ACTION_PAY_PAYONEER,
    ACTION_PAY_WIRE,
    ACTION_PAY_MPESA,
    // APPLICANT
    ACTION_APPLICANT_OPEN,
    ACTION_APPLICANT_DEACTIVATE,
    ACTION_CHANGE_AVATAR,
    ACTION_GET_PRESETS_APPLICANTE,
    ACTION_GET_WRITERS_PAYMENTS_TOTALS,
    ACTION_GET_WRITERS_PAYMENTS_LIST,
    ACTION_GET_PAYOUTS_PRESETS_WRITER,
    ACTION_APPROVE_APPLICANT,
    ACTION_WRITER_CREDIT,
    GET_BALANCE,
    ACTION_GET_CREATE_PAYROLL_NOTE
} from './action-types'

import {
    ACTIVATE_APPLICANT,
    ACTIVATE_WRITER,
    DEACTIVATE_APPLICANT,
    DEACTIVATE_WRITER,
    DELETE_FILE_FROM_LIST,
    DELETE_NOTE_FROM_LIST,
    EDIT_WRITER_NOTE,
    OPEN_APPLICANT,
    POST_NOTE,
    SET_AVATAR_URL,
    SET_NOTE_LIST,
    SET_PRESETS,
    SET_PRESETS_APPLICANT,
    SET_STATUS_TRUSTED_TO_NEW,
    SET_STATUS_TRUSTED_TO_TRUSTED,
    SET_WRITER,
    SET_WRITERS_LIST,
    SET_WRITERS_SHORT_LIST,
    SET_WRITER_LIMIT,
    UPDATE_WRITER,
    UPDATE_WRITER_FILES_LIST,
    SET_WRITERS_LIST_LAST_PAGE,
    SET_WRITERS_SUMMARY,
    SET_TOTALS_LOADING,
    SET_WRITERS_LIST_LOADING,
    SET_PRESETS_LOADING,
    SET_WRITERS_PAYMENTS_LIST,
    SET_WRITERS_PAYMENTS_LIST_LOADING,
    SET_WRITERS_PAYMENTS_WRITERS_SUMMARY,
    SET_WRITERS_PAYMENTS_TOTALS_LOADING,
    SET_WRITERS_PAYMENTS_LIST_LAST_PAGE,
    SET_PAYOUTS_PRESETS,
    SET_PAYOUTS_PRESETS_LOADING,
    UPDATE_PAYOUT
} from './mutation-types'

export const actions = {
    // GET WRITERS
    async [ACTION_GET_WRITERS_LIST]({ commit, dispatch }, payload) {
        commit(SET_WRITERS_LIST_LOADING, true)
        let list
        if (payload.preset) {
            list = await dispatch(ACTION_GET_WRITERS_LIST_WITH_PRESET, payload)
        } else {
            list = await dispatch(ACTION_GET_WRITERS_LIST_WITHOUT_PRESET, payload)
        }
        commit(SET_WRITERS_LIST, list)
        commit(SET_WRITERS_LIST_LOADING, false)
        return list
    },

    // GET TOTALS
    async [ACTION_GET_WRITERS_TOTALS]({ commit }, payload) {
        commit(SET_TOTALS_LOADING, true)
        let totals
        const payload2 = removeEmptyParamsFromRequest({ ...payload })
        if (payload2?.preset) {
            totals = await Api.get('/api/writer/list-by-preset', { ...payload2 }, {}, false)
        } else {
            totals = await Api.get('/api/writer/list-by-params', { ...payload2 }, {}, false)
        }
        commit(SET_WRITERS_SUMMARY, totals.data)
        commit(SET_WRITERS_LIST_LAST_PAGE, {
            last_page: Math.ceil(totals.data.count / payload.per_page),
            total: totals.data.count
        })
        commit(SET_TOTALS_LOADING, false)
    },

    // GET CLIENTS WITH PRESET
    async [ACTION_GET_WRITERS_LIST_WITH_PRESET](_, payload) {
        const { data } = await Api.get('/api/writer/list-by-preset', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        return data
    },

    // GET CLIENTS WITHOUT PRESET
    async [ACTION_GET_WRITERS_LIST_WITHOUT_PRESET](_, payload) {
        const { data } = await Api.get('/api/writer/list-by-params', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        return data
    },

    // GET WRITERS LIST
    // async [ACTION_GET_WRITERS_LIST]({ commit }, payload) {
    //     const responses = await Promise.all([
    //         Api.get('/api/writer/list-by-params', removeEmptyParamsFromRequest({ ...payload, mode: 'list' })),
    //         Api.get('/api/writer/list-by-params', removeEmptyParamsFromRequest({ ...payload, mode: 'count' }))
    //     ])
    //     const { data } = responses[0]
    //     const { data: totals } = responses[1]
    //     commit(SET_WRITERS_LIST, data)
    //     commit(SET_WRITERS_LIST_LAST_PAGE, {
    //         last_page: Math.ceil(totals.count / payload.per_page),
    //         total: totals.count
    //     })
    //     // commit(SET_PRESETS, data.statuses)
    //     return data
    // },
    // GET WRITER
    async [ACTION_GET_FETCH_WRITER]({ commit }, payload) {
        const { data } = await Api.get('/api/writer/fetch', removeEmptyParamsFromRequest({ ...payload }))
        commit(SET_WRITER, data)
        return data
    },
    // ACTIVATE WRITER
    async [ACTION_WRITER_ACTIVATE]({ commit }, payload) {
        await Api.put('/api/writer/action/reactivate', payload)
        commit(ACTIVATE_WRITER, payload)
    },
    // DEACTIVATE WRITER
    async [ACTION_WRITER_DEACTIVATE]({ commit }, payload) {
        await Api.put('/api/writer/action/suspend', payload)
        commit(DEACTIVATE_WRITER, payload)
    },
    // POST A NOTE WRITER
    async [ACTION_WRITER_POST_NOTE](ctx, payload) {
        const { data } = await Api.post('/api/writer-note/create', payload)
        data.admin = {
            id: ctx.rootGetters['client/getterMainInfo'].id,
            avatar: ctx.rootGetters['client/getterMainInfo'].avatar_url,
            avatar_url: ctx.rootGetters['client/getterMainInfo'].avatar_url,
            admin_firstname: ctx.rootGetters['client/getterMainInfo'].firstname,
            admin_lastname: ctx.rootGetters['client/getterMainInfo'].lastname
        }
        ctx.commit(POST_NOTE, data)
    },
    // POST A NOTE WRITER
    async [ACTION_WRITER_EDIT]({ commit }, payload) {
        const { data } = await Api.put('/api/writer/update', payload)
        commit(UPDATE_WRITER, payload)
        return data
    },
    // SET STATUS TRUSTED TO TRUSTED
    async [ACTION_SET_STATUS_TRUSTED_TO_TRUSTED]({ commit }, payload) {
        await Api.put('/api/writer/set-trusted-status-trusted', payload)
        commit(SET_STATUS_TRUSTED_TO_TRUSTED, payload)
    },
    // SET STATUS TRUSTED TO NEW
    async [ACTION_SET_STATUS_TRUSTED_TO_NEW]({ commit }, payload) {
        await Api.put('/api/writer/set-trusted-status-new', payload)
        commit(SET_STATUS_TRUSTED_TO_NEW, payload)
    },
    // DELETE FILE
    async [ACTION_DELETE_WRITER_FILE]({ commit }, payload) {
        const { data } = await Api.delete('/api/files/applicant/delete', payload)
        commit(DELETE_FILE_FROM_LIST, payload)
        return data
    },
    // UPLOAD FILES
    async [ACTION_UPLOAD_WRITER_FILE]({ commit }, { formData, callback }) {
        const { data } = await Api.post('/api/file-storage/applicant/upload-file', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: callback
        })
        commit(UPDATE_WRITER_FILES_LIST, data)
        return data
    },
    // GET WRITER NOTE LIST
    async [ACTION_GET_WRITER_NOTE_LIST]({ commit }, payload) {
        const { data } = await Api.get('/api/writer-note/list', payload)
        commit(SET_NOTE_LIST, data)
    },
    // DELETE NOTE
    async [ACTION_DELETE_WRITER_NOTE]({ commit }, id) {
        await Api.delete('/api/writer-note/delete', { id })
        commit(DELETE_NOTE_FROM_LIST, id)
    },
    // EDIT WRITER NOTE
    async [ACTION_EDIT_WRITER_NOTE]({ commit }, payload) {
        const { data } = await Api.put('/api/writer-note/update', payload)
        commit(EDIT_WRITER_NOTE, payload)
        return data
    },
    // GET EXAM LIST
    async [ACTION_GET_EXAM_LIST](ctx, payload) {
        const { data } = await Api.get('/api/writer-exam/list-by-params', payload)
        return data
    },
    // EDIT LIMIT
    async [ACTION_EDIT_UPDATE]({ commit }, payload) {
        await Api.put('/api/writer/action/update', payload)
        commit(SET_WRITER_LIMIT, payload)
    },
    // GET WRITERS PAYMENTS LIST
    async [ACTION_GET_WRITERS_PAYMENTS_LIST]({ commit }, payload) {
        commit(SET_WRITERS_PAYMENTS_LIST_LOADING, true)
        let list
        if (payload.preset) {
            list = await Api.get('/api/writer-payout/list-by-preset', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        } else {
            list = await Api.get('/api/writer-payout/list-by-params', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        }
        commit(SET_WRITERS_PAYMENTS_LIST, list.data)
        commit(SET_WRITERS_PAYMENTS_LIST_LOADING, false)
        return list
    },

    // GET WRITERS PAYMENTS TOTALS
    async [ACTION_GET_WRITERS_PAYMENTS_TOTALS]({ commit }, payload) {
        commit(SET_WRITERS_PAYMENTS_TOTALS_LOADING, true)
        let totals
        if (payload.preset) {
            totals = await Api.get('/api/writer-payout/list-by-preset', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        } else {
            totals = await Api.get('/api/writer-payout/list-by-params', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        }
        commit(SET_WRITERS_PAYMENTS_WRITERS_SUMMARY, totals.data)
        commit(SET_WRITERS_PAYMENTS_LIST_LAST_PAGE, {
            last_page: Math.ceil(totals.data.count / payload.per_page),
            total: totals.data.count
        })
        commit(SET_WRITERS_PAYMENTS_TOTALS_LOADING, false)
    },

    // GET WRITERS PAYOUTS PRESETS
    async [ACTION_GET_PAYOUTS_PRESETS_WRITER]({ commit }) {
        commit(SET_PAYOUTS_PRESETS_LOADING, true)
        const presets = await Api.get('/api/writer-payout/list-presets')
        const tmp = presets.data.map((item) => ({
            ...item,
            preset: item.value,
            count: item.counters?.count || 0
        }))
        commit(SET_PAYOUTS_PRESETS, {
            data: tmp
        })
        commit(SET_PAYOUTS_PRESETS_LOADING, false)
    },

    // GET PAYABLE
    async [ACTION_GET_PAYABLE](ctx, payout_id) {
        const { data } = await Api.post('/api/writer-payout/get-payable', { payout_id })
        return data
    },

    // GET PAYABLE
    async [ACTION_CREATE_BATCH]({ commit }, payload) {
        const { data } = await Api.post('/api/writer-payout/create-batch', payload)
        commit(UPDATE_PAYOUT, data)
    },

    async [ACTION_PAY_PAYONEER]({ commit }, payload) {
        const { data } = await Api.post('/api/writer-payout/pay/payoneer/pay', payload)
        commit(UPDATE_PAYOUT, data)
        return data
    },

    async [ACTION_PAY_WIRE]({ commit }, payload) {
        const { data } = await Api.post('/api/writer-payout/pay/wire/mark-as-sent', payload)
        commit(UPDATE_PAYOUT, data)
        return data
    },

    async [ACTION_PAY_MPESA]({ commit }, payload) {
        const { data } = await Api.post('/api/writer-payout/pay/mpesa/mark-as-sent', payload)
        commit(UPDATE_PAYOUT, data)
        return data
    },
    // CREDIT WRITER
    async [ACTION_WRITER_CREDIT](ctx, payload) {
        await Api.post('/api/writer-credit/create', payload)
    },

    // APPLICANT

    // OPEN APPLICANT
    async [ACTION_APPLICANT_OPEN]({ commit }, payload) {
        await Api.put('/api/writer/applicant/open', payload)
        commit(OPEN_APPLICANT, payload)
    },

    // DEACTIVATE WRITER
    async [ACTION_APPLICANT_DEACTIVATE]({ commit }, payload) {
        await Api.put('/api/writer/action/close-applicant', payload)
        commit(DEACTIVATE_APPLICANT, payload)
    },

    // CHANGE AVATAR
    async [ACTION_CHANGE_AVATAR]({ commit }, payload) {
        const result = await Api.post('/api/writer/action/update-avatar', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
        commit(SET_AVATAR_URL, {
            sw_id: payload.get('sw_id'),
            avatar_url: result.data
        })
        return result.data
    },

    // GET WRITERS PRESETS
    async [ACTION_GET_PRESETS_WRITER]({ commit }) {
        commit(SET_PRESETS_LOADING, true)
        const presets = await Api.get('/api/writer/list-presets')
        const tmp = presets.data.map((item) => ({
            ...item,
            preset: item.value,
            count: item.counters?.count || 0
        }))
        commit(SET_PRESETS, {
            data: tmp
        })
        commit(SET_PRESETS_LOADING, false)
    },

    // GET APPLICANT PRESETS
    async [ACTION_GET_PRESETS_APPLICANTE]({ commit }) {
        const { data } = await Api.get('/api/writer/list-preset-applicant')
        commit(SET_PRESETS_APPLICANT, data)
    },

    // GET WRITERS SHORT LIST
    async [ACTION_GET_WRITER_SHORT_LIST]({ commit }, payload) {
        commit(SET_WRITERS_SHORT_LIST, [])
        const { data } = await Api.get('/api/writer/short-list-by-ids', payload)
        commit(SET_WRITERS_SHORT_LIST, data)
    },

    async [ACTION_REFRESH_STATISTIC](ctx, payload) {
        await Api.post('/api/writer-statistics/calculate', payload)
    },

    async [ACTION_APPROVE_APPLICANT]({ commit }, payload) {
        await Api.put('/api/writer/action/approve-applicant', payload)
        commit(ACTIVATE_APPLICANT, payload)
    },

    async [GET_BALANCE](ctx, payload) {
        const list = await Api.get('/api/writer-payout/pay/payoneer/get-balance', payload)
        return list.data
    },

    async [ACTION_GET_CREATE_PAYROLL_NOTE](ctx, payload) {
        await Api.post('/api/writer-payout-note/create', payload)
    }
}
