const oldTitle = document.title;

class ChangeTitle {
    constructor() {
        this.title = function (title) {
            document.title = (document.title === oldTitle ? title : oldTitle);
        }
    }

    start = (title = 'New Notification 🔔') => {
        this.timer = setInterval(() => {
            this.title(title)
        }, 1000);
        window.addEventListener('focus', this.stop)
        window.addEventListener('click', this.stop)
    };

    stop = () => {
        clearInterval(this.timer)
        document.title = oldTitle
        window.removeEventListener('focus', this.stop)
        window.removeEventListener('click', this.stop)
    };
}

export default new ChangeTitle()
