export const RESET_STATE = 'RESET_STATE'

export const SET_SUPPORT_LIST = 'SET_SUPPORT_LIST'

export const SET_SUPPORT_LIST_LOADING = 'SET_SUPPORT_LIST_LOADING'

export const SET_SUPPORT_TOTALS = 'SET_SUPPORT_TOTALS'

export const SET_SUPPORT_LIST_LIVE = 'SET_SUPPORT_LIST_LIVE'

export const UPDATE_TICKET = 'UPDATE_TICKET'

export const CLOSE_TICKET = 'CLOSE_TICKET'

export const CLOSE_TICKET_MASS = 'CLOSE_TICKET_MASS'

export const SET_PAYLOAD = 'SET_PAYLOAD'

export const SET_TIMER = 'SET_TIMER'

export const CLEAR_TIMER = 'CLEAR_TIMER'

export const SET_PRESETS_LOADING = 'SET_PRESETS_LOADING'

export const SET_CLIENT_PRESETS = 'SET_CLIENT_PRESETS'

export const SET_WRITER_PRESETS = 'SET_WRITER_PRESETS'

export const UPDATE_SUPPORT_LIST = 'UPDATE_SUPPORT_LIST'

export const SET_SUPPORT_CHAT = 'SET_SUPPORT_CHAT'

export const SET_SUPPORT_CHAT_LOADING = 'SET_SUPPORT_CHAT_LOADING'

export const SET_SUPPORT_CHAT_MESSAGE = 'SET_SUPPORT_CHAT_MESSAGE'

export const SET_MESSAGE_SENDING_LOADING = 'SET_MESSAGE_SENDING_LOADING'
