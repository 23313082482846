export const ACTION_GET_FEEDBACKS_LIST = 'ACTION_GET_FEEDBACKS_LIST'

export const ACTION_EDIT_FEEDBACK = 'ACTION_EDIT_FEEDBACK'

export const ACTION_SET_STATUS_FEEDBACK = 'ACTION_SET_STATUS_FEEDBACK'

export const ACTION_CREATE_TICKET_FEEDBACK = 'ACTION_CREATE_TICKET_FEEDBACK'

export const ACTION_GET_FETCH_FEEDBACK = 'ACTION_GET_FETCH_FEEDBACK'

export const ACTION_GET_PRESETS_FEEDBACK = 'ACTION_GET_PRESETS_FEEDBACK'

export const ACTION_GET_FEEDBACKS_TOTALS = 'ACTION_GET_FEEDBACKS_TOTALS'

export const ACTION_GET_FEEDBACKS_LIST_WITH_PRESET = 'ACTION_GET_FEEDBACKS_LIST_WITH_PRESET'

export const ACTION_GET_FEEDBACKS_LIST_WITHOUT_PRESET = 'ACTION_GET_FEEDBACKS_LIST_WITHOUT_PRESET'
