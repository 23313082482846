export default [
    {
        path: '/writers',
        component: () => import(/* webpackChunkName: "writers" */ '@/views/HR/WritersList'),
        meta: {
            requiresAuth: true,
            name: 'Writers',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: [],
            keepAlive: true
        },
        name: 'writers'
    },
    {
        path: '/writers/:id',
        component: () => import(/* webpackChunkName: "writers" */ '@/views/HR/WriterDetails'),
        meta: {
            requiresAuth: true,
            name: 'writer-profile',
            layout: 'dashboard-layout',
            breadcrumb() {
                const { id } = this.$route.params;

                return {
                    label: id,
                    parent: 'writers'
                };
            },
            permissions: [],
            scrollToTop: true,
            keepAlive: true
        },
        children: [
            {
                path: '',
                name: 'writer-profile',
                component: () => import(/* webpackChunkName: "writers" */ '@/views/HR/Tabs/Details'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    permissions: [],
                    scrollToTop: true,
                    keepAlive: true
                }
            },
            {
                path: 'orders',
                name: 'writer-profile-order',
                component: () => import(/* webpackChunkName: "writers" */ '@/views/HR/Tabs/Orders.vue'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    permissions: [],
                    keepAlive: true
                }
            },
            {
                path: 'notes',
                name: 'writer-profile-notes',
                component: () => import(/* webpackChunkName: "writers" */ '@/views/HR/Tabs/Notes'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    permissions: [],
                    keepAlive: true
                }
            },
            {
                path: 'tickets',
                name: 'writer-profile-tickets',
                component: () => import(/* webpackChunkName: "writers" */ '@/views/HR/Tabs/Tickets'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    permissions: [],
                    keepAlive: true
                }
            },
            {
                path: 'files',
                name: 'writer-profile-files',
                component: () => import(/* webpackChunkName: "writers" */ '@/views/HR/Tabs/Files'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    permissions: [],
                    keepAlive: true
                }
            },
            {
                path: 'testimonials',
                name: 'writer-profil-testimonials',
                component: () => import(/* webpackChunkName: "writers" */ '@/views/HR/Tabs/Feedback'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    permissions: [],
                    keepAlive: true
                }
            },
            {
                path: 'payments',
                name: 'writer-profile-payments',
                component: () => import(/* webpackChunkName: "writers" */ '@/views/HR/Tabs/PaymentsHistory'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    permissions: [],
                    keepAlive: true
                }
            },
            {
                path: 'credits',
                name: 'writer-profile-credits',
                component: () => import(/* webpackChunkName: "writers" */ '@/views/HR/Tabs/Credits'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    permissions: [],
                    keepAlive: true
                }
            },
            {
                path: 'exams',
                name: 'writer-profile-examsessions',
                component: () => import(/* webpackChunkName: "writers" */ '@/views/HR/Tabs/ExamSessions'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    permissions: [],
                    keepAlive: true
                }
            }
        ]
    }
]
