import Api from '@/helpers/api/index.js'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'

import {
    ACTION_GET_EMAILS_LIST,
    ACTION_GET_SELECT_EMAIL,
    ACTION_CREATE_OR_UPDATE
} from './action-types'

import {
    SET_EMAILS_LIST
} from './mutation-types'

export const actions = {
    async [ACTION_GET_EMAILS_LIST]({ commit }, payload) {
        const { data } = await Api.get('/api/mail/remarketing/list-by-params', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        commit(SET_EMAILS_LIST, data)
    },
    async [ACTION_GET_SELECT_EMAIL](_, payload) {
        const { data } = await Api.get('/api/mail/remarketing/fetch', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        return data
    },
    async [ACTION_CREATE_OR_UPDATE](_, payload) {
        await Api.post('/api/mail/remarketing/create-or-update', removeEmptyParamsFromRequest({ ...payload }), {}, false)
    }
}
