import {
    ACTION_EDIT_EMAIL
} from './action-types'

import {
    SET_FORM_FROM_PAYLOAD
} from './mutation-types'

export const actions = {
    async [ACTION_EDIT_EMAIL]({ commit }, payload) {
        commit(SET_FORM_FROM_PAYLOAD, JSON.parse(payload.payload))
    }
}
