import { createHelpers } from 'vuex-map-fields';

import { actions } from './actions.js';
import { state } from './state.js';
import { mutations } from './mutations.js';
import { getters } from './getters.js';

export const {
    mapFields: mapMailingFields,
    mapFields: mapMailingFormFields
} = createHelpers({
    getterType: 'mailing/getField',
    mutationType: 'mailing/updateField'
});

export const mailing = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
