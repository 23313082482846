import { mapGetters, createNamespacedHelpers } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'

import Auth from '@/helpers/auth'
import {
    ACTION_LOGOUT
} from '@/store/modules/client/action-types.js'

const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client')

// this mixin contains the logic for session expiry
export default {
    data: () => ({
        authTimer: null
    }),
    computed: {
        ...mapGetters('client', ['getterIsAuth'])
    },
    watch: {
        getterIsAuth(newVal) {
            if (newVal) {
                this.startAuthTimer()
                this.$socket.connect()
            } else {
                clearInterval(this.authTimer)
                this.$socket.emit('forceDisconnect');
            }
        }
    },
    created() {
        // set timer for authentication popup (extend token expiry)
        if (this.getterIsAuth) {
            this.startAuthTimer()
        } else {
            clearInterval(this.authTimer)
        }
    },
    methods: {
        ...mapClientActions([
            ACTION_LOGOUT
        ]),
        startAuthTimer() {
            if (window.location.pathname.includes('widgets')) {
                clearInterval(this.authTimer)
            } else {
                this.authTimer = setInterval(() => {
                    try {
                        const result = Auth.shouldDisplayPopup()
                        if (result) {
                            eventBus.$emit('showAuthPopup', Auth.getTimeToExpiryInSeconds())
                        }
                    } catch (error) {
                        eventBus.$emit(
                            'showSnackBar',
                            'Looks like your session has expired and you need to log in.',
                            'error'
                        )

                        // Clear client data
                        this[ACTION_LOGOUT]()
                        this.$router.push('/login')
                        clearInterval(this.authTimer)
                    }
                }, 5000)
            }
        }
    }
}
