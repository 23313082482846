import { getField } from 'vuex-map-fields'

export const getters = {
    getField,
    getterFeedbacksList(state) {
        return state.feedbacksList
    },
    getterLastPage(state) {
        return state.lastPage
    },
    getterTotal(state) {
        return state.total
    },
    getterSummary(state) {
        return state.summary
    },
    getterTotalsLoading(state) {
        return state.totalsLoading
    },
    getterPresets(state) {
        return state.preset
    },
    getterListLoading(state) {
        return state.feedbacksListLoading
    }
}
