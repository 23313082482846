import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi'
    },
    theme: {
        themes: {
            light: {
                // primary: '#1976D2',
                // secondary: '#4F4F4F',
                accent: '#284067'
                // error: '#FF0000',
                // success: '#27D91F',
                // backColor: '#43425D',
                // price: '#219653',
                // background: '#E7E7E7',
                // purple: '#9B51E0'
            }
        }
    }
});
