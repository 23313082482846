<template>
    <v-app id="inspire">
        <component :is="layout" />
        <confirm-auth-popup />
        <snackbar />
        <notifier v-if="$socket.isConnected" />
    </v-app>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import AuthLayout from '@/layout/AuthLayout'
import DashboardLayout from '@/layout/DashboardLayout'
import EmptyLayout from '@/layout/EmptyLayout'
import ConfirmAuthPopup from '@/components/Auth/ConfirmAuthPopup'
import authMixin from '@/mixins/authMixin.js'

import Snackbar from '@/components/shared/Snackbar'
import Notifier from '@/components/shared/Notifier'
import { eventBus } from '@/helpers/event-bus/'

import {
    ACTION_ADMIN_TOUCH,
    ACTION_UPDATE_CLIENT_DATA
} from '@/store/modules/client/action-types'

import { buildDate, moment_from } from '@/plugins/filtersServices'

const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client')

export default {
    name: 'App',
    components: {
        AuthLayout,
        DashboardLayout,
        EmptyLayout,
        Snackbar,
        Notifier,
        ConfirmAuthPopup
    },
    mixins: [authMixin],
    data() {
        return {
            isOffline: false
        }
    },
    computed: {
        layout() {
            return this.$route.meta.layout || 'empty-layout';
        },
        ...mapGetters('client', [
            'getterIsAuth',
            'getterMainInfo'
        ])
    },
    watch: {
        '$route.name': function () {
            if (this.isOffline) {
                this.connectionError()
            }
        },
        getterIsAuth(val) {
            if (val) {
                this[ACTION_ADMIN_TOUCH]()
                this.$socket.reconnect()
            }
        }
    },
    async mounted() {
        // offline listener
        window.addEventListener('offline', () => {
            this.connectionError()
            this.isOffline = true
        })
        window.addEventListener('online', () => {
            eventBus.$emit('showSnackBar', 'The connection was restored', 'success')
            this.isOffline = false
            setTimeout(() => {
                window.location.reload()
            }, 500)
        });
    },
    async created() {
        if (this.getterIsAuth) {
            if (!this.getterMainInfo.id) {
                await this[ACTION_UPDATE_CLIENT_DATA]()
            }
            await this[ACTION_ADMIN_TOUCH]()
            this.$socket.connect()
        }
        await this.getBackendVersion()
    },
    methods: {
        ...mapClientActions([
            ACTION_ADMIN_TOUCH,
            ACTION_UPDATE_CLIENT_DATA
        ]),
        connectionError() {
            const errorString = 'There is a problem with the internet connection, try refreshing the page please.'
            eventBus.$emit('showSnackBar', errorString, 'error')
        },
        async getBackendVersion() {
            try {
                const resp = await fetch(`${process.env.VUE_APP_API_URL}/api/data/api-version`)
                const data = await resp.json();
                // eslint-disable-next-line no-console
                console.group('BACKEND')
                // eslint-disable-next-line no-console
                console.log(
                    '%c environment ',
                    'color: #fff; font-style: italic; font-weight: 700; background-color: #00897B;padding: 3px 3px; border-radius: 4px;',
                    ` ${data.env} `
                );
                // eslint-disable-next-line no-console
                console.log(
                    '%c version ',
                    'color: #fff; font-style: italic; font-weight: 700; background-color: #00897B;padding: 3px 3px; border-radius: 4px;',
                    ` ${data.commit_hash} `
                );
                // eslint-disable-next-line no-console
                console.log(
                    '%c build time ',
                    'color: #fff; font-style: italic; font-weight: 700; background-color: #00897B;padding: 3px 3px; border-radius: 4px;',
                    ` ${data.date_readable}, (${data.updated_when})`
                );
                // eslint-disable-next-line no-console
                console.groupEnd()
                // eslint-disable-next-line no-console
                console.group('FRONTEND')
                // eslint-disable-next-line no-console
                console.log(
                    '%c environment ',
                    'color: #fff; font-style: italic; background-color: #5E35B1; padding: 3px 3px; border-radius: 4px;',
                    ` ${process.env.VUE_APP_ENV} `
                );
                // eslint-disable-next-line no-console
                console.log(
                    '%c version ',
                    'color: #fff; font-style: italic; font-weight: 700; background-color: #5E35B1;padding: 3px 3px; border-radius: 4px;',
                    ` ${process.env.VUE_APP_VERSION} `
                );
                // eslint-disable-next-line no-console
                console.log(
                    '%c build time ',
                    'color: #fff; font-style: italic; font-weight: 700; background-color: #5E35B1;padding: 3px 3px; border-radius: 4px; ',
                    ` ${buildDate(process.env.VUE_APP_BUILD_DATETIME)}, (${moment_from(process.env.VUE_APP_BUILD_DATETIME)})`
                );
                // eslint-disable-next-line no-console
                console.groupEnd()
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
            }
        }
    }
}
</script>
