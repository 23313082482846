import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { eventBus } from '@/helpers/event-bus/'
import { config } from '@/store/modules/config/index.js'
import { client } from '@/store/modules/client/index.js'
import { support } from '@/store/modules/support/index.js'
import { orders } from '@/store/modules/orders/index.js'
import { admins } from '@/store/modules/admin/index.js'
import { clients } from '@/store/modules/clients/index.js'
import { writers } from '@/store/modules/writers/index.js'
import { payroll } from '@/store/modules/payroll/index.js'
import { permissions } from '@/store/modules/permissions/index.js'
import { roles } from '@/store/modules/roles/index.js'
import { bookmarks } from '@/store/modules/bookmarks/index.js'
import { notifications } from '@/store/modules/notifications/index.js'
import { dashboard } from '@/store/modules/dashboard/index.js'
import { feedbacks } from '@/store/modules/feedbacks/index.js'
import { promo } from '@/store/modules/promo/index.js'
import { batch } from '@/store/modules/batch/index.js'
import { credits } from '@/store/modules/credits/index.js'
import { emailBuilder } from '@/store/modules/emailBuilder/index.js'
import { emailViewer } from '@/store/modules/emailViewer/index.js'
import { mailing } from '@/store/modules/mailing/index.js'
import { loginSession } from '@/store/modules/loginSession/index.js'
import { messageLog } from '@/store/modules/messageLog/index.js'
import { clientInvitation } from '@/store/modules/clientInvitation/index.js';
import { VuexHelper } from '@/helpers/vuex/index.js'

function checkerBuildId() {
    const instance = new VuexHelper(process.env.VUE_APP_BUILD)
    if (instance.hasChanged()) {
        // eslint-disable-next-line
        console.log('%c Resetting vuex store!', 'background: #222; color: #bada55')
        setTimeout(() => {
            eventBus.$emit('showSnackBar', 'The site has been updated. Data not saved may have been cleared', 'success')
        }, 0);
        instance.reset()
        instance.setNewId()
        return true
    }
    return false
}

checkerBuildId()

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            paths: ['client', 'emailBuilder']
        })
    ],
    modules: {
        client,
        config,
        support,
        orders,
        admins,
        clients,
        writers,
        payroll,
        permissions,
        roles,
        bookmarks,
        dashboard,
        feedbacks,
        promo,
        batch,
        emailBuilder,
        emailViewer,
        credits,
        mailing,
        notifications,
        loginSession,
        messageLog,
        clientInvitation
    }
})
