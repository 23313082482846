import { cloneDeep } from 'lodash'

export const initialState = {
    orderListLoading: true,
    presetLoading: true,
    totalsLoading: true,
    orderList: [],
    currentPage: 0,
    lastPage: 0,
    total: 0,
    files: [],
    summary: {},
    preset: [],
    requestPayload: null,
    timerID: null,
    notes: [],
    ordersCompletedList: []
}

export const state = cloneDeep(initialState)
