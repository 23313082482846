import { getField } from 'vuex-map-fields'

export const getters = {
    getField,
    getterPermissionsList(state) {
        return state.permissionList
    },
    getterLastPage(state) {
        return state.lastPage
    },
    getterTotal(state) {
        return state.total
    }
}
