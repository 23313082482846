import { getField } from 'vuex-map-fields'

export const getters = {
    getField,
    getterAdminsList(state) {
        return state.adminList
    },
    getterLastPage(state) {
        return state.last_page
    },
    getterTotal(state) {
        return state.total
    },
    getterCurrentPage(state) {
        return state.current_page
    },
    getterRoles(state) {
        return state.roles
    },
    getterPermissions(state) {
        return state.permissions
    }
}
