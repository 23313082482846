/* eslint-disable class-methods-use-this */

export class VuexHelper {
    constructor(build) {
        if (build === '') throw new Error('No build id provided')
        this.newId = build

        if (this.isSet() === false) {
            this.setNewId()
        }
    }

    hasChanged() {
        return !(this.newId === this.getOldId())
    }

    reset() {
        return new Promise((resolve) => {
            window.localStorage.removeItem('vuex')
            resolve()
        })
    }

    getOldId() {
        if (window.localStorage.getItem('build') === null) return false
        const { id } = JSON.parse(window.localStorage.getItem('build'))
        return id
    }

    isSet() {
        return !!this.getOldId()
    }

    setNewId() {
        return new Promise((resolve) => {
            const data = {
                id: this.newId
            }
            window.localStorage.setItem('build', JSON.stringify(data))
            resolve()
        })
    }
}
