export default [
    {
        path: '/login-session',
        component: () => import('@/views/LoginSession/LoginSession.vue'),
        meta: {
            requiresAuth: true,
            name: 'LoginSession',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        },
        name: 'login-session'
    }
]
