import { getField } from 'vuex-map-fields'

export const getters = {
    getField,
    getterBatchList(state) {
        return state.batchList
    },
    getterLastPage(state) {
        return state.lastPage
    },
    getterTotal(state) {
        return state.total
    },
    getterCurrentPage(state) {
        return state.currentPage
    },
    getterPresets(state) {
        return state.presets
    },
    getterSummary(state) {
        return {
            totalFromCount: state.totalFromCount,
            total: state.total,
            pages: state.lastPage
        }
    }
}
