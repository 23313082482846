import { getField } from 'vuex-map-fields'

export const getters = {
    getField,
    getFullMenu(state) {
        return state.fullMenu
    },
    getterLoadingFullMenu(state) {
        return state.loadingFullMenu
    }
}
