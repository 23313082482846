// import { SchemaAdmin } from '@/models/SchemaAdmin.js'

export const initialState = {
    rolesList: [],
    lastPage: 0,
    total: 0,
    permissions: []
}

export const state = { ...initialState }
