export function removeEmptyParamsFromRequest(obj) {
    const query = Object.entries(obj)
    // eslint-disable-next-line no-unused-vars
        .filter(([_, v]) => v !== '' && v !== 'all')
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

    if (query.search_by && !query.search_for) {
        delete query.search_by
    }

    return query
}

export function findAndReplaceInArray(arr, value, key = 'id', newValue) {
    const index = arr.findIndex((item) => item[key] === value)
    arr.splice(index, 1, {
        ...arr[index],
        ...newValue
    })
}
