import Api from '@/helpers/api/index.js'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'
import {
    ACTION_ADD_STORE_CREDIT,
    ACTION_GET_CLIENTS_LIST,
    ACTION_GET_PRESETS,
    ACTION_UPDATE_CLIENT,
    ACTION_GET_CLIENTS_LIST_WITH_PRESET,
    ACTION_GET_CLIENTS_LIST_WITHOUT_PRESET,
    ACTION_REFRESH_STATISTIC,
    ACTION_GET_CLIENTS_TOTALS,
    ACTION_CREATE_SMS,
    ACTION_CLIENT_AFFILIATE
} from './action-types'

import {
    SET_CLIENT_LIST,
    SET_PRESETS,
    UPDATE_CLIENT,
    UPDATE_CLIENT_BALANCE,
    SET_CLIENT_LIST_PAGES,
    SET_CLIENTS_SUMMARY,
    SET_TOTALS_LOADING,
    SET_CLIENTS_LIST_LOADING
} from './mutation-types'

export const actions = {
    // GET CLIENTS
    async [ACTION_GET_CLIENTS_LIST]({ commit, dispatch }, payload) {
        commit(SET_CLIENTS_LIST_LOADING, true)
        let list
        if (payload.preset) {
            list = await dispatch(ACTION_GET_CLIENTS_LIST_WITH_PRESET, payload)
        } else {
            list = await dispatch(ACTION_GET_CLIENTS_LIST_WITHOUT_PRESET, payload)
        }
        commit(SET_CLIENT_LIST, list)
        commit(SET_CLIENTS_LIST_LOADING, false)
        return list
    },

    // GET TOTALS
    async [ACTION_GET_CLIENTS_TOTALS]({ commit }, payload) {
        commit(SET_TOTALS_LOADING, true)
        let totals
        const payload2 = removeEmptyParamsFromRequest({ ...payload })
        if (payload2?.preset) {
            totals = await Api.get('/api/client/list-by-preset', { ...payload2 }, {}, false)
        } else {
            totals = await Api.get('/api/client/list-by-params', { ...payload2 }, {}, false)
        }
        commit(SET_CLIENTS_SUMMARY, totals.data)
        commit(SET_CLIENT_LIST_PAGES, {
            last_page: Math.ceil(totals.data.count / payload.per_page),
            total: totals.data.count
        })

        commit(SET_TOTALS_LOADING, false)
    },

    // GET CLIENTS WITH PRESET
    async [ACTION_GET_CLIENTS_LIST_WITH_PRESET](_, payload) {
        const { data } = await Api.get('/api/client/list-by-preset', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        return data
    },

    // GET CLIENTS WITHOUT PRESET
    async [ACTION_GET_CLIENTS_LIST_WITHOUT_PRESET](_, payload) {
        const { data } = await Api.get('/api/client/list-by-params', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        return data
    },

    // add store credit
    async [ACTION_ADD_STORE_CREDIT]({ commit }, payload) {
        const { data } = await Api.post('/api/client-credit/create', payload)
        commit(UPDATE_CLIENT_BALANCE, {
            clientid: data.clientid,
            new_balance: data.credit
        })
        return data
    },
    // update client
    async [ACTION_UPDATE_CLIENT]({ commit }, payload) {
        const { data } = await Api.put('/api/client/update', payload)
        commit(UPDATE_CLIENT, payload)
        return data
    },

    // get presets
    async [ACTION_GET_PRESETS]({ commit }) {
        const presets = await Api.get('/api/client/list-presets')
        const tmp = presets.data.map((item) => ({
            ...item,
            preset: item.value,
            count: item.counters?.count || 0
        }))
        commit(SET_PRESETS, {
            data: tmp
        })
    },

    // refresh statistics

    async [ACTION_REFRESH_STATISTIC](ctx, payload) {
        await Api.post('/api/client-statistics/calculate', payload)
    },
    async [ACTION_CREATE_SMS](ctx, payload) {
        await Api.post('/api/client/notify/sms-direct', payload)
    },
    async [ACTION_CLIENT_AFFILIATE](ctx, payload) {
        await Api.post('/api/client-affiliate/create-or-update', payload)
    }
}
