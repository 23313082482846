import Api from '@/helpers/api/index.js'

import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'

import {
    ACTION_ACTIVATE_ADMIN,
    ACTION_CHANGE_AVATAR,
    ACTION_DEACTIVATE_ADMIN,
    ACTION_GET_ADMIN_LIST,
    ACTION_UPDATE_ADMIN_ROLES
} from './action-types'

import {
    ACTIVATE_ADMIN,
    DEACTIVATE_ADMIN,
    SET_ADMIN_AVATAR_URL,
    SET_ADMIN_LIST,
    UPDATE_ADMIN_ROLES
} from './mutation-types'

export const actions = {
    // GET ORDER LIST
    async [ACTION_GET_ADMIN_LIST]({ commit }, payload) {
        const { data } = await Api.get('/api/admin/list-by-params', { ...removeEmptyParamsFromRequest(payload), mode: 'list' })
        const { data: totals } = await Api.get('/api/admin/list-by-params', { ...removeEmptyParamsFromRequest(payload), mode: 'count' })

        commit(SET_ADMIN_LIST, {
            data: data.data,
            current_page: data.current_page,
            last_page: Math.ceil(totals.count / payload.per_page),
            total: totals.count
        })
    },
    async [ACTION_UPDATE_ADMIN_ROLES]({ commit }, payload) {
        const { data } = await Api.put('/api/admin/assign-role', removeEmptyParamsFromRequest(payload));
        commit(UPDATE_ADMIN_ROLES, data)
    },
    async [ACTION_ACTIVATE_ADMIN]({ commit }, payload) {
        await Api.put('/api/admin/update', { admin_id: payload, is_active: true })
        commit(ACTIVATE_ADMIN, payload)
    },
    async [ACTION_DEACTIVATE_ADMIN]({ commit }, payload) {
        await Api.put('/api/admin/update', { admin_id: payload, is_active: false })
        commit(DEACTIVATE_ADMIN, payload)
    },
    // CHANGE AVATAR
    async [ACTION_CHANGE_AVATAR]({ commit }, payload) {
        const result = await Api.post('/api/admin/update-avatar', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
        commit(SET_ADMIN_AVATAR_URL, {
            id: payload.get('id'),
            avatar_url: result.data
        })
    }
}
