import { updateField } from 'vuex-map-fields';
import { findAndReplaceInArray } from '@/helpers/utils/index.js'
import dayjs from 'dayjs'

import {
    SET_MAILING_LIST,
    CREATE_MAILING,
    DELETE_MAILING,
    SET_MEMBERS_GROUP,
    SET_POPULATE_PRESETS,
    SET_MEMBERS_LIST,
    SET_MAILING_LIST_TOTALS_LOADING,
    SET_MAILING_LIST_TOTAL,
    // TEMPLATES
    SET_MAILING_TEMPLATES_LIST,
    DELETE_TEMPLATE,
    // LOG
    SET_MAILS_LOG_LIST,
    SET_MAILS_LOG_TOTAL,
    SET_MAILS_LOG_TOTALS_LOADING
} from './mutation-types'

export const mutations = {
    updateField,
    [SET_MAILING_LIST](state, payload) {
        state.mailingList = payload
        state.mailingCurrentPage = payload.current_page
    },
    [SET_MAILING_LIST_TOTAL](state, payload) {
        state.mailingTotal = payload.total
        state.mailingLastPage = payload.last_page
    },
    [SET_MAILING_LIST_TOTALS_LOADING](state, payload) {
        state.mailingTotalsLoading = payload
    },
    [CREATE_MAILING](state, payload) {
        const data = {
            ...payload,
            created_at: dayjs(),
            address: `${payload.name.toLowerCase()}@${payload.domain}`,
            members_count: 0
        }
        state.mailingList.unshift(data)
    },
    [DELETE_MAILING](state, payload) {
        const index = state.mailingList.findIndex(({ address }) => address === payload.address)
        state.mailingList.splice(index, 1)
    },
    [SET_POPULATE_PRESETS](state, payload) {
        state.populatePresets = payload
    },
    [SET_MEMBERS_GROUP](state, payload) {
        findAndReplaceInArray(state.mailingList, payload.address, 'address', { members_count: payload.count })
    },
    [SET_MEMBERS_LIST](state, payload) {
        state.membersList = payload
    },

    // TEMPLATES

    [SET_MAILING_TEMPLATES_LIST](state, payload) {
        state.mailingTemplatesList = payload
    },
    [DELETE_TEMPLATE](state, payload) {
        const index = state.mailingTemplatesList.findIndex(({ id }) => id === payload.id)
        state.mailingTemplatesList.splice(index, 1)
    },
    // LOG
    [SET_MAILS_LOG_LIST](state, payload) {
        state.mailsloglist = payload.data
        state.logCurrentPage = payload.current_page
    },
    [SET_MAILS_LOG_TOTAL](state, payload) {
        state.mailslogTotal = payload.total
        state.logLastPage = payload.last_page
    },
    [SET_MAILS_LOG_TOTALS_LOADING](state, payload) {
        state.logTotalsLoading = payload
    }
}
