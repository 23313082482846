export default [
    {
        path: '/orders',
        component: () => import(/* webpackChunkName: "orders" */ '@/views/Order/OrdersList'),
        meta: {
            requiresAuth: true,
            name: 'Orders',
            layout: 'dashboard-layout',
            permissions: [
                // 'order/view'
            ],
            keepAlive: true
        },
        name: 'orders'
    },
    {
        path: '/orders/:id',
        component: () => import(/* webpackChunkName: "orders" */ '@/views/Order/OrderDetails'),
        meta: {
            requiresAuth: true,
            name: 'Order Details',
            layout: 'dashboard-layout',
            breadcrumb() {
                const { id } = this.$route.params;

                return {
                    label: id,
                    parent: 'orders'
                };
            },
            permissions: [],
            keepAlive: true
        },
        children: [
            {
                path: '',
                name: 'order-details',
                component: () => import(/* webpackChunkName: "orders" */ '@/views/Order/Tabs/Details'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    scrollToTop: true,
                    permissions: [],
                    keepAlive: true
                }
            },
            {
                path: 'notes',
                name: 'order-details-notes',
                component: () => import(/* webpackChunkName: "orders" */ '@/views/Order/Tabs/Notes'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    scrollToTop: true,
                    permissions: [],
                    keepAlive: true
                }
            },
            {
                path: 'comment-log',
                name: 'order-details-comments',
                component: () => import(/* webpackChunkName: "orders" */ '@/views/Order/Tabs/CommentsLog'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    permissions: [],
                    keepAlive: true
                }
            },
            {
                path: 'client-chat',
                name: 'order-details-client',
                component: () => import(/* webpackChunkName: "orders" */ '@/views/Order/Tabs/SupportClient'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    permissions: [],
                    keepAlive: true
                }
            },
            {
                path: 'writer-chat',
                name: 'order-details-writer',
                component: () => import(/* webpackChunkName: "orders" */ '@/views/Order/Tabs/SupportWriter'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    permissions: [],
                    keepAlive: true
                }
            },
            {
                path: 'logs',
                name: 'order-details-logs',
                component: () => import(/* webpackChunkName: "orders" */ '@/views/Order/Tabs/Logs'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    permissions: [],
                    keepAlive: true
                }
            },
            {
                path: 'files',
                name: 'order-details-files',
                component: () => import(/* webpackChunkName: "orders" */ '@/views/Order/Tabs/FilesMain'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    permissions: [],
                    keepAlive: true
                }
            },
            {
                path: 'files-add',
                name: 'order-details-additional-files',
                component: () => import(/* webpackChunkName: "orders" */ '@/views/Order/Tabs/FilesAdd'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    permissions: [],
                    keepAlive: true
                }
            },
            {
                path: 'feedback',
                name: 'order-details-feedback',
                component: () => import(/* webpackChunkName: "orders" */ '@/views/Order/Tabs/Feedback'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    permissions: [],
                    keepAlive: true
                }
            },
            {
                path: 'gateway',
                name: 'order-details-gateway',
                component: () => import(/* webpackChunkName: "orders" */ '@/views/Order/Tabs/Gateway'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    permissions: [],
                    keepAlive: true
                }
            },
            {
                path: 'activity',
                name: 'order-activity',
                component: () => import(/* webpackChunkName: "orders" */ '@/views/Order/Tabs/Activity.vue'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    permissions: [],
                    keepAlive: true
                }
            }
        ]
    }
]
