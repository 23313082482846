export const SET_ORDER_LIST = 'SET_ORDER_LIST'

export const CLEAR_ORDER_LIST = 'CLEAR_ORDER_LIST'

export const SET_ORDER_LIST_LOADING = 'SET_ORDER_LIST_LOADING'

export const SET_ORDER_LIST_PAGES = 'SET_ORDER_LIST_PAGES'

export const SET_FILES_LIST = 'SET_FILES_LIST'

export const UPDATE_FILES_LIST = 'UPDATE_FILES_LIST'

export const SET_ORDER_SUMMARY = 'SET_ORDER_SUMMARY'

export const SET_WRITER = 'SET_WRITER'

export const SET_SW_TOTAL = 'SET_SW_TOTAL'

export const SET_DEADLINE = 'SET_DEADLINE'

export const SET_PRESETS = 'SET_PRESETS'

export const SET_PRESETS_LOADING = 'SET_PRESETS_LOADING'

export const SET_TOTALS_LOADING = 'SET_TOTALS_LOADING'

export const ASSIGN_BID = 'ASSIGN_BID'

export const ORDER_DROP = 'ORDER_DROP'

export const DELETE_BID = 'DELETE_BID'

export const SET_PAYLOAD = 'SET_PAYLOAD'

export const REVISION_ORDER = 'REVISION_ORDER'

export const SET_ORDER_LIST_LIVE = 'SET_ORDER_LIST_LIVE'

export const SET_TIMER = 'SET_TIMER'

export const CLEAR_TIMER = 'CLEAR_TIMER'

export const SET_NOTES = 'SET_NOTES'

export const POST_NOTE = 'POST_NOTE'

export const DELETE_NOTE = 'DELETE_NOTE'

export const EDIT_NOTE = 'EDIT_NOTE'

export const DELETE_NOTE_COUNTER = 'DELETE_NOTE_COUNTER'

export const SET_ORDERS_COMPLETED_LIST = 'SET_ORDERS_COMPLETED_LIST'

export const SET_ORDER_STATUS = 'SET_ORDER_STATUS'
