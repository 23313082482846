export const statusText = (str) => {
    const copyStr = str.toUpperCase()
    if (copyStr === 'PENDING') {
        return 'In progress'
    }
    if (copyStr === 'REVISION') {
        return 'In revision'
    }
    if (copyStr === 'TO_REFUND') {
        return 'To Refund'
    }
    return str
}

export const statusBgColor = (str) => {
    str = str.toUpperCase()
    if (str === 'PAID' || str === 'COMPLETE' || str === 'CLIENT' || str === 'WRITERS' || str === 'ACTIVE' || str === 'WAITING FOR REPLY') {
        return 'rgba(89, 199, 77, 0.1)'
    }
    if (str === 'FREE' || str === 'INQUIRY') {
        return 'rgba(136,86,242,.1)'
    }
    if (str === 'NEW' || str === 'WRITER' || str === 'OPEN' || str === 'SUGGESTION' || str === 'REQUEST') {
        return 'rgba(17,97,224,.1)'
    }
    if (str === 'PENDING' || str === 'REVISION' || str === 'APPLICANTS' || str === 'BASIC' || str === 'COMPLAINT') {
        return 'rgba(255, 105, 12, 0.1)'
    }
    if (str === 'UNPAID' || str === 'TO_REFUND' || str === 'TO REFUND' || str === 'OVERDUE' || str === 'CLOSED' || str === 'WEBSITE') {
        return 'rgba(212, 28, 28, 0.1)'
    }
    if (str === 'TODAY' || str === 'YESTERDAY') {
        return 'rgba(46, 139, 192, 0.1)'
    }
    return 'rgba(12, 45, 72, 0.1)'
}

export const statusTextColor = (str) => {
    str = str.toUpperCase()
    if (str === 'PAID' || str === 'COMPLETE' || str === 'CLIENT' || str === 'WRITERS' || str === 'ACTIVE' || str === 'WAITING FOR REPLY') {
        return '#59C74D'
    }
    if (str === 'NEW' || str === 'WRITER' || str === 'OPEN' || str === 'SUGGESTION' || str === 'REQUEST') {
        return '#1161e0'
    }
    if (str === 'FREE' || str === 'INQUIRY') {
        return '#8856f2'
    }
    if (str === 'PENDING' || str === 'REVISION' || str === 'APPLICANTS' || str === 'BASIC' || str === 'COMPLAINT') {
        return '#FF690C'
    }
    if (str === 'UNPAID' || str === 'TO_REFUND' || str === 'TO REFUND' || str === 'OVERDUE' || str === 'CLOSED' || str === 'WEBSITE') {
        return '#D41C1C'
    }
    if (str === 'TODAY' || str === 'YESTERDAY') {
        return 'rgba(46, 139, 192, 1)'
    }
    return '#0c2d48'
}
