export default [
    {
        path: '/batch',
        component: () => import('@/views/Batch/BatchesList'),
        meta: {
            requiresAuth: true,
            name: 'Batch',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        },
        name: 'batch'
    },
    {
        path: '/batch/:id',
        name: 'batch-details',
        component: () => import(/* webpackChunkName: "feedbacks" */ '@/views/Batch/BatchDetails'),
        meta: {
            requiresAuth: true,
            name: 'batch-detail',
            layout: 'dashboard-layout',
            breadcrumb() {
                const { id } = this.$route.params;

                return {
                    label: id,
                    parent: 'batch'
                };
            },
            permissions: [],
            scrollToTop: true,
            keepAlive: true
        }
    }
]
