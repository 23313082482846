/* eslint-disable class-methods-use-this */
import axios from 'axios'
import { cacheAdapterEnhancer } from 'axios-extensions';
import Auth from '~/helpers/auth/index.js'

export class Http {
    // TO DO - remove the hardcoded baseUrl and make it through .env
    create() {
        const headers = {
            baseURL: process.env.VUE_APP_API_URL,
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Cache-Control': 'no-cache'
            },
            adapter: cacheAdapterEnhancer(axios.defaults.adapter)
        }

        if (Auth.getToken()) {
            headers.Authorization = `Bearer ${Auth.getToken()}`
        }

        const instance = axios.create(headers)

        // request interceptor
        instance.interceptors.request.use(async (config) => {
            const token = await Auth.getToken()
            // eslint-disable-next-line no-param-reassign
            if (token) {
                config.headers.Authorization = `Bearer ${token}`
            }
            return config
        }, (error) => Promise.reject(error))

        // TO DO - response interceptor
        // Response interceptor for API calls
        // axiosApiInstance.interceptors.response.use((response) => {
        //     return response
        // }, async function (error) {
        //     const originalRequest = error.config;
        //     if (error.response.status === 403 && !originalRequest._retry) {
        //         originalRequest._retry = true;
        //         const access_token = await refreshAccessToken();
        //         axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        //         return axiosApiInstance(originalRequest);
        //     }
        //     return Promise.reject(error);
        // });

        return instance
    }
}

export default new Http()
