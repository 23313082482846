export const SET_WRITERS_LIST = 'SET_WRITERS_LIST'

export const SET_WRITERS_LIST_LAST_PAGE = 'SET_WRITERS_LIST_LAST_PAGE'

export const ACTIVATE_WRITER = 'ACTIVATE_WRITER'

export const DEACTIVATE_WRITER = 'DEACTIVATE_WRITER'

export const UPDATE_WRITER = 'UPDATE_WRITER'

export const SET_STATUS_TRUSTED_TO_TRUSTED = 'SET_STATUS_TRUSTED_TO_TRUSTED'

export const SET_STATUS_TRUSTED_TO_NEW = 'SET_STATUS_TRUSTED_TO_NEW'

export const DELETE_FILE_FROM_LIST = 'DELETE_FILE_FROM_LIST'

export const UPDATE_WRITER_FILES_LIST = 'UPDATE_WRITER_FILES_LIST'

export const SET_NOTE_LIST = 'SET_NOTE_LIST'

export const DELETE_NOTE_FROM_LIST = 'DELETE_NOTE_FROM_LIST'

export const POST_NOTE = 'POST_NOTE'

export const EDIT_WRITER_NOTE = 'EDIT_WRITER_NOTE'

export const SET_WRITER_LIMIT = 'SET_WRITER_LIMIT'

export const SET_PRESETS = 'SET_PRESETS'

export const SET_WRITER = 'SET_WRITER'

export const SET_AVATAR_URL = 'SET_AVATAR_URL'

export const SET_WRITERS_SHORT_LIST = 'SET_WRITERS_SHORT_LIST'

export const SET_WRITERS_SUMMARY = 'SET_WRITERS_SUMMARY'

export const SET_TOTALS_LOADING = 'SET_TOTALS_LOADING'

export const SET_WRITERS_LIST_LOADING = 'SET_WRITERS_LIST_LOADING'

export const CLEAR_WRITERS_LIST = 'CLEAR_WRITERS_LIST'

export const SET_PRESETS_LOADING = 'SET_PRESETS_LOADING'

export const SET_WRITERS_PAYMENTS_LIST_LOADING = 'SET_WRITERS_PAYMENTS_LIST_LOADING'

export const SET_WRITERS_PAYMENTS_LIST = 'SET_WRITERS_PAYMENTS_LIST'

export const SET_WRITERS_PAYMENTS_WRITERS_SUMMARY = 'SET_WRITERS_PAYMENTS_WRITERS_SUMMARY'

export const SET_WRITERS_PAYMENTS_TOTALS_LOADING = 'SET_WRITERS_PAYMENTS_TOTALS_LOADING'

export const SET_WRITERS_PAYMENTS_LIST_LAST_PAGE = 'SET_WRITERS_PAYMENTS_LIST_LAST_PAGE'

export const SET_PAYOUTS_PRESETS_LOADING = 'SET_PAYOUTS_PRESETS_LOADING'

export const SET_PAYOUTS_PRESETS = 'SET_PAYOUTS_PRESETS'

export const UPDATE_PAYOUT = 'UPDATE_PAYOUT'

// APPLICANT

export const ACTIVATE_APPLICANT = 'ACTIVATE_APPLICANT'

export const OPEN_APPLICANT = 'OPEN_APPLICANT'

export const DEACTIVATE_APPLICANT = 'DEACTIVATE_APPLICANT'

export const SET_PRESETS_APPLICANT = 'SET_PRESETS_APPLICANT'
