import Api from '@/helpers/api/index.js'
import Auth from '@/helpers/auth/index.js'

import {
    ACTION_UPDATE_SELF,
    ACTION_LOGIN,
    ACTION_LOGOUT,
    ACTION_REFRESH_TOKEN,
    ACTION_REGISTER,
    ACTION_RESET_PASSWORD,
    ACTION_UPDATE_CLIENT_DATA,
    ACTION_UPLOAD_AVATAR,
    ACTION_ADMIN_TOUCH
} from './action-types'

import {
    CHANGE_AVATAR,
    UPDATE_SELF,
    LOGOUT_ADMIN,
    SET_ADMIN,
    SET_TOUCH_INTERVAL,
    CLEAR_TOUCH_INTERVAL
} from './mutation-types'

export const actions = {
    async [ACTION_LOGOUT]({ commit }) {
        Auth.logout()
        Auth.logoutVuex()
        commit(CLEAR_TOUCH_INTERVAL)
        commit(LOGOUT_ADMIN)
    },
    async [ACTION_REFRESH_TOKEN](ctx, payload) {
        const { data } = await Api.post('/api/auth/refresh-token', {
            ...payload
        })
        const token = {
            token: data.data.access_token,
            ...data.data
        }
        Auth.login(token)
    },
    async [ACTION_ADMIN_TOUCH]({ commit, getters }) {
        if (getters.getterIsAuth) {
            const touchInterval = setInterval(async () => {
                await Api.get('/api/auth/touch')
            }, 1000 * 60 * (process.env.VUE_APP_TOUCH_DELAY_MINUTES || 1));
            commit(SET_TOUCH_INTERVAL, touchInterval)
        } else {
            commit(CLEAR_TOUCH_INTERVAL)
        }
    },
    // Login user
    async [ACTION_LOGIN]({ commit }, payload) {
        const { data } = await Api.post('/api/auth/login', payload)
        Auth.login(data.token_data)
        commit(SET_ADMIN, data.user)
    },

    // Register user
    async [ACTION_REGISTER](ctx, payload) {
        return Api.post('/api/auth/register', payload)
    },

    // Register user
    async [ACTION_UPDATE_CLIENT_DATA]({ commit }) {
        const { data } = await Api.get('/api/admin/current-user')
        commit(SET_ADMIN, data)
    },

    // RESET PASSWORD
    async [ACTION_RESET_PASSWORD](ctx, payload) {
        return Api.post('/api/auth/forgot-password/send-email', payload)
    },

    // UPDATE SELF
    async [ACTION_UPDATE_SELF]({ commit }, payload) {
        return Api.put('/api/admin/update-self', payload)
            .then(() => {
                commit(UPDATE_SELF, payload)
            })
            .catch((err) => {
                throw err
            })
    },

    // CHANGE_AVATAR
    async [ACTION_UPLOAD_AVATAR]({ commit }, payload) {
        const result = await Api.post('/api/admin/update-avatar', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
        commit(CHANGE_AVATAR, result)
    }
}
