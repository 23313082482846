import Api from '@/helpers/api/index.js'

import {
    ACTION_GET_TICKET,
    ACTION_GET_SUPPORT_CHAT,
    ACTION_RESET_STATE
} from './action-types'
import {
    SET_SUPPORT_CHAT,
    SET_SUPPORT_CHAT_LOADING,
    RESET_STATE
} from './mutation-types'

import { actions_client } from './actions-client'
import { actions_writer } from './actions-writer'

export const actions = {
    ...actions_client,
    ...actions_writer,

    [ACTION_RESET_STATE]({ commit }) {
        commit(RESET_STATE, [])
    },
    // GET TICKET
    async [ACTION_GET_TICKET](ctx, payload) {
        const { data } = await Api.get(`/api/support/${payload.type}/ticket/fetch`, payload)
        return data
    },
    //
    async [ACTION_GET_SUPPORT_CHAT]({ commit }, payload) {
        commit(SET_SUPPORT_CHAT, [])
        commit(SET_SUPPORT_CHAT_LOADING, true)
        const { data } = await Api.get(`/api/support/${payload.isWriter ? 'writer' : 'client'}/message/list`, payload)
        commit(SET_SUPPORT_CHAT, data)
        commit(SET_SUPPORT_CHAT_LOADING, false)
    }
}
