// import { SchemaAdmin } from '@/models/SchemaAdmin.js'

export const initialState = {
    promoList: [],
    currentPage: 0,
    lastPage: 0,
    total: 0,
    presets: [],
    promoListLoading: true,
    summary: {},
    totalsLoading: true
}

export const state = { ...initialState }
