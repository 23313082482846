export const ACTION_GET_MAILING_LIST = 'ACTION_GET_MAILING_LIST'
export const ACTION_FETCH_MAILING = 'ACTION_FETCH_MAILING'
export const ACTION_DELETE_MAILING = 'ACTION_DELETE_MAILING'
export const ACTION_CREATE_MAILING = 'ACTION_CREATE_MAILING'
export const ACTION_GET_MEMBERS_GROUP = 'ACTION_GET_MEMBERS_GROUP'
export const ACTION_POPULATE_MEILING = 'ACTION_POPULATE_MEILING'
export const ACTION_SEND_MEILING = 'ACTION_SEND_MEILING'
export const ACTION_ADD_MEMBERS = 'ACTION_ADD_MEMBERS'
export const ACTION_REMOVE_MEMBER = 'ACTION_REMOVE_MEMBER'
export const ACTION_GET_MAILING_LIST_TOTALS = 'ACTION_GET_MAILING_LIST_TOTALS'

// TEMPLATES

export const ACTION_GET_MAILING_TEMPLATES_LIST = 'ACTION_GET_MAILING_TEMPLATES_LIST'
export const ACTION_GET_MAILING_TEMPLATE = 'ACTION_GET_MAILING_TEMPLATE'
export const ACTION_DELETE_TEMPLATE = 'ACTION_DELETE_TEMPLATE'
export const ACTION_CREATE_OR_UPDATE_TEMPLATE = 'ACTION_CREATE_OR_UPDATE_TEMPLATE'

// LOG

export const ACTION_GET_MAILS_LOG_LIST = 'ACTION_GET_MAILS_LOG_LIST'
export const ACTION_GET_MAILS_LOG_FETCH = 'ACTION_GET_MAILS_LOG_FETCH'
export const ACTION_GET_MAILS_LOG_TOTALS = 'ACTION_GET_MAILS_LOG_TOTALS'

export const ACTION_CREATE_MAIL_LIST = 'ACTION_CREATE_MAIL_LIST'
