export default [
    {
        path: '/message-log',
        component: () => import('@/views/MessageLog/MessageLog.vue'),
        meta: {
            requiresAuth: true,
            name: 'Message Log',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        },
        name: 'message-log'
    }
]
