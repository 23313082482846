import { createHelpers } from 'vuex-map-fields';

import { state } from './state.js';
import { getters } from './getters.js';
import { mutations } from './mutations.js';
import { actions } from './actions.js';

export const {
    mapFields: mapAdminsFields,
    mapFields: mapAdminsFormFields
} = createHelpers({
    getterType: 'admin/getField',
    mutationType: 'admin/updateField'
});

export const permissions = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
