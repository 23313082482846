var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getterMainInfo)?_c('div',{staticClass:"d-flex main-header",staticStyle:{"position":"relative"}},[_c('search'),_c('bookmark-menu'),_c('notification-list'),_c('v-menu',{staticClass:"header_menu",attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"px-4 header_avatar"},'div',attrs,false),on),[_c('span',{staticStyle:{"position":"relative","padding-right":"10px"}},[_c('avatar-rolles',{attrs:{"access-status":"admin","firstname":_vm.getterMainInfo.firstname,"lastname":_vm.getterMainInfo.lastname,"avatar-url":_vm.getterMainInfo.avatar_url,"avatar-fallback":_vm.getterMainInfo.avatar_fallback,"avatar-size":"45"}})],1)])]}}],null,false,620930730)},[_c('v-list',{staticClass:"header_mnu"},[_vm._l((_vm.menu),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(!item.list)?_c('router-link',{staticClass:"py-2 d-flex align-center  text-decoration-none",class:hover ? 'accent--text' : 'black--text',attrs:{"to":{name: item.route_name}}},[_c('img',{staticClass:"mr-2",staticStyle:{"width":"24px","height":"24px"},attrs:{"src":item.icon,"alt":""}}),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.title))])]):_c('v-list-group',{staticClass:"menu",attrs:{"value":false},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('img',{staticClass:"mr-2",staticStyle:{"width":"24px","height":"24px"},attrs:{"src":item.icon,"alt":""}}),_c('span',{staticClass:"font-weight-medium",staticStyle:{"position":"relative"}},[_vm._v(" "+_vm._s(item.title)+" ")])])]},proxy:true}],null,true)},_vm._l((item.list),function(subitem,subindex){return _c('v-list-item',{key:subindex},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var subHover = ref.hover;
return [_c('router-link',{staticClass:"py-2 font-weight-medium d-flex align-center  text-decoration-none subitem",class:subHover ? 'accent--text' : 'black--text',attrs:{"to":{name: subitem.route_name, query: { preset: subitem.query } }}},[_vm._v(" "+_vm._s(subitem.text)+" "),(subitem.counter === 0 || subitem.counter)?_c('span',{staticClass:"count",style:(("background-color: " + (_vm.statusBgColor(subitem.text)) + "; color: " + (_vm.statusTextColor(subitem.text))))},[_vm._v(" "+_vm._s(subitem.counter)+" ")]):_vm._e()])]}}],null,true)})],1)}),1)]}}],null,true)})],1)],1)}),_c('v-list-item',{staticStyle:{"cursor":"pointer"}},[_c('v-list-item-title',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('span',{staticClass:"font-weight-medium mr-2 d-flex align-center",class:hover ? 'accent--text' : 'black--text',on:{"click":function($event){return _vm.logout()}}},[_c('img',{staticClass:"mr-2",staticStyle:{"width":"24px","height":"24px"},attrs:{"src":require("@/assets/images/svg/logout 3.svg")}}),_vm._v(" SIGN OUT ")])]}}],null,false,4016694032)})],1)],1)],2)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }