import { createHelpers } from 'vuex-map-fields';

import { actions } from './actions.js';
import { state } from './state.js';
import { mutations } from './mutations.js';
import { getters } from './getters.js';

export const {
    mapFields: mapEmailViewerFields,
    mapFields: mapEmailViewerFormFields
} = createHelpers({
    getterType: 'emailViewer/getField',
    mutationType: 'emailViewer/updateField'
});

export const emailViewer = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
