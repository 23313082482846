export const SET_PROMO_LIST = 'SET_PROMO_LIST'

export const CREATE_PROMO = 'CREATE_PROMO'

export const SET_STATUS_PROMO = 'SET_STATUS_PROMO'

export const SET_PRESETS = 'SET_PRESETS'

export const SET_PROMO_LIST_LOADING = 'SET_PROMO_LIST_LOADING'

export const SET_TOTALS_LOADING = 'SET_TOTALS_LOADING'

export const SET_PROMO_LIST_PAGES = 'SET_PROMO_LIST_PAGES'

export const CLEAR_PROMO_LIST = 'CLEAR_PROMO_LIST'

export const SET_PROMO_SUMMARY = 'SET_PROMO_SUMMARY'
