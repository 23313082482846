<template>
    <div class="notice-messages">
        <transition-group
            name="notice-messages-list"
            tag="div"
        >
            <div
                v-for="notice in notificationList"
                :key="notice.id"
                :class="{
                    'notice-messages__item-success': notice.type === 'success',
                    'notice-messages__item-info': notice.type === 'info',
                    'notice-messages__item-error': notice.type === 'error'
                }"
                class="notice-messages__item"
            >
                <div class="notice-messages__item-inside">
                    <span
                        v-if="notice.type === 'success'"
                        class="notice-messages__item-inside__icon"
                    >
                        <v-icon color="white">
                            mdi-check-circle-outline
                        </v-icon>
                    </span>

                    <span
                        v-if="notice.type === 'info'"
                        class="notice-messages__item-inside__icon"
                    >
                        <v-icon color="white">
                            mdi-alert-outline
                        </v-icon>
                    </span>

                    <span
                        v-if="notice.type === 'error'"
                        class="notice-messages__item-inside__icon"
                    >
                        <v-icon color="white">
                            mdi-alert-circle-outline
                        </v-icon>
                    </span>

                    <div
                        class="notice-messages__item-inside__text text--white"
                        v-html="msgProcessed(notice.msg)"
                    />

                    <button
                        class="notice-messages__item-inside__close"
                        color="white"
                        @click="delItem(notice.id)"
                    />
                </div>
            </div>
        </transition-group>
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus';

export default {
    data() {
        return {
            notificationList: []
        }
    },
    created() {
        eventBus.$on('showSnackBar', (msg, type) => {
            const notification = {
                msg,
                type,
                id: new Date().toISOString()
            }
            this.notificationList.unshift(notification)
            setTimeout(() => {
                this.deleteNotification()
            }, 5000);
        })
    },
    methods: {
        deleteNotification() {
            this.notificationList.pop()
        },
        msgProcessed(str) {
            if (typeof str === 'string') {
                return str.replace(/\n/g, '<br />')
            }
            return str
        }
    }
}
</script>

<style lang="scss">
    .notice-messages-list-enter {
        opacity: 0;
        transform: translateX(80px);
    }

    .notice-messages-list-leave-active {
        position: absolute;
        left: 0;
        transform: translateY(60px);
    }
    .notice-messages-list-leave-to  {
        opacity: 0;
    }

    .notice-messages {
        position: fixed;
        right: 20px;
        bottom: 100px;
        width: 350px;
        z-index: 99999999999;
        &__item {
            transition: all 1s;
            display: inline-block;
            margin-bottom: 10px;
            width: 100%;
            padding: 25px 0px 25px 20px;
            border-radius: 4px;
            &-success {
                background: #4ed03c;
            }
            &-info {
                background: #ff7b2f;
            }
            &-error {
                background: #ff2f2f;
            }
            &-inside {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                &__icon {
                    width: 34px;
                    height: 34px;
                    svg{
                        width: 34px;
                        height: 34px;
                    }
                }
                &__text {
                    flex-grow: 2;
                    line-height: 1.2;
                    margin: 0 20px;
                    font-size: 16px;
                    font-weight: 500;
                    color: #fff;
                }
                &__close {
                    color: #fff !important;
                    background: transparent;
                    border: none;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
</style>
