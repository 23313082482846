import { cloneDeep } from 'lodash'

export const initialState = {
    loginSessionList: [],
    currentPage: 0,
    lastPage: 0,
    total: 0,
    presets: []
}

export const state = cloneDeep(initialState)
