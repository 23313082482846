import { cloneDeep } from 'lodash';
import { updateField } from 'vuex-map-fields';
import { findAndReplaceInArray } from '@/helpers/utils/index.js'
import {
    SET_CLIENT_LIST,
    SET_PRESETS,
    UPDATE_CLIENT,
    UPDATE_CLIENT_BALANCE,
    SET_CLIENT_LIST_PAGES,
    SET_CLIENTS_SUMMARY,
    SET_TOTALS_LOADING,
    SET_CLIENTS_LIST_LOADING,
    CLEAR_CLIENT_LIST
} from './mutation-types'

import { initialState } from './state'

export const mutations = {
    updateField,
    [CLEAR_CLIENT_LIST](state) {
        Object.assign(state, cloneDeep(initialState))
    },
    [SET_CLIENT_LIST](state, payload) {
        state.clientsList = payload.data
        state.currentPage = payload.current_page
    },
    [SET_CLIENT_LIST_PAGES](state, payload) {
        state.lastPage = payload.last_page
        state.total = payload.total
    },
    [SET_CLIENTS_SUMMARY](state, payload) {
        state.summary = { ...payload }
    },
    [SET_CLIENTS_LIST_LOADING](state, payload) {
        state.clientsListLoading = payload
    },
    [SET_TOTALS_LOADING](state, payload) {
        state.totalsLoading = payload
    },
    [UPDATE_CLIENT](state, payload) {
        findAndReplaceInArray(state.clientsList, payload.id, 'id', payload)
    },
    [SET_PRESETS](state, payload) {
        state.presets = payload.data
    },
    [UPDATE_CLIENT_BALANCE](state, payload) {
        const index = state.clientsList.findIndex((item) => item.id === payload.clientid)
        if (index !== -1) {
            state.clientsList.splice(index, 1, {
                ...state.clientsList[index],
                statistics: {
                    ...state.clientsList[index].statistics,
                    money: {
                        ...state.clientsList[index].statistics.money,
                        account_balance: Number(state.clientsList[index].statistics.money.account_balance) + payload.new_balance
                    }
                }
            })
        }
    }
}
