import Api from '@/helpers/api/index.js'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'

import {
    ACTION_GET_CLIENTS_CREDITS_LIST,
    ACTION_GET_CLIENTS_CREDITS_TOTALS,
    ACTION_GET_WRITER_CREDITS_LIST,
    ACTION_GET_WRITER_CREDITS_TOTALS
} from './action-types'

import {
    SET_CLIENTS_CREDITS_LIST,
    SET_CLIENTS_CREDITS_LIST_LOADING,
    SET_CLIENTS_CREDITS_LIST_PAGES,
    SET_CLIENTS_CREDITS_TOTAL,
    SET_WRITERS_CREDITS_LIST,
    SET_WRITERS_CREDITS_LIST_LOADING,
    SET_WRITERS_CREDITS_LIST_PAGES,
    SET_WRITERS_CREDITS_TOTAL,
    SET_WRITERS_CREDITS_TOTALS_LOADING,
    SET_CLIENTS_CREDITS_TOTALS_LOADING
} from './mutation-types'

export const actions = {
    // GET CREDITS LIST
    async [ACTION_GET_CLIENTS_CREDITS_LIST]({ commit }, payload) {
        // GET LIST
        commit(SET_CLIENTS_CREDITS_LIST_LOADING, true)
        const { data } = await Api.get('/api/client-credit/list-by-params', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        commit(SET_CLIENTS_CREDITS_LIST, {
            data: data.data
        })
        commit(SET_CLIENTS_CREDITS_LIST_LOADING, false)
    },
    // GET TOTALS
    async [ACTION_GET_CLIENTS_CREDITS_TOTALS]({ commit }, payload) {
        commit(SET_CLIENTS_CREDITS_TOTALS_LOADING, true)
        const totals = await Api.get('/api/client-credit/list-by-params', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        commit(SET_CLIENTS_CREDITS_LIST_PAGES, {
            last_page: Math.ceil(totals.data.count / payload.per_page),
            total: totals.data.count
        })
        commit(SET_CLIENTS_CREDITS_TOTAL, {
            total_credit: totals.data.credit,
            total_debit: totals.data.debit
        })
        commit(SET_CLIENTS_CREDITS_TOTALS_LOADING, false)
    },

    // GET WRITER CREDITS LIST
    async [ACTION_GET_WRITER_CREDITS_LIST]({ commit }, payload) {
        // GET LIST
        commit(SET_WRITERS_CREDITS_LIST_LOADING, true)
        const { data } = await Api.get('/api/writer-credit/list-by-params', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        commit(SET_WRITERS_CREDITS_LIST, {
            data: data.data
        })
        commit(SET_WRITERS_CREDITS_LIST_LOADING, false)
    },
    // GET TOTALS
    async [ACTION_GET_WRITER_CREDITS_TOTALS]({ commit }, payload) {
        commit(SET_WRITERS_CREDITS_TOTALS_LOADING, true)
        const totals = await Api.get('/api/writer-credit/list-by-params', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        commit(SET_WRITERS_CREDITS_LIST_PAGES, {
            last_page: Math.ceil(totals.data.count / payload.per_page),
            total: totals.data.count
        })
        commit(SET_WRITERS_CREDITS_TOTAL, {
            total_credit: totals.data.credit,
            total_debit: totals.data.debit
        })
        commit(SET_WRITERS_CREDITS_TOTALS_LOADING, false)
    }
}
