<template>
    <div
        v-click-outside="toggleMenu"
        class="custom-category-header"
    >
        <div class="d-flex justify-space-between align-center px-4 py-4">
            <div
                class="d-flex align-center service"
                style="padding-left: 10px; color: #fff"
            >
                SERVICES
            </div>
            <v-icon
                class="close"
                color="#fff"
                @click="toggleMenu"
            >
                mdi-close
            </v-icon>
        </div>
        <v-divider />
        <div class="custom-category-header__content-block">
            <ul>
                <li
                    v-for="(item, index) in menu"
                    :key="index"
                    style="list-style-type: none"
                >
                    <div class="d-flex align-center justify-space-between pr-1">
                        <div
                            class="d-flex align-center py-3"
                        >
                            <img
                                :src="item.icon"
                                alt=""
                                style="width: 18px; height: 18px;"
                            >
                            <span
                                class="category-title-menu"
                                @click="toggleSubmenu(index, item.type)"
                            >
                                {{ item.title }}
                            </span>
                            <router-link
                                v-if="item.route_name"
                                :to="{name: item.route_name}"
                                class="pl-2"
                                style="color: #fff; font-size: 12px"
                            >
                                <span class="view-all">view all</span>
                            </router-link>
                        </div>
                        <v-icon
                            class="chevron-right"
                            color="white"
                            @click="toggleSubmenu(index, item.type)"
                        >
                            mdi-chevron-right
                        </v-icon>
                    </div>
                    <div class="">
                        <ul
                            v-if="isSubmenuShow === index"
                            class="custom-category-header__submenu"
                        >
                            <div
                                v-if="isLoading"
                                class="d-flex w-full justify-center h-full flex-column align-center"
                                style="height: 600px"
                            >
                                <v-progress-circular
                                    :size="100"
                                    :width="5"
                                    color="white"
                                    indeterminate
                                />
                            </div>
                            <template v-else>
                                <li
                                    v-for="(child, childIndex) in item.list"
                                    :key="childIndex"
                                    style="list-style-type: none"
                                >
                                    <router-link
                                        v-if="child.route_name !== 'support-writer'&& child.route_name !== 'support-client' && child.route_name !== 'orders' && child.route_name !== 'batch' && child.route_name !== 'hr-clients' && child.route_name !== 'client-invitation'"
                                        :to="{
                                            name: child.route_name,
                                            query: {preset: child.query}
                                        }"
                                        class="d-flex py-2 align-center text-decoration-none justify-space-between w-full"
                                    >
                                        <span class="category-title-submenu">{{ child.text }}</span>
                                        <span
                                            v-if="child.counter !== undefined"
                                            class="category-counter-submenu"
                                        >{{ child.counter }}</span>
                                    </router-link>
                                </li>
                                <template v-for="(key, idx) in listServices">
                                    <div
                                        v-if="Object.hasOwn(item.list, key)"
                                        :key="idx"
                                    >
                                        <router-link
                                            :to="{
                                                name: item.list[key].route_name,
                                            }"
                                            class="d-flex py-2 align-center text-decoration-none justify-space-between w-full"
                                        >
                                            <span
                                                class="category-title-submenu"
                                                style="text-decoration: underline"
                                            >
                                                {{ item.list[key].text }}
                                            </span>
                                        </router-link>
                                        <ul>
                                            <li
                                                v-for="(child, childIndex) in item.list[key].list"
                                                :key="childIndex"
                                                style="list-style-type: none"
                                            >
                                                <router-link
                                                    :to="{
                                                        name: child.route_name,
                                                        query: {preset: child.query}
                                                    }"
                                                    class="d-flex py-2 align-center text-decoration-none justify-space-between w-full"
                                                >
                                                    <span class="category-title-submenu">{{ child.text }}</span>
                                                    <span
                                                        v-if="child.counter !== undefined"
                                                        class="category-counter-submenu"
                                                    >
                                                        {{ child.counter }}
                                                    </span>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </template>
                            </template>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

import { DashboardMenuBuilder } from '@/services/dashboardService';

export default {
    name: 'CategoryHeader',
    props: {
        drawer: {
            type: Boolean
        }
    },
    data() {
        return {
            menu: null,
            isSubmenuShow: false,
            dashboard: new DashboardMenuBuilder(),
            isLoading: false
        }
    },
    computed: {
        listServices() {
            return ['writer', 'client', 'order', 'order_batch', 'clients', 'clientInvitation']
        }
    },
    watch: {
        $route() {
            this.$emit('toggleMenu')
        }
    },
    async created() {
        this.menu = this.dashboard.fullMenu(this.dashboard.categoryHeaderMenu)
    },
    methods: {
        toggleMenu() {
            this.$emit('toggleMenu')
        },
        async toggleSubmenu(index, type) {
            if (type !== '') {
                this.isSubmenuShow === index
                    ? this.isSubmenuShow = false
                    : this.isSubmenuShow = index
                this.isLoading = true
                await this.dashboard.getCounters(type)
                this.isLoading = false
                this.menu = this.dashboard.fullMenu(this.dashboard.categoryHeaderMenu)
            } else {
                this.isSubmenuShow === index
                    ? this.isSubmenuShow = false
                    : this.isSubmenuShow = index
            }
        }
    }
}
</script>

<style lang="scss">
.custom-category-header{
    background-color: #232f3f;
    position: absolute;
    z-index: 1;
    top: 64px;
    left: 85px;
    height: 100vh;
    width: 300px;
    box-shadow: 0px 0px 10px 3px rgb(25 50 71 / 25%);
    @media (max-width: 768px) {
        width: 250px;
        left: 0;
        top: 0;
    }
    @media (max-width: 480px) {
        width: 200px;
    }
    &__submenu {
        position: absolute;
        width: 280px;
        background-color: #232f3f;
        box-shadow: 0px 0px 10px 3px rgb(25 50 71 / 25%);
        top: 0;
        left: 315px;
        height: 100vh;
        padding: 20px;
        height: 100%;
        overflow: scroll;
        @media (max-width: 768px) {
            left: 252px;
            width: 200px;
        }
        @media (max-width: 480px) {
            left: 202px;
            padding: 10px;
            width: 150px;
        }
    }
    &__content-block {
        height: calc(100% - 120px);
        overflow-y: scroll;
    }
    &__content-block ul {
        @media (max-width: 768px) {
            padding-left: 10px !important;
        }
    }
}

.category-title-menu {
    font-weight: 300;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
    width: 100px;
    margin-left: 16px;
    @media (max-width: 768px) {
        left: 0;
        top: 56px;
        font-size: 12px;
        margin-left: 8px;
    }
    @media (max-width: 480px) {
        width: 80px;
    }
    &:hover {
        color: #f90;
    }
}

.category-title-submenu {
    font-weight: 300;
    font-size: 16px;
    color: #fff;
    @media (max-width: 768px) {
        font-size: 14px;
    }
    @media (max-width: 480px) {
        font-size: 12px;
    }
    &:hover {
        color: #f90;
    }
}

.category-counter-submenu {
    background-color: #fff;
    padding: 2px 4px;
    border-radius: 4px;
    font-weight: 600;
    @media (max-width: 768px) {
        font-size: 14px;
    }
    @media (max-width: 480px) {
        margin-left: 5px;
        font-size: 12px;
    }
}

.view-all {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 2px 6px;
    border-radius: 16px;
    white-space: nowrap;
    @media (max-width: 768px) {
        margin-right: 5px;
        font-size: 12px;
    }
    &:hover {
        background-color: white;
        color: #232f3f;
    }
}
</style>
