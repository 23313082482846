import { updateField } from 'vuex-map-fields';
import { findAndReplaceInArray } from '@/helpers/utils/index.js'
import {
    DELETE_ROLE,
    SET_ROLES_LIST,
    UPDATE_ROLE,
    UPDATE_ROLE_PERMISSIONS,
    SET_ALL_PERMISSIONS
} from './mutation-types'

export const mutations = {
    updateField,
    [SET_ROLES_LIST](state, payload) {
        state.rolesList = payload.data
        state.lastPage = payload.last_page
        state.total = payload.total
    },
    [SET_ALL_PERMISSIONS](state, payload) {
        state.permissions = payload
    },
    [UPDATE_ROLE_PERMISSIONS](state, payload) {
        findAndReplaceInArray(state.rolesList, payload.id, 'id', payload)
    },
    [DELETE_ROLE](state, id) {
        state.rolesList = state.rolesList.filter((role) => role.id !== id)
    },
    [UPDATE_ROLE](state, payload) {
        findAndReplaceInArray(state.rolesList, payload.id, 'id', payload)
    }
}
