export default [
    {
        path: '/settings/variables',
        name: 'settings-variables',
        component: () => import(/* webpackChunkName: "support" */ '@/views/Settings/Variables'),
        meta: {
            requiresAuth: true,
            name: 'Variables',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        }
    },
    {
        path: '/settings/site-settings',
        name: 'site-settings',
        component: () => import(/* webpackChunkName: "support" */ '@/views/Settings/Sites'),
        meta: {
            requiresAuth: true,
            name: 'Site settings',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        }
    },
    {
        path: '/settings/mails',
        name: 'mails',
        component: () => import(/* webpackChunkName: "support" */ '@/views/Settings/Mails'),
        meta: {
            requiresAuth: true,
            name: 'Mails Remarketing',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        }
    },
    {
        path: '/settings/mails-log',
        name: 'mails-log',
        component: () => import(/* webpackChunkName: "support" */ '@/views/Settings/MailsLog'),
        meta: {
            requiresAuth: true,
            name: 'Mailing Log',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        }
    },
    {
        path: '/settings/mails-edit/:id',
        name: 'mails-edit',
        component: () => import(/* webpackChunkName: "support" */ '@/views/Settings/MailsEdit'),
        meta: {
            requiresAuth: true,
            name: 'Mails Remarketing: Edit',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        }
    },
    {
        path: '/settings/mails-create',
        name: 'mails-create',
        component: () => import(/* webpackChunkName: "support" */ '@/views/Settings/MailsEdit'),
        meta: {
            requiresAuth: true,
            name: 'Mails Remarketing: Create',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        }
    },
    {
        path: '/settings/mailing-list',
        name: 'mailing-list',
        component: () => import(/* webpackChunkName: "support" */ '@/views/Settings/MailingList'),
        meta: {
            requiresAuth: true,
            name: 'Mailing: List',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        }
    },
    {
        path: '/settings/mailing-templates',
        name: 'mailing-templates',
        component: () => import(/* webpackChunkName: "support" */ '@/views/Settings/MailingTemplates'),
        meta: {
            requiresAuth: true,
            name: 'Mailing: Templates',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        }
    },
    {
        path: '/settings/mailing-template-edit/:id',
        name: 'mailing-template-edit',
        component: () => import(/* webpackChunkName: "support" */ '@/views/Settings/MailingTemplateEdit'),
        meta: {
            requiresAuth: true,
            name: 'Mailing Template: Edit',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        }
    },
    {
        path: '/settings/mailing-template-create',
        name: 'mailing-template-create',
        component: () => import(/* webpackChunkName: "support" */ '@/views/Settings/MailingTemplateEdit'),
        meta: {
            requiresAuth: true,
            name: 'Mailing Template: Create',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        }
    }
]
