import { cloneDeep } from 'lodash'

export const initialState = {
    loginMessageLog: [],
    currentPage: 0,
    lastPage: 0,
    total: 0
}

export const state = cloneDeep(initialState)
