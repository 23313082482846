import Api from '@/helpers/api/index.js'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'

import {
    ACTION_CLIENT_INVITATION_LIST,
    ACTION_CLIENT_INVITATION_PRESETS,
    ACTION_CLEAR_STORE
} from './action-types'

import {
    SET_CLIENT_INVITATION_LIST,
    SET_CLIENT_INVITATION_PRESETS,
    SET_CLIENT_INVITATION_TOTALS,
    CLEAR_STORE
} from './mutation-types'

export const actions = {
    [ACTION_CLEAR_STORE]({ commit }) {
        commit(CLEAR_STORE)
    },
    async [ACTION_CLIENT_INVITATION_LIST]({ commit }, payload) {
        let responses = null
        if (payload.preset) {
            responses = await Promise.all([
                Api.get('/api/client-invitation/list-by-preset', removeEmptyParamsFromRequest({ ...payload, mode: 'list' })),
                Api.get('/api/client-invitation/list-by-preset', removeEmptyParamsFromRequest({ ...payload, mode: 'count' }))
            ])
        } else {
            responses = await Promise.all([
                Api.get('/api/client-invitation/list-by-params', removeEmptyParamsFromRequest({ ...payload, mode: 'list' })),
                Api.get('/api/client-invitation/list-by-params', removeEmptyParamsFromRequest({ ...payload, mode: 'count' }))
            ])
        }

        const { data } = responses[0]
        const { data: totals } = responses[1]
        commit(SET_CLIENT_INVITATION_LIST, data)
        commit(SET_CLIENT_INVITATION_TOTALS, {
            count: totals.count,
            last_page: Math.ceil(totals.count / payload.per_page),
            totalFromCount: totals.total
        })
        return data
    },
    // get presets
    async [ACTION_CLIENT_INVITATION_PRESETS]({ commit }) {
        const presets = await Api.get('/api/client-invitation/list-presets')
        const tmp = presets.data.map((item) => ({
            ...item,
            preset: item.value,
            count: item.counters?.count || 0
        }))
        commit(SET_CLIENT_INVITATION_PRESETS, { data: tmp })
    }
}
