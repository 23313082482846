<template>
    <div class="notifier">
        <transition-group name="list">
            <div
                v-for="item in list"
                :key="item.render_id"
                class="notifier_item"
                :class="`notifier_item--${item.type}`"
                @click="goToDetails(item.id)"
            >
                <v-icon
                    class="mr-2"
                >
                    {{ getIconByType(item.type) }}
                </v-icon>
                <div class="d-flex flex-column flex-grow-1 notifier_item-content">
                    <p class="font-weight-medium mb-1 d-flex">
                        <span class="flex-grow-1 mr-2">
                            {{ item.title }}
                        </span>
                        <v-icon
                            @click.stop="closeNotification(item.render_id)"
                        >
                            mdi-close
                        </v-icon>
                    </p>
                    <p
                        v-if="item.description"
                        class="mb-0"
                    >
                        «{{ item.description }}»
                    </p>
                </div>
            </div>
        </transition-group>
    </div>
</template>

<script>
import ChangeTitle from '@/helpers/changeTitle.js'
import Sound from '@/assets/sounds/correct-answer.wav';

import { createNamespacedHelpers } from 'vuex';

import {
    ACTION_ADD_NOTIFICATION
} from '@/store/modules/notifications/action-types';

const {
    mapActions: mapNotificationsActions
} = createNamespacedHelpers('notifications');

export default {
    data() {
        return {
            list: [],
            currId: 0
        }
    },
    created() {
        this.$socket.on('snackbar:create', (data) => {
            this.playSound()
            this.titleChange()
            this.list = [...this.list, { ...data, render_id: this.currId }]
            this.currId += 1
            this[ACTION_ADD_NOTIFICATION]([{ ...data, render_id: this.currId }])
            setTimeout(() => {
                this.list.shift()
            }, 10000);
        })
    },
    methods: {
        ...mapNotificationsActions({
            ACTION_ADD_NOTIFICATION
        }),
        getIconByType(type) {
            switch (type) {
            case 'default':
                return 'mdi-bell-badge-outline'
            case 'success':
                return 'mdi-check'
            case 'warning':
                return 'mdi-alert-box-outline'
            case 'error':
                return 'mdi-alert-circle-outline'

            default:
                return 'mdi-lightbulb-alert-outline'
            }
        },
        closeNotification(id) {
            const index = this.list.findIndex(({ render_id }) => render_id === id)
            this.list.splice(index, 1)
        },
        goToDetails(id) {
            if (!id) return
            const isOrderId = id.includes('S-')
            const isClientTicket = id.includes('TC-')
            const isWriterTicket = id.includes('TW-')
            if (isOrderId) {
                this.$router.push({
                    name: 'order-details',
                    params: {
                        id
                    }
                })
            }
            if (isClientTicket) {
                this.$router.push({
                    name: 'support-details',
                    params: {
                        id,
                        type: 'client'
                    }
                })
            }
            if (isWriterTicket) {
                this.$router.push({
                    name: 'support-details',
                    params: {
                        id,
                        type: 'writer'
                    }
                })
            }
        },
        playSound() {
            const audio = new Audio(Sound);
            audio.play();
        },
        titleChange() {
            ChangeTitle.start()
        }
    }
}
</script>

<style lang="scss">
    .notifier{
        position: fixed;
        right: 40px;
        bottom: 40px;
        display: flex;
        flex-direction: column;
        width: 300px;
        &_item {
            width: 100%;
            padding: 10px 20px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.75);
            margin-bottom: 20px;
            cursor: pointer;
            transition: .3s background;
            background: #fff;
            &--default {
                background-color: #cce5ff;
                color: #004085;
                & > i {
                    color: #004085;
                }
            }
            &--success {
                color: #155724;
                background-color: #d4edda;
                & > i {
                    color: #155724;
                }
            }
            &--warning {
                color: #856404;
                background-color: #fff3cd;
                & > i {
                    color: #856404;
                }
            }
            &--error {
                color: #721c24;
                background-color: #f8d7da;
                & > i {
                    color: #721c24;
                }
            }
            &-content {
                max-width: calc(100% - 32px);
                word-break: break-word;
            }
        }
    }
    .list-enter-active, .list-leave-active {
        transition: all .3s;
    }
    .list-enter, .list-leave-to{
        opacity: 0;
        transform: translateY(30px);
    }
</style>
