import { createHelpers } from 'vuex-map-fields';

import { actions } from './actions.js';
import { state } from './state.js';
import { mutations } from './mutations.js';
import { getters } from './getters.js';

export const {
    mapFields: mapEmailBuilderFields,
    mapFields: mapEmailBuilderFormFields
} = createHelpers({
    getterType: 'emailBuilder/getField',
    mutationType: 'emailBuilder/updateField'
});

export const emailBuilder = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
