export const SET_CLIENTS_CREDITS_LIST = 'SET_CLIENTS_CREDITS_LIST'
export const SET_CLIENTS_CREDITS_LIST_LOADING = 'SET_CLIENTS_CREDITS_LIST_LOADING'
export const SET_CLIENTS_CREDITS_LIST_PAGES = 'SET_CLIENTS_CREDITS_LIST_PAGES'
export const SET_CLIENTS_CREDITS_TOTAL = 'SET_CLIENTS_CREDITS_TOTAL'
export const SET_CLIENTS_CREDITS_TOTALS_LOADING = 'SET_CLIENTS_CREDITS_TOTALS_LOADING'
export const SET_WRITERS_CREDITS_LIST = 'SET_WRITERS_CREDITS_LIST'
export const SET_WRITERS_CREDITS_LIST_LOADING = 'SET_WRITERS_CREDITS_LIST_LOADING'
export const SET_WRITERS_CREDITS_LIST_PAGES = 'SET_WRITERS_CREDITS_LIST_PAGES'
export const SET_WRITERS_CREDITS_TOTAL = 'SET_WRITERS_CREDITS_TOTAL'
export const SET_WRITERS_CREDITS_TOTALS_LOADING = 'SET_WRITERS_CREDITS_TOTALS_LOADING'
