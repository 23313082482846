import { updateField } from 'vuex-map-fields';
// import { cloneDeep } from 'lodash'
// import { initialState } from './state'

import {
    SET_EMAILS_LIST,
    SET_SELECT_EMAIL
} from './mutation-types'

export const mutations = {
    updateField,
    [SET_EMAILS_LIST](state, payload) {
        state.emailsList = payload
    },
    [SET_SELECT_EMAIL](state, payload) {
        state.selectEmail = payload
    }
}
