import { getField } from 'vuex-map-fields'

export const getters = {
    getField,
    getterLoginSessionList(state) {
        return state.loginSessionList
    },
    getterLastPage(state) {
        return state.lastPage
    },
    getterTotal(state) {
        return state.total
    },
    getterCurrentPage(state) {
        return state.currentPage
    },
    getterSessionLoginPresets(state) {
        return state.presets
    },
    getterSummary(state) {
        return {
            total: state.total,
            pages: state.lastPage
        }
    }
}
