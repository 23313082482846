import Api from '@/helpers/api/index.js'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'

import {
    ACTION_DELETE_NOTIFICATION,
    ACTION_GET_NOTIFICATIONS,
    ACTION_GET_NOTIFICATIONS_LOADMORE,
    ACTION_ADD_NOTIFICATION,
    ACTION_DELETE_NOTIFICATIONS_ALL
} from './action-types'

import {
    DELETE_NOTIFICATION,
    SET_NOTIFICATIONS_LIST,
    SET_NOTIFICATIONS_LIST_LOADMORE,
    SET_NOTIFICATION,
    DELETE_NOTIFICATIONS_ALL
} from './mutation-types'

export const actions = {
    async [ACTION_GET_NOTIFICATIONS]({ commit }, payload) {
        const { data } = await Api.get('/api/alert/list', removeEmptyParamsFromRequest({ ...payload }))
        commit(SET_NOTIFICATIONS_LIST, data)
    },

    async [ACTION_GET_NOTIFICATIONS_LOADMORE]({ commit }, payload) {
        const { data } = await Api.get('/api/alert/list', removeEmptyParamsFromRequest({ ...payload }))
        commit(SET_NOTIFICATIONS_LIST_LOADMORE, data)
    },

    async [ACTION_ADD_NOTIFICATION]({ commit }, payload) {
        commit(SET_NOTIFICATION, payload)
    },

    async [ACTION_DELETE_NOTIFICATION]({ commit }, payload) {
        // await Api.delete('/api/alert/delete-one', { ...payload })
        commit(DELETE_NOTIFICATION, { ...payload })
    },

    async [ACTION_DELETE_NOTIFICATIONS_ALL]({ commit }) {
        // await Api.delete('/api/alert/delete-all', { ...payload })
        commit(DELETE_NOTIFICATIONS_ALL)
    }
}
