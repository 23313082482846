import { updateField } from 'vuex-map-fields';
import { findAndReplaceInArray } from '@/helpers/utils/index.js'
import {
    ACTIVATE_ADMIN,
    DEACTIVATE_ADMIN,
    SET_ADMIN_AVATAR_URL,
    SET_ADMIN_LIST,
    UPDATE_ADMIN_ROLES
} from './mutation-types'

export const mutations = {
    updateField,
    [SET_ADMIN_LIST](state, payload) {
        state.adminList = payload.data
        state.last_page = payload.last_page
        state.total = payload.total
    },
    [UPDATE_ADMIN_ROLES](state, payload) {
        findAndReplaceInArray(state.adminList, payload.id, 'id', payload)
    },
    [ACTIVATE_ADMIN](state, payload) {
        findAndReplaceInArray(state.adminList, payload, 'id', { is_active: 1 })
    },
    [DEACTIVATE_ADMIN](state, payload) {
        findAndReplaceInArray(state.adminList, payload, 'id', { is_active: 0 })
    },
    [SET_ADMIN_AVATAR_URL](state, payload) {
        findAndReplaceInArray(state.adminList, +payload.id, 'id', {
            avatar: payload.avatar_url,
            avatar_url: payload.avatar_url
        })
    }
}
