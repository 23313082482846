export default [
    {
        path: '/project-type',
        component: () => import(/* webpackChunkName: "orders" */ '@/views/Crud/ProjectTypes'),
        meta: {
            requiresAuth: true,
            name: 'Project Types',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        },
        name: 'project-types'
    }
]
