import { getField } from 'vuex-map-fields'

export const getters = {
    getField,
    getterClientsList(state) {
        return state.clientsList
    },
    getterLastPage(state) {
        return state.lastPage
    },
    getterTotal(state) {
        return state.total
    },
    getterCurrentPage(state) {
        return state.currentPage
    },
    getterPresets(state) {
        return state.presets
    },
    getterSummary(state) {
        return state.summary
    },
    getterTotalsLoading(state) {
        return state.totalsLoading
    },
    getterListLoading(state) {
        return state.clientsListLoading
    }
}
