export default [
    {
        path: '/login',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth/Login'),
        meta: {
            layout: 'auth-layout',
            scrollToTop: true,
            permissions: []
        },
        name: 'login'
    },
    {
        path: '/register',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth/Register'),
        meta: {
            layout: 'auth-layout',
            scrollToTop: true,
            permissions: []
        },
        name: 'register'
    },
    {
        path: '/reset-password',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth/ResetPassword'),
        meta: {
            layout: 'auth-layout',
            scrollToTop: true,
            permissions: []
        },
        name: 'reset-password'
    },
    {
        path: '/auth/client/password/show-reset-form/:token',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth/NewPasswordForm'),
        meta: {
            layout: 'auth-layout',
            scrollToTop: true,
            permissions: []
        },
        name: 'new-password'
    }
]
