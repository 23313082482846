export const ACTION_GET_CLIENTS_LIST = 'ACTION_GET_CLIENTS_LIST'

export const ACTION_ADD_STORE_CREDIT = 'ACTION_ADD_STORE_CREDIT'

export const ACTION_UPDATE_CLIENT = 'ACTION_UPDATE_CLIENT'

export const ACTION_GET_PRESETS = 'ACTION_GET_PRESETS'

export const ACTION_GET_CLIENTS_LIST_WITH_PRESET = 'ACTION_GET_CLIENTS_LIST_WITH_PRESET'

export const ACTION_GET_CLIENTS_LIST_WITHOUT_PRESET = 'ACTION_GET_CLIENTS_LIST_WITHOUT_PRESET'

export const ACTION_GET_CLIENTS_TOTALS = 'ACTION_GET_CLIENTS_TOTALS'

export const ACTION_REFRESH_STATISTIC = 'ACTION_REFRESH_STATISTIC'

export const ACTION_CREATE_SMS = 'ACTION_CREATE_SMS'

export const ACTION_CLIENT_AFFILIATE = 'ACTION_CLIENT_AFFILIATE'
