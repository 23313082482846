// import { SchemaAdmin } from '@/models/SchemaAdmin.js'

export const initialState = {
    feedbacksList: [],
    total: 0,
    lastPage: 0,
    preset: [],
    summary: {},
    totalsLoading: true,
    feedbacksListLoading: true
}

export const state = { ...initialState }
