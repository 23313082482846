<template>
    <div class="auth-page">
        <v-main class="fill-height">
            <v-container class="fill-height">
                <v-row justify="center">
                    <div class="form">
                        <router-view />
                    </div>
                </v-row>
            </v-container>
        </v-main>
    </div>
</template>

<script>
export default {
    name: 'AuthLayout'
}
</script>

<style lang="scss">
    .auth-page{
        background: #F5F5F5;
        min-height: 100vh;
        .form{
            background: #fff;
            max-width: 470px;
            width: 100%;
        }
    }
</style>
