export default [
    {
        name: 'permissions',
        path: '/permissions',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/Access/Permissions'),
        meta: {
            requiresAuth: true,
            name: 'Permissions',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        }
    },
    {
        name: 'roles',
        path: '/roles',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/Access/Roles'),
        meta: {
            requiresAuth: true,
            name: 'Roles',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        }
    },
    {
        path: '/admins',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/Access/Admins'),
        meta: {
            requiresAuth: true,
            name: 'Admins',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        },
        name: 'admins'
    }
]
