export const ACTION_GET_TICKET = 'ACTION_GET_TICKET'

export const ACTION_RESET_STATE = 'ACTION_RESET_STATE'

// CLIENT

export const ACTION_GET_SUPPORT_LIST = 'ACTION_GET_SUPPORT_LIST'

export const ACTION_START_LIVE_UPDATE = 'ACTION_START_LIVE_UPDATE'

export const ACTION_STOP_LIVE_UPDATE = 'ACTION_STOP_LIVE_UPDATE'

export const ACTION_SEND_MESSAGE = 'ACTION_SEND_MESSAGE'

export const ACTION_CREATE_TICKET = 'ACTION_CREATE_TICKET'

export const ACTION_UPDATE_TICKET = 'ACTION_UPDATE_TICKET'

export const ACTION_CLOSE_TICKET = 'ACTION_CLOSE_TICKET'

export const ACTION_CLOSE_TICKETS_MASS = 'ACTION_CLOSE_TICKETS_MASS'

export const ACTION_GET_CLIENT_PRESETS = 'ACTION_GET_CLIENT_PRESETS'

export const ACTION_CLIENT_TRANSFER_TICKET = 'ACTION_CLIENT_TRANSFER_TICKET'

// WRITER

export const ACTION_WRITER_GET_SUPPORT_LIST = 'ACTION_WRITER_GET_SUPPORT_LIST'

export const ACTION_START_LIVE_UPDATE_WRITER2 = 'ACTION_START_LIVE_UPDATE_WRITER2'

export const ACTION_WRITER_SEND_MESSAGE = 'ACTION_WRITER_SEND_MESSAGE'

export const ACTION_WRITER_UPDATE_TICKET = 'ACTION_WRITER_UPDATE_TICKET'

export const ACTION_CREATE_TICKET_WRITER = 'ACTION_CREATE_TICKET_WRITER'

export const ACTION_WRITER_CLOSE_TICKET = 'ACTION_WRITER_CLOSE_TICKET'

export const ACTION_GET_WRITER_PRESETS = 'ACTION_GET_WRITER_PRESETS'

export const ACTION_WRITER_TRANSFER_TICKET = 'ACTION_WRITER_TRANSFER_TICKET'

// SUPPORT CHATS

export const ACTION_GET_SUPPORT_CHAT = 'ACTION_GET_SUPPORT_CHAT'

export const ACTION_BIND_ORDER_TO_TICKET = 'ACTION_BIND_ORDER_TO_TICKET'

export const ACTION_BIND_ORDER_TO_TICKET_WRITER = 'ACTION_BIND_ORDER_TO_TICKET_WRITER'
