// import { SchemaAdmin } from '@/models/SchemaAdmin.js'

export const initialState = {
    adminList: [],
    total: Infinity,
    last_page: Infinity,
    current_page: 0,
    roles: [],
    permissions: []
}

export const state = { ...initialState }
