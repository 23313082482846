import { getField } from 'vuex-map-fields'

export const getters = {
    getField,
    getterOrderList(state) {
        return state.orderList
    },
    getterOrderListLoading(state) {
        return state.orderListLoading
    },
    getterPresetsLoading(state) {
        return state.presetLoading
    },
    getterTotalsLoading(state) {
        return state.totalsLoading
    },
    getterLastPage(state) {
        return state.lastPage
    },
    getterTotal(state) {
        return state.total
    },
    getterCurrentPage(state) {
        return state.currentPage
    },
    getterOrderFiles(state) {
        return state.files
    },
    getterSummary(state) {
        return state.summary
    },
    getterPresets(state) {
        return state.preset
    },
    getterRequestPayload(state) {
        return state.requestPayload
    },
    getterNotes(state) {
        return state.notes
    },
    getterOrdersCompletedList(state) {
        return state.ordersCompletedList
    }
}
