export default [
    {
        path: '/payroll',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/Payroll/Payroll'),
        meta: {
            requiresAuth: true,
            name: 'Payroll',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        },
        name: 'payroll'
    }
]
