export default [
    {
        path: '/dashboard',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/Dashboard/Index'),
        meta: {
            requiresAuth: true,
            name: 'Dashboard',
            layout: 'dashboard-layout',
            permissions: []
        },
        name: 'dashboard'
    }
]
