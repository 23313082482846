export default [
    {
        path: '/user_setting',
        component: () => import(/* webpackChunkName: "writers" */ '@/views/UserSetting/UserSetting'),
        meta: {
            requiresAuth: true,
            name: 'Profile',
            layout: 'dashboard-layout',
            permissions: []
        },
        name: 'user_setting'

    },
    {
        path: '/user_setting/change-name',
        component: () => import(/* webpackChunkName: "writers" */ '@/views/UserSetting/ChangeName'),
        meta: {
            requiresAuth: true,
            name: 'Profile',
            layout: 'dashboard-layout',
            permissions: []
        },
        name: 'user_setting_change-name'
    },
    {
        path: '/user_setting/change-email',
        component: () => import(/* webpackChunkName: "writers" */ '@/views/UserSetting/ChangeEmail'),
        meta: {
            requiresAuth: true,
            name: 'Profile',
            layout: 'dashboard-layout',
            permissions: []
        },
        name: 'user_setting_change-email'
    },
    {
        path: '/user_setting/change-password',
        component: () => import(/* webpackChunkName: "writers" */ '@/views/UserSetting/ChangePassword'),
        meta: {
            requiresAuth: true,
            name: 'Profile',
            layout: 'dashboard-layout',
            permissions: []
        },
        name: 'user_setting_change-password'
    },
    {
        path: '/user_setting/change-phone',
        component: () => import(/* webpackChunkName: "writers" */ '@/views/UserSetting/ChangePhone'),
        meta: {
            requiresAuth: true,
            name: 'Profile',
            layout: 'dashboard-layout',
            permissions: []
        },
        name: 'user_setting_change-phone'
    },
    {
        path: '/user_setting/change-twofa',
        component: () => import(/* webpackChunkName: "writers" */ '@/views/UserSetting/ChangeTwoFa'),
        meta: {
            requiresAuth: true,
            name: 'Profile',
            layout: 'dashboard-layout',
            permissions: []
        },
        name: 'user_setting_change-twofa'
    }
]
