import Api from '@/helpers/api/index.js'

import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'

import {
    ACTION_CREATE_PERMISSION,
    ACTION_DELETE_PERMISSIONS,
    ACTION_GET_PERMISSIONS_LIST,
    ACTION_UPDATE_PERMISSION
} from './action-types'

import {
    CREATE_PERMISSION,
    DELETE_PERMISSIONS,
    SET_PERMISSIONS_LIST,
    UPDATE_PERMISSION
} from './mutation-types'

export const actions = {
    // GET ORDER LIST
    async [ACTION_GET_PERMISSIONS_LIST]({ commit }, payload) {
        const { data } = await Api.get('/api/permissions/permission/list-by-params', removeEmptyParamsFromRequest(payload))
        commit(SET_PERMISSIONS_LIST, data)
    },
    async [ACTION_DELETE_PERMISSIONS]({ commit }, payload) {
        await Api.delete('/api/permissions/permission/delete', { id: payload })
        commit(DELETE_PERMISSIONS, payload)
    },
    async [ACTION_UPDATE_PERMISSION]({ commit }, payload) {
        const { data } = await Api.put('/api/permissions/permission/update', removeEmptyParamsFromRequest(payload))
        commit(UPDATE_PERMISSION, payload)
        return data
    },
    async [ACTION_CREATE_PERMISSION]({ commit }, payload) {
        const { data } = await Api.post('/api/permissions/permission/create', removeEmptyParamsFromRequest(payload))
        commit(CREATE_PERMISSION, data)
        return data
    }
}
