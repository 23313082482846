import { updateField } from 'vuex-map-fields';
import {
    DELETE_NOTIFICATION,
    SET_NOTIFICATIONS_LIST,
    SET_NOTIFICATIONS_LIST_LOADMORE,
    SET_NOTIFICATION,
    DELETE_NOTIFICATIONS_ALL
} from './mutation-types'

export const mutations = {
    updateField,
    [SET_NOTIFICATIONS_LIST](state, payload) {
        state.list = [...payload.data]
        state.total = payload.total ?? state.list.length
        state.last_page = payload.last_page
    },
    [SET_NOTIFICATIONS_LIST_LOADMORE](state, payload) {
        state.list = [...state.list, ...payload.data]
    },
    [SET_NOTIFICATION](state, payload) {
        state.list = [...payload, ...state.list]
        state.total += payload.length
    },
    [DELETE_NOTIFICATION](state, payload) {
        state.list = state.list.filter((item) => item.render_id !== payload.id)
        state.total -= 1
    },
    [DELETE_NOTIFICATIONS_ALL](state) {
        state.list = []
        state.total = 0
    }
}
