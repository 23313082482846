import { getField } from 'vuex-map-fields'

export const getters = {
    getField,
    /* clients */
    getterClientsCreditsList(state) {
        return state.clientsCreditsList
    },
    getterClientsLastPage(state) {
        return state.clientsLastPage
    },
    getterClientsTotal(state) {
        return state.clientsTotal
    },
    getterClientsTotalCredit(state) {
        return state.clientsTotalCredit
    },
    getterClientsTotalDebit(state) {
        return state.clientsTotalDebit
    },
    getterClientsListLoading(state) {
        return state.clientsCreditsListLoading
    },
    getterClientsTotalLoading(state) {
        return state.clientsCreditsTotalsLoading
    },
    /* clients */

    /* writers */
    getterWritersCreditsList(state) {
        return state.writersCreditsList
    },
    getterWritersLastPage(state) {
        return state.writersLastPage
    },
    getterWritersTotal(state) {
        return state.writersTotal
    },
    getterWritersTotalCredit(state) {
        return state.writersTotalCredit
    },
    getterWritersTotalDebit(state) {
        return state.writersTotalDebit
    },
    getterWritersListLoading(state) {
        return state.writersCreditsListLoading
    },
    getterWritersTotalLoading(state) {
        return state.writersCreditsTotalsLoading
    }
    /* writers */
}
