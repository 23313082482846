import { getField } from 'vuex-map-fields'

export const getters = {
    getField,
    getterMailingList(state) {
        return state.mailingList
    },
    getterPopulatePresets(state) {
        return state.populatePresets
    },
    getterMembersList(state) {
        return state.membersList
    },
    getterMembersTemplatesList(state) {
        return state.mailingTemplatesList
    },
    getterMailsLogList(state) {
        return state.mailsloglist
    },
    getterMailsLogLastPage(state) {
        return state.logLastPage
    },
    getterMailsLogTotal(state) {
        return state.mailslogTotal
    },
    getterMailsLogCurrentPage(state) {
        return state.logCurrentPage
    },
    getterMailsLogTotalsLoading(state) {
        return state.logTotalsLoading
    },
    getterMailingLastPage(state) {
        return state.mailingLastPage
    },
    getterMailingTotal(state) {
        return state.mailingTotal
    },
    getterMailingCurrentPage(state) {
        return state.mailingCurrentPage
    },
    getterMailingTotalsLoading(state) {
        return state.mailingTotalsLoading
    }
}
