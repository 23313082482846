import { updateField } from 'vuex-map-fields';
import { cloneDeep } from 'lodash';
import { findAndReplaceInArray } from '@/helpers/utils/index.js'
import {
    ACTIVATE_APPLICANT,
    ACTIVATE_WRITER,
    DEACTIVATE_APPLICANT,
    DEACTIVATE_WRITER,
    DELETE_FILE_FROM_LIST,
    DELETE_NOTE_FROM_LIST,
    EDIT_WRITER_NOTE,
    OPEN_APPLICANT,
    POST_NOTE,
    SET_AVATAR_URL,
    SET_NOTE_LIST,
    SET_PRESETS,
    SET_PRESETS_APPLICANT,
    SET_STATUS_TRUSTED_TO_NEW,
    SET_STATUS_TRUSTED_TO_TRUSTED,
    SET_WRITER,
    SET_WRITERS_LIST,
    SET_WRITERS_SHORT_LIST,
    SET_WRITER_LIMIT,
    UPDATE_WRITER,
    UPDATE_WRITER_FILES_LIST,
    SET_WRITERS_LIST_LAST_PAGE,
    SET_WRITERS_SUMMARY,
    SET_TOTALS_LOADING,
    SET_WRITERS_LIST_LOADING,
    CLEAR_WRITERS_LIST,
    SET_PRESETS_LOADING,
    SET_WRITERS_PAYMENTS_LIST,
    SET_WRITERS_PAYMENTS_LIST_LOADING,
    SET_WRITERS_PAYMENTS_WRITERS_SUMMARY,
    SET_WRITERS_PAYMENTS_TOTALS_LOADING,
    SET_WRITERS_PAYMENTS_LIST_LAST_PAGE,
    SET_PAYOUTS_PRESETS,
    SET_PAYOUTS_PRESETS_LOADING,
    UPDATE_PAYOUT
} from './mutation-types'

import { initialState } from './state'

export const mutations = {
    updateField,
    [CLEAR_WRITERS_LIST](state) {
        Object.assign(state, cloneDeep(initialState))
    },
    [SET_WRITERS_LIST](state, payload) {
        state.writersList = payload.data
        state.currentPage = payload.current_page
    },
    [SET_WRITERS_LIST_LAST_PAGE](state, payload) {
        state.lastPage = payload.last_page
        state.total = payload.total
    },
    [SET_WRITERS_SUMMARY](state, payload) {
        state.summary = { ...payload }
    },
    [SET_WRITERS_LIST_LOADING](state, payload) {
        state.writersListLoading = payload
    },
    [SET_TOTALS_LOADING](state, payload) {
        state.totalsLoading = payload
    },
    [SET_PRESETS_LOADING](state, payload) {
        state.presetLoading = payload
    },
    [SET_PAYOUTS_PRESETS_LOADING](state, payload) {
        state.payoutPresetsLoading = payload
    },
    [SET_PAYOUTS_PRESETS](state, payload) {
        state.payoutPresets = payload.data
    },
    [ACTIVATE_WRITER](state, { writer_id }) {
        findAndReplaceInArray(state.writersList, writer_id, 'sw_id', { is_active: 1 })
    },
    [DEACTIVATE_WRITER](state, { writer_id }) {
        findAndReplaceInArray(state.writersList, writer_id, 'sw_id', { is_active: 0 })
    },
    [UPDATE_WRITER](state, payload) {
        findAndReplaceInArray(state.writersList, payload.sw_id, 'sw_id', payload)
    },
    [SET_STATUS_TRUSTED_TO_TRUSTED](state, payload) {
        payload.writers.forEach((writerId) => {
            findAndReplaceInArray(state.writersList, writerId, 'sw_id', { trust_status: 'TRUSTED' })
        })
    },
    [SET_STATUS_TRUSTED_TO_NEW](state, payload) {
        payload.writers.forEach((writerId) => {
            findAndReplaceInArray(state.writersList, writerId, 'sw_id', { trust_status: 'WRITER' })
        })
    },
    [DELETE_FILE_FROM_LIST](state, payload) {
        const index = state.files.findIndex((file) => file.id === payload.id)
        if (index !== -1) {
            state.files.splice(index, 1)
            state.writer.files_count -= 1
        }
    },
    [UPDATE_WRITER_FILES_LIST](state, payload) {
        payload.forEach((file) => {
            state.files.push(file)
        })
    },
    [SET_NOTE_LIST](state, payload) {
        state.noteList = payload
    },
    [DELETE_NOTE_FROM_LIST](state, id) {
        const index = state.noteList.findIndex((note) => note.id === id)
        state.noteList.splice(index, 1)
    },
    [POST_NOTE](state, payload) {
        const index = state.writersList.findIndex((item) => item.sw_id === payload.sw_id)
        if (index !== -1) {
            state.writersList.splice(index, 1, {
                ...state.writersList[index],
                notes_count: state.writersList[index].notes_count + 1
            })
        }
        state.noteList.unshift(payload)
    },
    [EDIT_WRITER_NOTE](state, payload) {
        findAndReplaceInArray(state.noteList, payload.id, 'id', { details: payload.details })
    },
    [SET_WRITER_LIMIT](state, { writer_id, projects_limit }) {
        findAndReplaceInArray(state.writersList, writer_id, 'sw_id', { projects_limit })
    },
    [SET_PRESETS](state, payload) {
        state.presets = payload.data
    },
    [SET_WRITER](state, payload) {
        state.writer = payload
    },
    [SET_WRITERS_SHORT_LIST](state, payload) {
        state.writersShortList = payload
    },
    [SET_WRITERS_PAYMENTS_LIST](state, payload) {
        state.payouts = payload.data
        state.payoutsCurrentPage = payload.current_page
    },
    [UPDATE_PAYOUT](state, payload) {
        const index = state.payouts.findIndex(({ paymentid }) => paymentid === payload.paymentid)
        state.payouts.splice(index, 1, {
            ...payload
        })
    },
    [SET_WRITERS_PAYMENTS_LIST_LOADING](state, payload) {
        state.payoutsListLoading = payload
    },
    [SET_WRITERS_PAYMENTS_WRITERS_SUMMARY](state, payload) {
        state.payoutsSummary = { ...payload }
    },
    [SET_WRITERS_PAYMENTS_TOTALS_LOADING](state, payload) {
        state.payoutsTotalsLoading = payload
    },
    [SET_WRITERS_PAYMENTS_LIST_LAST_PAGE](state, payload) {
        state.payoutsLastPage = payload.last_page
        state.payoutsTotal = payload.total
    },
    // APPLICANT

    [ACTIVATE_APPLICANT](state, { writer_id }) {
        findAndReplaceInArray(state.writersList, writer_id, 'sw_id', { is_active: 1, accessstatus: 'WRITER' })
    },
    [DEACTIVATE_APPLICANT](state, { writer_id }) {
        findAndReplaceInArray(state.writersList, writer_id, 'sw_id', { is_active: 0 })
    },
    [OPEN_APPLICANT](state, payload) {
        payload.writers.forEach((writerId) => {
            findAndReplaceInArray(state.writersList, writerId, 'sw_id', { is_active: 'OPEN' })
        });
    },
    [SET_AVATAR_URL](state, payload) {
        findAndReplaceInArray(state.writersList, +payload.sw_id, 'sw_id', { avatar: payload.avatar_url })
    },
    [SET_PRESETS_APPLICANT](state, payload) {
        state.applicant_presets = payload
    }
}
