<template>
    <v-app>
        <v-app-bar
            app
            class="header-toolbar"
        >
            <v-row class="header align-center">
                <v-col>
                    <div class="d-flex align-center header-logo">
                        <router-link :to="{name: 'dashboard'}">
                            <div style="position: relative">
                                <v-chip
                                    v-if="environment"
                                    class="environment rounded px-1"
                                    color="#ffe4d5"
                                    text-color="#ff5252"
                                >
                                    {{ environment }}
                                </v-chip>
                                <v-toolbar-title
                                    class="text--black mr-1 mb-0"
                                    style="height: 32px;"
                                >
                                    <img
                                        src="@images/logo.svg"
                                        alt="fwc"
                                    >
                                </v-toolbar-title>
                            </div>
                        </router-link>
                        <span
                            class="category-btn"
                            @click.stop="toggleMenu"
                        >
                            <v-icon
                                class="menu"
                                color="#1F2939 "
                            >
                                mdi-menu
                            </v-icon>
                            <span class="service">Services</span>
                        </span>
                        <category-header-custom
                            v-if="drawer"
                            @toggleMenu="toggleMenu"
                        />
                    </div>
                </v-col>
                <v-col>
                    <div class="d-flex main-header__wrapper">
                        <main-header />
                    </div>
                </v-col>
            </v-row>
        </v-app-bar>

        <v-main class="bordered">
            <breadcrumbs v-if="showBreadcrumbs" />
            <KeepAlive
                v-if="isKeepAlive"
                :max="3"
            >
                <router-view />
            </KeepAlive>
            <router-view v-else />
        </v-main>
    </v-app>
</template>

<script>
import MainHeader from '@/components/Header/MainHeader'
import Breadcrumbs from '@/components/Header/Breadcrumbs'
import CategoryHeaderCustom from '@/components/Header/CategoryHeaderCustom.vue';

export default {
    name: 'DashboardLayout',
    components: {
        CategoryHeaderCustom,
        MainHeader,
        Breadcrumbs
    },
    data() {
        return {
            timer: null,
            drawer: false
        }
    },
    computed: {
        pageName() {
            return this.$route.meta.name || 'Dashboard';
        },
        showBreadcrumbs() {
            return this.$route.name === 'dashboard' ? 0 : 1
        },
        isKeepAlive() {
            return this.$route.meta.keepAlive || false;
        },
        environment() {
            return process.env.VUE_APP_PUBLIC_APP_ENV
        }
    },
    methods: {
        toggleMenu() {
            this.drawer = !this.drawer
        }
    }
}
</script>

<style lang="scss">
    .v-app-bar{
        left: 0 !important;
    }
    .header_mnu{
        min-width: 220px;
    }
    .header_avatar{
        height: 64px;
        display: flex;
        align-items: center;
        &:hover{
            border-top: 2px solid red;
        }
    }
    .main-header {
        &, &__wrapper {
            margin-left: auto;
        }
    }
    @media (max-width:514px) {
        .header {
            position: relative;
            &-toolbar, &-toolbar .v-toolbar__content{
                height: auto !important;
            }
            .v-breadcrumbs {
                padding: 0;
            }
            .header_avatar {
                height: 40px;
                padding-left: 5px !important;
                padding-right: 5px !important;
                &:hover{
                    border-top: none
                }
            }
        }
        .expanding-search__form {
            display: none;
        }
        .v-menu__content.v-menu__content--fixed {
            max-width: 100%;
            left: 0 !important;
            right: 0;
        }
    }
    .environment{
        font-size: 8px !important;
        height: 12px !important;
        position: absolute !important;
        right: 0 !important;
        top: -5px !important;
    }

    .category-btn{
        border-left: 1px solid rgba(0, 0, 0, 0.54);
        display: flex;
        align-items: center;
        padding: 2px 6px;
        margin: 0 6px;
        cursor: pointer;
    }
    .service {
        margin-left: 5px;
        @media (max-width: 480px) {
            display: none;
            margin-left: 0;
            padding-left: 0 !important;
        }
    }
</style>
