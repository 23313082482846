<template>
    <div
        v-if="showPopup && flag"
        ref="auth"
        class="popup"
    >
        <div class="popup-wrapper">
            <div class="popup-wrapper__image">
                <v-icon large>
                    mdi-clock
                </v-icon>
            </div>
            <div class="popup-wrapper__content">
                <p class="popup-wrapper__content-title">
                    Your session is expiring!
                </p>
                <p class="popup-wrapper__content-subtitle">
                    Signing out in {{ countdownToMinutes }}
                </p>
                <v-btn
                    color="accent"
                    class="py-4 px-3 mr-4"
                    height="max-content"
                    :disabled="loading"
                    @click="confirm"
                >
                    Stay on site
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus/index.js'
import Auth from '@/helpers/auth/index.js'
import {
    ACTION_REFRESH_TOKEN
} from '@/store/modules/client/action-types.js'

const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client')

export default {
    name: 'ConfirmAuthPopup',
    data() {
        return {
            showPopup: false,
            flag: true,
            timer: null,
            countdown: null,
            loading: false
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterIsAuth'
        ]),
        countdownToMinutes() {
            const seconds = `${Math.floor(this.countdown % 60)}`.length <= 1 ? `0${Math.floor(this.countdown % 60)}` : Math.floor(this.countdown % 60)
            return seconds >= 0 ? `${Math.floor(this.countdown / 60)} min ${seconds} sec` : '0 min 0 sec'
        }
    },
    watch: {
        showPopup(newVal) {
            if (newVal) {
                this.startTimer()
            } else {
                clearInterval(this.timer)
            }
        },
        getterIsAuth() {
            clearInterval(this.timer)
            this.flag = true
            this.showPopup = false
        }
    },
    mounted() {
        eventBus.$on('showAuthPopup', (countdown) => {
            this.showPopup = true
            this.countdown = countdown
        })
        eventBus.$on('resetPopupFlag', () => {
            clearInterval(this.timer)
            this.flag = true
            this.showPopup = false
        })
        window.addEventListener('storage', (event) => {
            if (event.type === 'storage') {
                clearInterval(this.timer)
                this.flag = true
                this.showPopup = false
            }
        });
    },
    methods: {
        ...mapClientActions({
            actionRefreshToken: ACTION_REFRESH_TOKEN
        }),
        async confirm() {
            try {
                this.loading = true
                clearInterval(this.timer)
                const refresh_token = Auth.getRefreshToken()
                await this.actionRefreshToken({ refresh_token })
                this.$socket.reconnect()
                this.hidePopup()
            } catch (e) {
                eventBus.$emit('showSnackBar', e, 'error');
            } finally {
                this.loading = false
            }
        },
        startTimer() {
            this.timer = setInterval(() => {
                this.countdown -= 1
                if (this.countdown <= 0) {
                    Auth.logout()
                    Auth.logoutVuex()
                    this.$router.push({
                        name: 'login'
                    })
                    this.hidePopup()
                }
            }, 1000);
        },
        hidePopup() {
            window.dispatchEvent(new Event('storage'));
        }
    }
}
</script>

<style lang="scss">
    .popup {
        position: fixed;
        right: 0px;
        bottom: 0px;
        padding: 30px;
        max-width: 600px;
        z-index: 999;
        background: #fff;
        box-shadow: 0px 0px 51px rgba(0, 0, 0, 0.07);
        border-radius: 6px;
        border-bottom-right-radius: 0;
        &:after {
            content: "";
            width: 100%;
            height: 4px;
            background: #82b1ff;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
        }
        &-wrapper {
            position: relative;
            display: flex;
            &__image {
                width: 69px;
                height: 69px;
                margin-right: 36px;
                color: #82b1ff;
                svg {
                    width: 69px;
                    height: 69px;
                }
            }
            &__content {
                p {
                    margin-top: 0;
                }
                &-title {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 28px;
                    line-height: 1.06;
                    margin-bottom: 20px;
                }
                &-subtitle {
                    font-size: 20px;
                    margin-bottom: 30px;
                }
            }
            &__cross {
                position: absolute;
                right: -10px;
                top: 0px;
                color: #878787;
                cursor: pointer;
            }
        }
    }
    @media screen and (max-width: 768px){
        .popup {
            right: 0;
            bottom: 0;
        }
    }
    @media screen and (max-width: 568px){
        .popup {
            &-wrapper {
                &__image {
                    display: none;
                }
            }
        }
    }
</style>
