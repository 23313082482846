<template>
    <v-menu
        bottom
        offset-y
        class="bookmarks"
    >
        <template v-slot:activator="{ on, attrs }">
            <div
                v-bind="attrs"
                class="px-4 header_avatar d-flex justify-center"
                v-on="on"
            >
                <v-icon
                    color="#82b1ff"
                >
                    mdi-star-outline
                </v-icon>
                <v-avatar
                    class="bookmark-counter"
                    size="25"
                    color="rgba(39, 174, 96, 0.1)"
                >
                    <span
                        class="text-subtitle-2"
                        style="color: rgb(39, 174, 96);"
                    >{{ getterTotal }}</span>
                </v-avatar>
            </div>
        </template>

        <v-list
            class="bookmark_mnu px-3"
        >
            <p class="text-subtitle-1">
                Bookmark
            </p>
            <!-- Filter -->
            <div
                class="bookmarks_filter w-full d-flex justify-space-between mb-4"
                @click.stop
            >
                <span
                    class="w-full text-center text-subtitle-2"
                    :class="{'bookmarks_filter--active': search.object_type === 'all'}"
                    style="cursor: pointer"
                    @click="filterBookmark('all')"
                >
                    All
                </span>
                <span
                    class="w-full text-center text-subtitle-2"
                    :class="{'bookmarks_filter--active': search.object_type === 'order'}"
                    style="cursor: pointer"
                    @click="filterBookmark('order')"
                >
                    Orders
                </span>
                <span
                    class="w-full text-center text-subtitle-2"
                    :class="{'bookmarks_filter--active': search.object_type === 'ticket'}"
                    style="cursor: pointer"
                    @click="filterBookmark('ticket')"
                >
                    Tickets
                </span>
            </div>
            <!-- List -->
            <div
                v-show="getterBookmarks.length"
                class="bookmarks_list"
                @scroll="test"
                @click.stop
            >
                <div
                    v-for="(bookmark, index) in getterBookmarks"
                    :key="bookmark.bookmarkid"
                    class="my-3"
                >
                    <div>
                        <div class="d-flex">
                            <v-avatar
                                v-if="bookmark.referenceid.slice(0, 2) === 'TC' || bookmark.referenceid.slice(0, 2) === 'TW'"
                                class="mr-2"
                                color="rgba(39, 217, 31, .2)"
                            >
                                <span
                                    alt=""
                                    class="bookmark-icon bookmark-icon--ticket"
                                />
                            </v-avatar>
                            <v-avatar
                                v-else
                                class="mr-2"
                                color="rgba(39, 217, 31, .2)"
                            >
                                <span
                                    alt=""
                                    class="bookmark-icon bookmark-icon--order"
                                />
                            </v-avatar>
                            <p class="mb-0">
                                <span
                                    class="font-weight-bold"
                                    style="cursor: pointer;"
                                    @click="goToBookmark(bookmark.referenceid)"
                                >{{ bookmark.referenceid }}</span> <br>
                                <span class="grey--text">{{ $date.dateFromNow(bookmark.created_at) }}</span> <br>
                                <span class="grey--text">{{ $date.humanizeDate(bookmark.created_at) }}</span>
                            </p>
                            <span class="ml-auto">
                                <v-icon
                                    width="10px"
                                    @click="deleteBookmark(bookmark.id)"
                                >
                                    mdi-close
                                </v-icon>
                            </span>
                        </div>
                        <div class="d-flex px-2 py-2 bookmark_details">
                            {{ bookmark.details }}
                        </div>

                        <v-divider
                            v-if="index !== getterBookmarks.length - 1"
                            class="my-2"
                        />
                    </div>
                </div>
                <div
                    v-if="getterLastPage > search.page || loading"
                    class="d-flex justify-center"
                >
                    <v-progress-circular
                        ref="loader"
                        :size="50"
                        :width="4"
                        color="primary"
                        indeterminate
                    />
                </div>
            </div>
            <!-- Empty -->
            <div
                v-if="getterBookmarks.length === 0"
                class="d-flex flex-column align-center w-full"
            >
                <div class="mb-3">
                    <v-icon
                        size="50"
                        color="#82b1ff"
                    >
                        mdi-star-outline
                    </v-icon>
                </div>
                <p class="text-subtitle-2 text-center">
                    The favorites are empty. You can add an order or ticket to your favorites.
                </p>
            </div>
        </v-list>
    </v-menu>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'

import {
    ACTION_DELETE_BOOKMARK,
    ACTION_GET_BOOKMARKS,
    ACTION_GET_BOOKMARKS_LOADMORE
} from '@/store/modules/bookmarks/action-types';

const {
    mapActions: mapBookmarkActions
} = createNamespacedHelpers('bookmarks');
export default {
    data() {
        return {
            filter: 'all',
            loading: true,
            search: {
                per_page: 15,
                page: 1,
                object_type: 'all'
            }
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterIsAuth',
            'getterMainInfo'
        ]),
        ...mapGetters('bookmarks', [
            'getterBookmarks',
            'getterTotal',
            'getterLastPage'
        ])
    },
    watch: {
        'getterMainInfo.id': async function (val) {
            if (val) {
                await this[ACTION_GET_BOOKMARKS](this.search)
            }
        }
    },
    async created() {
        if (this.getterMainInfo.id) {
            await this[ACTION_GET_BOOKMARKS](this.search)
            this.loading = false
        }
    },
    methods: {
        ...mapBookmarkActions({
            ACTION_GET_BOOKMARKS,
            ACTION_DELETE_BOOKMARK,
            ACTION_GET_BOOKMARKS_LOADMORE
        }),
        async filterBookmark(payload) {
            this.search.page = 1
            this.search.object_type = payload
            await this[ACTION_GET_BOOKMARKS](this.search)
        },
        goToBookmark(id) {
            if (id.slice(0, 2) === 'TC') {
                this.$router.push({
                    name: 'support-details',
                    params: {
                        id,
                        type: 'client'
                    }
                })
            }
            if (id.slice(0, 2) === 'TW') {
                this.$router.push({
                    name: 'support-details',
                    params: {
                        id,
                        type: 'writer'
                    }
                })
            }
            if (id.slice(0, 1) === 'S') {
                this.$router.push({
                    name: 'order-details',
                    params: {
                        id
                    }
                })
            }
        },
        async deleteBookmark(id) {
            try {
                await this[ACTION_DELETE_BOOKMARK]({ id })
                eventBus.$emit('showSnackBar', 'Success', 'success')
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async loadmore() {
            this.search.page += 1
            this.loading = true
            await this[ACTION_GET_BOOKMARKS_LOADMORE](this.search)
            this.loading = false
        },
        async test() {
            const el = document.querySelector('.bookmarks_list')
            if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
                if (this.getterLastPage > this.search.page && !this.loading) {
                    await this.loadmore();
                }
            }
        }
    }
}
</script>

<style lang="scss">
    .header_avatar{
        position: relative;
    }
    .bookmarks{
        z-index: 99;
        max-height: 400px;
        &_list{
            background: #fff;
            overflow-y: scroll;
            overflow-x: hidden;
            max-height: 400px;
        }
        &_filter{
            padding-bottom: 10px;
            border-bottom: 1px solid #D3D9E2;

            span {
                position: relative;
            }
            &--active{
                position: relative;
                color: #284067;
                &::after{
                    content: '';
                    position: absolute;
                    right: 0;
                    left: 0;
                    bottom: -12px;
                    height: 3px;
                    background: #284067;
                }
            }
        }
    }
    .bookmark-icon{
        width: 25px;
        display: block;
        height: 25px;
        &--ticket {
            background: url('~@/assets/images/svg/001-chat.svg');
            background-size: cover;
        }
        &--order {
            background: url('~@/assets/images/svg/001-supermarket.svg');
            background-size: cover;
        }
    }
</style>
