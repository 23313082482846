// import { SchemaAdmin } from '@/models/SchemaAdmin.js'

export const initialState = {
    payrollList: [],
    currentPage: 0,
    lastPage: 0,
    total: 0
}

export const state = { ...initialState }
