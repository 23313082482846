import '@styles/index.scss'

import Vue from 'vue'
import Vuelidate from 'vuelidate'
import Vuex from 'vuex'
import { VueMaskDirective } from 'v-mask'
import VueBreadcrumbs from 'vue-2-breadcrumbs';
import DatetimePicker from 'vuetify-datetime-picker'
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import VueMeta from 'vue-meta'
import Api from '@/helpers/api/index.js'
import Date from '@/plugins/date'
import Socket from '@/plugins/socket'
import App from './App'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.use(VueMeta)
Vue.config.productionTip = false

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        Vue,
        dsn: 'https://f071ddb71cd742c6afd921bbee81884b@o158244.ingest.sentry.io/6143448',
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: [process.env.VUE_APP_SENTRY_DOMAIN, /^\//]
            })
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0
    });
}

Vue.use(VueBreadcrumbs);

Vue.use(DatetimePicker)
Vue.use(Vuelidate)
Vue.use(Date)
Vue.use(Socket)
Vue.use(Vuex)
Vue.directive('mask', VueMaskDirective);

new Vue({
    vuetify,
    Api,
    router,
    store,
    render: (h) => h(App)
}).$mount('#app')
