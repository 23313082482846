import { updateField } from 'vuex-map-fields';

import {
    SET_CLIENTS_CREDITS_LIST,
    SET_CLIENTS_CREDITS_LIST_LOADING,
    SET_CLIENTS_CREDITS_LIST_PAGES,
    SET_CLIENTS_CREDITS_TOTAL,
    SET_CLIENTS_CREDITS_TOTALS_LOADING,
    SET_WRITERS_CREDITS_LIST,
    SET_WRITERS_CREDITS_LIST_LOADING,
    SET_WRITERS_CREDITS_LIST_PAGES,
    SET_WRITERS_CREDITS_TOTAL,
    SET_WRITERS_CREDITS_TOTALS_LOADING
} from './mutation-types'

export const mutations = {
    updateField,
    /* clients */
    [SET_CLIENTS_CREDITS_LIST](state, payload) {
        state.clientsCreditsList = payload.data
    },
    [SET_CLIENTS_CREDITS_LIST_LOADING](state, payload) {
        state.clientsCreditsListLoading = payload
    },
    [SET_CLIENTS_CREDITS_LIST_PAGES](state, payload) {
        state.clientsLastPage = payload.last_page === false ? state.lastPage : payload.last_page
        state.clientsTotal = payload.total === false ? state.total : payload.total
    },
    [SET_CLIENTS_CREDITS_TOTAL](state, payload) {
        state.clientsTotalCredit = payload.total_credit
        state.clientsTotalDebit = payload.total_debit
    },
    [SET_CLIENTS_CREDITS_TOTALS_LOADING](state, payload) {
        state.clientsCreditsTotalsLoading = payload
    },
    /* clients */

    /* writers */
    [SET_WRITERS_CREDITS_LIST](state, payload) {
        state.writersCreditsList = payload.data
    },
    [SET_WRITERS_CREDITS_LIST_LOADING](state, payload) {
        state.writersCreditsListLoading = payload
    },
    [SET_WRITERS_CREDITS_LIST_PAGES](state, payload) {
        state.writersLastPage = payload.last_page === false ? state.lastPage : payload.last_page
        state.writersTotalDebit = payload.total === false ? state.total : payload.total
    },
    [SET_WRITERS_CREDITS_TOTAL](state, payload) {
        state.writersTotalCredit = payload.total_credit
        state.totalDebit = payload.total_debit
    },
    [SET_WRITERS_CREDITS_TOTALS_LOADING](state, payload) {
        state.writersCreditsTotalsLoading = payload
    }
    /* writers */
}
