import { getField } from 'vuex-map-fields'

export const getters = {
    getField,
    getterMainInfo(state) {
        return {
            firstname: state.data.firstname,
            lastname: state.data.lastname,
            avatar_url: state.data.avatar_url,
            avatar_fallback: state.data.avatar_fallback,
            id: state.data.id,
            email: state.data.email,
            phone_code: state.data.phone_code,
            phone: state.data.phone
        }
    },
    getterAdminId(state) {
        return state.data.id
    },
    getterIsAuth(state) {
        return state.auth
    },
    getterTwoFa(state) {
        return state.data.twofa
    }
}
