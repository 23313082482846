import { updateField } from 'vuex-map-fields';

import {
    SET_DOMAINS
} from './mutation-types'

export const mutations = {
    updateField,
    [SET_DOMAINS](state, payload) {
        state.domains = payload.filter((item) => item.value !== 'all')
    }
}
