import { getField } from 'vuex-map-fields'

export const getters = {
    getField,
    getterWritersList(state) {
        return state.writersList
    },
    getterLastPage(state) {
        return state.lastPage
    },
    getterTotal(state) {
        return state.total
    },
    getterCurrentPage(state) {
        return state.currentPage
    },
    getterWriterFeedbacks(state) {
        return state.feedbacks
    },
    getterWriterNotes(state) {
        return state.noteList
    },
    getterPresets(state) {
        return state.presets
    },
    getterWriter(state) {
        return state.writer
    },
    getterApplicantPresets(state) {
        return state.applicant_presets
    },
    getterNotes(state) {
        return state.noteList
    },
    getterWritersShortList(state) {
        return state.writersShortList
    },
    getterSummary(state) {
        return state.summary
    },
    getterTotalsLoading(state) {
        return state.totalsLoading
    },
    getterListLoading(state) {
        return state.writersListLoading
    },
    getterPayoutsListLoading(state) {
        return state.payoutsListLoading
    },
    getterPayoutsList(state) {
        return state.payouts
    },
    getterPayoutsTotal(state) {
        return state.payoutsTotal
    },
    getterPayoutsLastPage(state) {
        return state.payoutsLastPage
    },
    getterPayoutsCurrentPage(state) {
        return state.payoutsCurrentPage
    },
    getterPayoutsSummary(state) {
        return state.payoutsSummary
    },
    getterPayoutsTotalsLoading(state) {
        return state.payoutsTotalsLoading
    },
    getterPayoutsPresets(state) {
        return state.payoutPresets
    },
    getterPayoutsPresetsLoading(state) {
        return state.payoutPresetsLoading
    }
}
