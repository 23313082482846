import { updateField } from 'vuex-map-fields';
import { cloneDeep } from 'lodash'
import { initialState } from './state'
import {
    SET_LOGIN_SESSION_LIST,
    SET_LOGIN_SESSION_PRESETS,
    SET_LOGIN_SESSION_TOTALS,
    CLEAR_STORE
} from './mutation-types'

export const mutations = {
    updateField,
    [CLEAR_STORE](state) {
        Object.assign(state, cloneDeep(initialState))
    },
    [SET_LOGIN_SESSION_LIST](state, payload) {
        state.loginSessionList = payload.data
        state.currentPage = payload.from
    },
    [SET_LOGIN_SESSION_TOTALS](state, payload) {
        state.lastPage = payload.last_page
        state.total = payload.count
    },
    [SET_LOGIN_SESSION_PRESETS](state, payload) {
        state.presets = payload.data
    }
}
