<template>
    <v-avatar
        :color="isAccent ? colors.backgroundAccent : colors.background"
        :size="avatarSize"
    >
        <div
            v-if="getAvatarSrc"
            class="avatar-img"
        >
            <img
                :src="getAvatarSrc"
                alt="avatar"
                class="avatar-user"
            >
        </div>
        <span
            v-else-if="getAccessStatus !== 'LEAD'"
            :class="`${colors.text} font-weight-bold`"
        >
            <span v-if="getInitials">
                {{ getInitials }}
            </span>
            <v-icon v-else>
                mdi-account-question-outline
            </v-icon>
        </span>
        <span
            v-else
            :class="`${colors.text} font-weight-bold`"
        >
            LE
        </span>
    </v-avatar>
</template>
<script>
export default {
    props: {
        avatarFallback: {
            type: String,
            required: false
        },
        avatarUrl: {
            type: String,
            required: false
        },
        accessStatus: {
            type: String,
            required: true
        },
        firstname: {
            type: String,
            required: false
        },
        lastname: {
            type: String,
            required: false
        },
        avatarSize: {
            type: String,
            required: false,
            default: '43'
        },
        isAccent: {
            type: Boolean
        }
    },
    computed: {
        getAccessStatus() {
            return this.accessStatus.toUpperCase()
        },
        getInitials() {
            let str
            if (this.firstname && this.lastname) {
                str = `${this.firstname?.toUpperCase().charAt(0)}${this.lastname?.toUpperCase().charAt(0)}`
            } else {
                str = false
            }
            return str
        },
        getAvatarSrc() {
            if (this.avatarUrl && this.avatarUrl.includes('https://')) {
                return this.avatarUrl
            }
            if (this.avatarFallback) {
                return `/img/avatars/${this.avatarFallback}`
            }
            return false
        },
        colors() {
            switch (this.getAccessStatus) {
            case 'ADMIN':
                return {
                    background: 'rgba(25, 118, 210, 0.1)',
                    backgroundAccent: 'rgba(25, 118, 210, 1)',
                    text: this.isAccent ? 'text--admin' : 'primary--text'
                }
            case 'WRITER':
                return {
                    background: 'rgba(240, 125, 0, 0.1)',
                    backgroundAccent: 'rgba(240, 125, 0, 1)',
                    text: this.isAccent ? 'text--writer' : 'accent--text'
                }
            case 'CLIENT':
                return {
                    background: 'rgba(39, 174, 96, 0.1)',
                    backgroundAccent: 'rgba(39, 174, 96, 1)',
                    text: this.isAccent ? 'text--client' : 'success--text'
                }
            case 'SUPERADMIN':
                return {
                    background: 'rgba(155, 81, 224, 0.1)',
                    backgroundAccent: 'rgba(155, 81, 224, 1)',
                    text: this.isAccent ? 'text--superadmin' : 'purple--text'
                }
            case 'LEAD':
                return {
                    background: 'rgba(0, 255, 255, 0.15)',
                    backgroundAccent: 'rgba(0, 0, 255, 0.15)'
                }
            case 'ERROR':
                return {
                    background: 'rgb(255, 0, 0, 0.15)',
                    backgroundAccent: 'rgb(255, 0, 0, 0.15)',
                    text: 'error--text'
                }
            default:
                return {}
            }
        }
    }
}
</script>
<style lang="scss">
.text{
    &--admin{
        color: #e6f1fc;
        caret-color: #e6f1fc;
    }
    &--writer{
        color: #fef2e8;
        caret-color: #fef2e8;
    }
    &--client{
        color: #e6f7ef;
        caret-color: #e6f7ef;
    }
    &--superadmin{
        color: #f4edfd;
        caret-color: #f4edfd;
    }
}
.avatar-img{
    img {
        max-width: 100%;
    }
}
</style>
