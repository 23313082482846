import { getField } from 'vuex-map-fields'

export const getters = {
    getField,
    getterBookmarks(state) {
        return state.bookmarks
    },
    getterTotal(state) {
        return state.total
    },
    getterLastPage(state) {
        return state.last_page
    }
}
