import Api from '@/helpers/api/index.js'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'

import {
    ACTION_GET_MESSAGE_LOG_LIST,
    ACTION_CLEAR_STORE
} from './action-types'

import {
    SET_LOGIN_MESSAGE_LOG_LIST,
    SET_LOGIN_MESSAGE_LOG_TOTALS,
    CLEAR_STORE
} from './mutation-types'

export const actions = {
    [ACTION_CLEAR_STORE]({ commit }) {
        commit(CLEAR_STORE)
    },
    async [ACTION_GET_MESSAGE_LOG_LIST]({ commit }, payload) {
        let responses = null
        responses = await Promise.all([
            Api.get('/api/messaging-log/list-by-params', removeEmptyParamsFromRequest({ ...payload, mode: 'list' })),
            Api.get('/api/messaging-log/list-by-params', removeEmptyParamsFromRequest({ ...payload, mode: 'count' }))
        ])

        const { data } = responses[0]
        const { data: totals } = responses[1]
        commit(SET_LOGIN_MESSAGE_LOG_LIST, data)
        commit(SET_LOGIN_MESSAGE_LOG_TOTALS, {
            count: totals.count,
            last_page: Math.ceil(totals.count / payload.per_page),
            totalFromCount: totals.total
        })
        return data
    }
}
