import { getField } from 'vuex-map-fields'

export const getters = {
    getField,
    getterPayrollList(state) {
        return state.payrollList
    },
    getterLastPage(state) {
        return state.lastPage
    },
    getterTotal(state) {
        return state.total
    },
    getterCurrentPage(state) {
        return state.currentPage
    }
}
