export default [
    {
        path: '/emails',
        component: () => import(/* webpackChunkName: "orders" */ '@/views/Emails/Emails'),
        meta: {
            requiresAuth: true,
            name: 'Emails',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        },
        name: 'emails'
    },
    {
        path: '/emails/list',
        component: () => import(/* webpackChunkName: "orders" */ '@/views/Emails/EmailsList'),
        meta: {
            requiresAuth: true,
            name: 'Mailing List',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        },
        name: 'emails-list'
    },
    {
        path: '/emails-edit/:id',
        component: () => import(/* webpackChunkName: "orders" */ '@/views/Emails/EmailsEdit'),
        meta: {
            requiresAuth: true,
            name: 'Emails edit',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: []
        },
        name: 'emails-edit'
    }
]
