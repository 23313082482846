import Api from '@/helpers/api/index.js'

import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'

import {
    ACTION_ACTIVATE_PROMO,
    ACTION_CREATE_PROMO,
    ACTION_DEACTIVATE_PROMO,
    ACTION_GET_PROMO_LIST,
    ACTION_GET_PRESETS,
    ACTION_GET_PROMO_LIST_WITH_PRESET,
    ACTION_GET_PROMO_LIST_WITHOUT_PRESET,
    ACTION_GET_PROMO_TOTALS
} from './action-types'

import {
    CREATE_PROMO,
    SET_PROMO_LIST,
    SET_STATUS_PROMO,
    SET_PRESETS,
    SET_PROMO_LIST_LOADING,
    SET_TOTALS_LOADING,
    SET_PROMO_LIST_PAGES,
    SET_PROMO_SUMMARY
} from './mutation-types'

export const actions = {
    // GET ORDER LIST
    async [ACTION_GET_PROMO_LIST]({ commit, dispatch }, payload) {
        commit(SET_PROMO_LIST_LOADING, true)
        let list
        if (payload.preset) {
            list = await dispatch(ACTION_GET_PROMO_LIST_WITH_PRESET, payload)
        } else {
            list = await dispatch(ACTION_GET_PROMO_LIST_WITHOUT_PRESET, payload)
        }
        commit(SET_PROMO_LIST, list)
        commit(SET_PROMO_LIST_LOADING, false)
        return list
    },
    // GET TOTALS
    async [ACTION_GET_PROMO_TOTALS]({ commit }, payload) {
        commit(SET_TOTALS_LOADING, true)
        let totals
        const payload2 = removeEmptyParamsFromRequest({ ...payload })
        if (payload2?.preset) {
            totals = await Api.get('/api/discount/list-by-preset', { ...payload2 }, {}, false)
        } else {
            totals = await Api.get('/api/discount/list-by-params', { ...payload2 }, {}, false)
        }
        commit(SET_PROMO_SUMMARY, totals.data)
        commit(SET_PROMO_LIST_PAGES, {
            last_page: Math.ceil(totals.data.count / payload.per_page),
            total: totals.data.count
        })

        commit(SET_TOTALS_LOADING, false)
    },
    async [ACTION_CREATE_PROMO]({ commit }, payload) {
        const { data } = await Api.post('/api/discount/create', payload);
        commit(CREATE_PROMO, data)
    },
    async [ACTION_ACTIVATE_PROMO]({ commit }, payload) {
        await Api.put('/api/discount/set-status-active', payload);
        commit(SET_STATUS_PROMO, { ...payload, new: 1 })
    },
    async [ACTION_DEACTIVATE_PROMO]({ commit }, payload) {
        await Api.put('/api/discount/set-status-inactive', payload);
        commit(SET_STATUS_PROMO, { ...payload, new: 0 })
    },
    // GET PROMO WITHOUT PRESET
    async [ACTION_GET_PROMO_LIST_WITHOUT_PRESET](_, payload) {
        const { data } = await Api.get('/api/discount/list-by-params', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        return data
    },
    // GET PROMO WITH PRESET
    async [ACTION_GET_PROMO_LIST_WITH_PRESET](_, payload) {
        const { data } = await Api.get('/api/discount/list-by-preset', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        return data
    },
    async [ACTION_GET_PRESETS]({ commit }) {
        const presets = await Api.get('/api/discount/list-presets')
        const tmp = presets.data.map((item) => ({
            ...item,
            preset: item.value,
            count: item.counters?.count || 0
        }))
        commit(SET_PRESETS, {
            data: tmp
        })
    }
}
