import Api from '@/helpers/api/index.js'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'

import {
    ACTION_GET_PAYROLL_LIST
} from './action-types'

import {
    SET_PAYROLL_LIST
} from './mutation-types'

export const actions = {
    async [ACTION_GET_PAYROLL_LIST]({ commit }, payload) {
        const { data } = await Api.get('/api/payroll/list', removeEmptyParamsFromRequest(payload))
        commit(SET_PAYROLL_LIST, data)
    }
}
