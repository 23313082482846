import { updateField } from 'vuex-map-fields';
import { findAndReplaceInArray } from '@/helpers/utils/index.js'
import {
    CREATE_PERMISSION,
    DELETE_PERMISSIONS,
    SET_PERMISSIONS_LIST,
    UPDATE_PERMISSION
} from './mutation-types'

export const mutations = {
    updateField,
    [SET_PERMISSIONS_LIST](state, payload) {
        state.permissionList = payload.data
        state.currentPage = payload.current_page
        state.lastPage = payload.last_page
        state.total = payload.total
    },
    [DELETE_PERMISSIONS](state, payload) {
        payload.forEach((id) => {
            state.permissionList = state.permissionList.filter((permission) => permission.id !== id)
        });
    },
    [UPDATE_PERMISSION](state, payload) {
        findAndReplaceInArray(state.permissionList, payload.id, 'id', payload)
    },
    [CREATE_PERMISSION](state, payload) {
        state.total += 1
        state.permissionList.unshift(payload)
    }
}
