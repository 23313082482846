import { cloneDeep } from 'lodash'
import { updateField } from 'vuex-map-fields';
import { findAndReplaceInArray } from '@/helpers/utils/index.js'
import {
    ASSIGN_BID,
    CLEAR_TIMER,
    DELETE_BID,
    DELETE_NOTE,
    DELETE_NOTE_COUNTER,
    EDIT_NOTE,
    ORDER_DROP,
    POST_NOTE,
    REVISION_ORDER,
    SET_DEADLINE,
    SET_FILES_LIST,
    SET_NOTES,
    SET_ORDERS_COMPLETED_LIST,
    SET_ORDER_LIST,
    SET_ORDER_LIST_LIVE,
    SET_ORDER_LIST_LOADING,
    SET_ORDER_LIST_PAGES,
    SET_ORDER_SUMMARY,
    SET_PAYLOAD,
    SET_PRESETS,
    SET_PRESETS_LOADING,
    SET_SW_TOTAL,
    SET_TIMER,
    SET_TOTALS_LOADING,
    SET_WRITER,
    UPDATE_FILES_LIST,
    CLEAR_ORDER_LIST,
    SET_ORDER_STATUS
} from './mutation-types'

import { initialState } from './state'

export const mutations = {
    updateField,
    [CLEAR_ORDER_LIST](state) {
        Object.assign(state, cloneDeep(initialState))
    },
    [SET_ORDER_LIST_PAGES](state, payload) {
        state.lastPage = payload.last_page === false ? state.lastPage : payload.last_page
        state.total = payload.total === false ? state.total : payload.total
    },
    [SET_ORDER_LIST_LIVE](state, payload) {
        state.orderList = payload.data.map((element) => {
            const find = state.orderList.find((item) => item.orderid === element.orderid)
            const isDiff = JSON.stringify({ ...find }) !== JSON.stringify({ ...element })
            if (find && isDiff) {
                element = {
                    ...element,
                    isUpdated: true
                }
            }
            if (!find) {
                element = {
                    ...element,
                    isUpdated: true,
                    isNew: true
                }
            }
            return element
        });
    },
    [SET_ORDER_LIST](state, payload) {
        state.orderList = payload.data
        state.currentPage = payload.current_page
        state.total = payload.total
    },
    [SET_ORDER_LIST_LOADING](state, payload) {
        state.orderListLoading = payload
    },
    [SET_PRESETS](state, payload) {
        state.preset = payload.data
    },
    [SET_PRESETS_LOADING](state, payload) {
        state.presetLoading = payload
    },
    [SET_TOTALS_LOADING](state, payload) {
        state.totalsLoading = payload
    },
    [SET_PAYLOAD](state, payload) {
        state.requestPayload = payload
    },
    [SET_FILES_LIST](state, payload) {
        state.files = payload
    },
    [UPDATE_FILES_LIST](state, payload) {
        const newFiles = payload.map((file) => ({
            ...file,
            owner_type: 'writer'
        }))
        newFiles.forEach((file) => {
            state.files.push(file)
        })
    },
    [SET_ORDER_SUMMARY](state, payload) {
        state.summary = {
            total: payload.total_sum,
            total_writer: payload.total_sw_sum,
            orders: payload.total
        }
    },
    [SET_WRITER](state, payload) {
        findAndReplaceInArray(state.orderList, payload.orderid, 'orderid', payload)
    },
    [ORDER_DROP](state, payload) {
        findAndReplaceInArray(state.orderList, payload.orderid, 'orderid', payload)
    },
    [SET_SW_TOTAL](state, payload) {
        findAndReplaceInArray(state.orderList, payload.orderid, 'orderid', { sw_total: payload.cost.toFixed(2) })
    },
    [SET_DEADLINE](state, payload) {
        findAndReplaceInArray(state.orderList, payload.orderid, 'orderid', payload)
    },

    [ASSIGN_BID](state, payload) {
        findAndReplaceInArray(state.orderList, payload.orderid, 'orderid', {
            status: 'PENDING',
            sw_status: 'PENDING',
            writer_firstname: payload.firstname,
            writer_lastname: payload.lastname,
            writer_field_id: payload.owner,
            writer_email: '',
            writer_avatar: payload.avatar,
            sw_id: payload.owner
        })
    },

    [DELETE_BID](state, payload) {
        const find = state.orderList.findIndex((item) => item.orderid === payload.order_id)
        state.orderList.splice(find, 1, {
            ...state.orderList[find],
            counters: {
                ...state.orderList[find].counters,
                bids_count: state.orderList[find].counters.bids_count - 1
            }
        })
    },

    [REVISION_ORDER](state, payload) {
        findAndReplaceInArray(state.orderList, payload.orderid, 'orderid', {
            status: 'REVISION',
            sw_status: 'REVISION'
        })
    },
    [SET_TIMER](state, payload) {
        state.timerID = payload
    },
    [CLEAR_TIMER](state) {
        clearTimeout(state.timerID);
    },

    [SET_NOTES](state, payload) {
        state.notes = payload
    },

    [POST_NOTE](state, payload) {
        state.notes.push(payload)
        const index = state.orderList.findIndex((item) => item.orderid === payload.orderid)
        state.orderList.splice(index, 1, {
            ...state.orderList[index],
            counters: {
                ...state.orderList[index]?.counters,
                notes_count: state.orderList[index]?.counters.notes_count + 1
            }
        })
    },

    [DELETE_NOTE](state, payload) {
        const index = state.notes.findIndex((note) => note.id === payload)
        state.notes.splice(index, 1)
    },

    [EDIT_NOTE](state, payload) {
        findAndReplaceInArray(state.notes, payload.id, 'id', { details: payload.details })
    },
    [DELETE_NOTE_COUNTER](state, payload) {
        const find = state.orderList.findIndex((item) => item.orderid === payload)
        state.orderList.splice(find, 1, {
            ...state.orderList[find],
            note_count: state.orderList[find].note_count - 1
        })
    },
    [SET_ORDERS_COMPLETED_LIST](state, payload) {
        state.ordersCompletedList = payload
    },
    [SET_ORDER_STATUS](state, payload) {
        findAndReplaceInArray(state.orderList, payload.orderid, 'orderid', payload)
    }
}
