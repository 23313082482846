export const ACTION_LOGIN = 'ACTION_LOGIN'
export const ACTION_LOGOUT = 'ACTION_LOGOUT'
export const ACTION_REGISTER = 'ACTION_REGISTER'
export const ACTION_RESET_PASSWORD = 'ACTION_RESET_PASSWORD'
export const ACTION_REFRESH_TOKEN = 'ACTION_REFRESH_TOKEN'
export const ACTION_UPDATE_SELF = 'ACTION_UPDATE_SELF'

export const ACTION_UPLOAD_AVATAR = 'ACTION_UPLOAD_AVATAR'
export const ACTION_UPDATE_CLIENT_DATA = 'ACTION_UPDATE_CLIENT_DATA'

export const ACTION_ADMIN_TOUCH = 'ACTION_ADMIN_TOUCH'
