export const SET_CLIENT_LIST = 'SET_SUPPORT_LIST'

export const UPDATE_CLIENT = 'UPDATE_CLIENT'

export const SET_PRESETS = 'SET_PRESETS'

export const UPDATE_CLIENT_BALANCE = 'UPDATE_CLIENT_BALANCE'

export const SET_CLIENT_LIST_PAGES = 'SET_CLIENT_LIST_PAGES'

export const SET_CLIENTS_SUMMARY = 'SET_CLIENTS_SUMMARY'

export const SET_TOTALS_LOADING = 'SET_PRESETS_LOADING'

export const SET_CLIENTS_LIST_LOADING = 'SET_CLIENTS_LIST_LOADING'

export const CLEAR_CLIENT_LIST = 'CLEAR_CLIENT_LIST'
