export const ACTION_GET_PROMO_LIST = 'ACTION_GET_PROMO_LIST'

export const ACTION_CREATE_PROMO = 'ACTION_CREATE_PROMO'

export const ACTION_ACTIVATE_PROMO = 'ACTION_ACTIVATE_PROMO'

export const ACTION_DEACTIVATE_PROMO = 'ACTION_DEACTIVATE_PROMO'

export const ACTION_GET_PROMO_LIST_WITH_PRESET = 'ACTION_GET_PROMO_LIST_WITH_PRESET'

export const ACTION_GET_PROMO_LIST_WITHOUT_PRESET = 'ACTION_GET_PROMO_LIST_WITHOUT_PRESET'

export const ACTION_GET_PRESETS = 'ACTION_GET_PRESETS'

export const ACTION_GET_PROMO_TOTALS = 'ACTION_GET_PROMO_TOTALS'
