import { updateField } from 'vuex-map-fields';
import { cloneDeep } from 'lodash'
import { initialState } from './state'

import {
    RESET_FORM,
    SET_FORM_FROM_PAYLOAD
} from './mutation-types'

export const mutations = {
    updateField,
    [RESET_FORM](state) {
        Object.assign(state, cloneDeep(initialState))
    },
    [SET_FORM_FROM_PAYLOAD](state, payload) {
        // clear state
        Object.assign(state, cloneDeep(initialState))
        // set state
        this.currentStep = 'address_book'
        this.activeNextBtn = false
        this.activeSaveBtn = false

        state.form_data = {
            mail_template: payload.mail_template,
            mail_start_at: payload.mail_start_at,
            mail_subject: payload.mail_subject,
            'header-01': payload.mail_template_data['header-01'],
            'section-01': payload.mail_template_data['section-01'],
            button: {
                text: payload.mail_template_data.button.text,
                url: payload.mail_template_data.button.url
            },
            subcopy: payload.mail_template_data.subcopy,
            search: {
                domain: payload.domain,
                from: payload.from,
                to: payload.to
            }
        }
    }
}
