export const ACTION_ORDER_LIST_LIVE_UPDATE = 'ACTION_ORDER_LIST_LIVE_UPDATE'

export const ACTION_GET_ORDER_LIST = 'ACTION_GET_ORDER_LIST'

export const ACTION_GET_ORDER_LIST_WITH_PRESET = 'ACTION_GET_ORDER_LIST_WITH_PRESET'

export const ACTION_GET_ORDER_LIST_WITHOUT_PRESET = 'ACTION_GET_ORDER_LIST_WITHOUT_PRESET'

export const ACTION_GET_ORDER_PRESETS = 'ACTION_GET_ORDER_PRESETS'

export const ACTION_GET_ORDER_TOTALS = 'ACTION_GET_ORDER_TOTALS'

export const ACTION_GET_ORDER = 'ACTION_GET_ORDER'

export const ACTION_GET_ORDER_FILES = 'ACTION_GET_ORDER_FILES'

export const ACTION_DELETE_ORDER_FILE = 'ACTION_DELETE_ORDER_FILE'

export const ACTION_UPLOAD_ORDER_FILE = 'ACTION_UPLOAD_ORDER_FILE'

export const ACTION_ASIGN_WRITER = 'ACTION_ASIGN_WRITER'

export const ACTION_ORDER_FEE = 'ACTION_ORDER_FEE'

export const ACTION_ORDER_DEADLINE = 'ACTION_ORDER_DEADLINE'

export const ACTION_ORDER_DROP = 'ACTION_ORDER_DROP'

export const ACTION_ORDER_REVISION = 'ACTION_ORDER_REVISION'

export const ACTION_GET_NOTES = 'ACTION_GET_NOTES'

export const ACTION_CREATE_NOTE = 'ACTION_CREATE_NOTE'

export const ACTION_DELETE_NOTE = 'ACTION_DELETE_NOTE'

export const ACTION_EDIT_NOTE = 'ACTION_EDIT_NOTE'

export const ACTION_GET_WRITERS_LIST_COMPLETED = 'ACTION_GET_WRITERS_LIST_COMPLETED'

export const ACTION_NOTIFY_COMPLETE_ORDER = 'ACTION_NOTIFY_COMPLETE_ORDER'

export const ACTION_NOTIFY_UNPAID_ORDER = 'ACTION_NOTIFY_UNPAID_ORDER'

export const ACTION_ORDER_CHANGE_STATUS = 'ACTION_ORDER_CHANGE_STATUS'
