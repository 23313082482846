import Api from '@/helpers/api/index.js'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'

import {
    ACTION_CLIENT_TRANSFER_TICKET,
    ACTION_CLOSE_TICKET,
    ACTION_CLOSE_TICKETS_MASS,
    ACTION_CREATE_TICKET,
    ACTION_GET_CLIENT_PRESETS,
    ACTION_GET_SUPPORT_LIST,
    ACTION_SEND_MESSAGE,
    ACTION_START_LIVE_UPDATE,
    ACTION_STOP_LIVE_UPDATE,
    ACTION_UPDATE_TICKET,
    ACTION_BIND_ORDER_TO_TICKET
} from './action-types'

import {
    CLEAR_TIMER,
    CLOSE_TICKET,
    CLOSE_TICKET_MASS,
    SET_CLIENT_PRESETS,
    SET_PAYLOAD,
    SET_SUPPORT_LIST,
    SET_SUPPORT_LIST_LIVE,
    SET_TIMER,
    UPDATE_SUPPORT_LIST,
    UPDATE_TICKET,
    SET_SUPPORT_CHAT_MESSAGE,
    SET_MESSAGE_SENDING_LOADING,
    SET_SUPPORT_TOTALS,
    SET_SUPPORT_LIST_LOADING,
    SET_PRESETS_LOADING
} from './mutation-types'

export const actions_client = {
    // GET SUPPORT LIST
    async [ACTION_GET_SUPPORT_LIST]({ commit }, payload) {
        commit(SET_SUPPORT_LIST_LOADING, true)
        let responses = null
        if (payload.preset) {
            responses = await Promise.all([
                Api.get('/api/support/client/ticket/list-by-preset', removeEmptyParamsFromRequest({ ...payload, mode: 'list' })),
                Api.get('/api/support/client/ticket/list-by-preset', removeEmptyParamsFromRequest({ ...payload, mode: 'count' }))
            ])
        } else {
            responses = await Promise.all([
                Api.get('/api/support/client/ticket/list-by-params', removeEmptyParamsFromRequest({ ...payload, mode: 'list' })),
                Api.get('/api/support/client/ticket/list-by-params', removeEmptyParamsFromRequest({ ...payload, mode: 'count' }))
            ])
        }

        const { data } = responses[0]
        const { data: totals } = responses[1]
        commit(SET_SUPPORT_TOTALS, {
            count: totals.count,
            last_page: Math.ceil(totals.count / payload.per_page)
        })
        commit(SET_PAYLOAD, removeEmptyParamsFromRequest({ ...payload }))
        commit(SET_SUPPORT_LIST, data)
        commit(SET_SUPPORT_LIST_LOADING, false)
    },
    async [ACTION_START_LIVE_UPDATE]({ commit, getters, dispatch }) {
        let data = null
        if (getters.getterRequestPayload.preset) {
            const response = await Api.get('/api/support/client/ticket/list-by-preset', { ...getters.getterRequestPayload, mode: 'list' }, {}, false)
            data = response.data
        } else {
            const response = await Api.get('/api/support/client/ticket/list-by-params', { ...getters.getterRequestPayload, mode: 'list' }, {}, false)
            data = response.data
        }

        commit(SET_SUPPORT_LIST_LIVE, data)
        const timerid = setTimeout(() => {
            dispatch(ACTION_START_LIVE_UPDATE)
        }, 1000 * 60);
        commit(SET_TIMER, timerid)
    },
    [ACTION_STOP_LIVE_UPDATE]({ commit }) {
        commit(CLEAR_TIMER)
    },
    // SEND MESSAGE
    async [ACTION_SEND_MESSAGE]({ commit }, { payload, item }) {
        const params = {
            details: payload.details,
            ticketid: item.ticketid
        }
        commit(SET_MESSAGE_SENDING_LOADING, true)
        const { data } = await Api.post('/api/support/client/message/create', params)
        commit(SET_SUPPORT_CHAT_MESSAGE, { ...data, ticketid: item.ticketid })
        commit(SET_MESSAGE_SENDING_LOADING, false)
        return data
    },
    // update ticket
    async [ACTION_UPDATE_TICKET]({ commit }, payload) {
        const { data } = await Api.put('/api/support/tickets/client/update', payload)
        commit(UPDATE_TICKET, payload)
        return data
    },
    // close ticket
    async [ACTION_CLOSE_TICKET]({ commit }, payload) {
        await Api.put('/api/support/client/ticket/close', {
            ticketid: payload.id
        })
        commit(CLOSE_TICKET, { id: payload.id })
    },

    async [ACTION_CLOSE_TICKETS_MASS]({ commit }, payload) {
        await Api.put('/api/support/client/ticket/close', {
            tickets: [...payload]
        })
        commit(CLOSE_TICKET_MASS, payload)
    },

    // Create ticket
    async [ACTION_CREATE_TICKET]({ commit }, payload) {
        const { data } = await Api.post('/api/support/client/ticket/create-for-client', removeEmptyParamsFromRequest(payload))
        commit(UPDATE_SUPPORT_LIST, data)
    },

    // Get client ticketa presets
    async [ACTION_GET_CLIENT_PRESETS]({ commit }) {
        commit(SET_PRESETS_LOADING, true)
        const { data } = await Api.get('/api/support/client/list-presets')
        const tmp = data.map((item) => ({
            ...item,
            preset: item.value,
            count: item.counters?.count || 0
        }))
        commit(SET_CLIENT_PRESETS, tmp)
        commit(SET_PRESETS_LOADING, false)
    },

    // Transfer client ticket

    async [ACTION_CLIENT_TRANSFER_TICKET](ctx, payload) {
        await Api.post('/api/support/tickets/client/transfer', payload)
    },

    async [ACTION_BIND_ORDER_TO_TICKET](ctx, payload) {
        await Api.put('/api/support/client/ticket/link-to-order', payload)
    }
}
