<template>
    <div class="d-flex">
        <v-form
            class="expanding-search__form"
            @submit.prevent="getPath"
        >
            <v-text-field
                class="expanding-search"
                :class="{'closed' : searchClosed}"
                prepend-inner-icon="mdi-magnify"
                filled
                dense
                @focus="searchClosed = false"
                @blur="searchClosed = true"
                @input="val => search = val"
            />
        </v-form>
    </div>
</template>
<script>
export default {
    data() {
        return {
            searchClosed: true,
            search: ''
        }
    },
    computed: {
        locationData() {
            return [
                {
                    prefix: 'S',
                    value: '/orders',
                    params: {
                        search_for: this.search,
                        search_by: 'orderid'
                    }
                },
                {
                    prefix: 'TC',
                    value: '/support/client',
                    params: {
                        search_for: this.search,
                        search_by: 'ticketid'
                    }
                },
                {
                    prefix: 'TW',
                    value: '/support/writer',
                    params: {
                        search_for: this.search,
                        search_by: 'ticketid'
                    }
                },
                {
                    prefix: 'BA',
                    value: '/batch',
                    params: {
                        search_for: this.search,
                        search_by: 'batchid'
                    }
                },
                {
                    prefix: 'writer',
                    value: '/writers',
                    params: {
                        search_for: this.getNumbers(this.search),
                        search_by: 'sw_id'
                    }
                },
                {
                    prefix: 'client',
                    value: '/hr/clients',
                    params: {
                        search_for: this.getNumbers(this.search),
                        search_by: 'id'
                    }
                }
            ]
        }
    },
    methods: {
        getPath() {
            const data = this.locationData.filter((item) => this.search.includes(item.prefix))
            let linkParams = ''
            Object.keys(data[0].params).forEach((item) => {
                linkParams += `${item}=${data[0].params[item]}&`
            })
            window.location.href = `${data[0].value}?${linkParams}`
        },
        getNumbers(str) {
            return str.match(/\d+/g).join('')
        }
    }
}
</script>
<style lang="scss">
    .expanding-search__form {
        height: 40px;
        align-self: center;
        @media (max-width: 800px) {
            display: none;
        }
    }
    .v-input.expanding-search {
        width: 250px;
        height: 40px;
        border-radius: 10px;
        transition: width 0.3s;
        .v-input__slot {
            &::before, &::after {
                border: none !important;
            }
        }
        &.closed {
            width: 45px;
        }
    }
</style>
