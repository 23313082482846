import Api from '@/helpers/api/index.js'

import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'

import {
    ACTION_CREATE_TICKET_FEEDBACK,
    ACTION_EDIT_FEEDBACK,
    ACTION_GET_FEEDBACKS_LIST,
    ACTION_GET_FETCH_FEEDBACK,
    ACTION_GET_PRESETS_FEEDBACK,
    ACTION_SET_STATUS_FEEDBACK,
    ACTION_GET_FEEDBACKS_LIST_WITHOUT_PRESET,
    ACTION_GET_FEEDBACKS_LIST_WITH_PRESET,
    ACTION_GET_FEEDBACKS_TOTALS
} from './action-types'

import {
    SET_FEEDBACKS_LIST,
    SET_PRESETS_LIST,
    UPDATE_FEEDBACK,
    UPDATE_FEEDBACK_STATUS,
    SET_TOTALS_LOADING,
    SET_FEEDBACKS_SUMMARY,
    SET_FEEDBACKS_LIST_PAGES,
    SET_FEEDBACKS_LIST_LOADING
} from './mutation-types'

export const actions = {
    // GET FEEDBACKS LIST
    async [ACTION_GET_FEEDBACKS_LIST]({ commit, dispatch }, payload) {
        commit(SET_FEEDBACKS_LIST_LOADING, true)
        let list
        if (payload.preset) {
            list = await dispatch(ACTION_GET_FEEDBACKS_LIST_WITH_PRESET, payload)
        } else {
            list = await dispatch(ACTION_GET_FEEDBACKS_LIST_WITHOUT_PRESET, payload)
        }
        commit(SET_FEEDBACKS_LIST, list)
        commit(SET_FEEDBACKS_LIST_LOADING, false)
        await dispatch(ACTION_GET_PRESETS_FEEDBACK, payload)
    },

    // GET FEEDBACKS WITH PRESET
    async [ACTION_GET_FEEDBACKS_LIST_WITH_PRESET](_, payload) {
        const { data } = await Api.get('/api/testimonial/list-by-preset', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        return data
    },

    // GET FEEDBACKS WITHOUT PRESET
    async [ACTION_GET_FEEDBACKS_LIST_WITHOUT_PRESET](_, payload) {
        const { data } = await Api.get('/api/testimonial/list-by-params', removeEmptyParamsFromRequest({ ...payload }), {}, false)
        return data
    },

    // GET TOTALS
    async [ACTION_GET_FEEDBACKS_TOTALS]({ commit }, payload) {
        commit(SET_TOTALS_LOADING, true)
        let totals
        const payload2 = removeEmptyParamsFromRequest({ ...payload })
        if (payload2?.preset) {
            totals = await Api.get('/api/testimonial/list-by-preset', { ...payload2 }, {}, false)
        } else {
            totals = await Api.get('/api/testimonial/list-by-params', { ...payload2 }, {}, false)
        }
        commit(SET_FEEDBACKS_SUMMARY, totals.data)
        commit(SET_FEEDBACKS_LIST_PAGES, {
            last_page: Math.ceil(totals.data.count / payload.per_page),
            total: totals.data.count
        })

        commit(SET_TOTALS_LOADING, false)
    },
    // EDIT FEEDBACK
    async [ACTION_EDIT_FEEDBACK]({ commit }, { payload, id }) {
        const { data } = await Api.put('/api/testimonial/update', { id, data: payload })
        commit(UPDATE_FEEDBACK, { payload, id })
        return data
    },
    // SET STATUS FEEDBACK
    async [ACTION_SET_STATUS_FEEDBACK]({ commit }, { payload, id }) {
        const { data } = await Api.put('/api/testimonials/set-status', { testimonials: id, status: payload.status })
        commit(UPDATE_FEEDBACK_STATUS, { payload, id })
        return data
    },
    // CREATE TICKET FOR CLIENT FEEBBACK
    async [ACTION_CREATE_TICKET_FEEDBACK](ctx, payload) {
        const { data } = await Api.post('/api/support/tickets/client/create', payload)
        return data
    },
    // FETCH FEEDBACK
    async [ACTION_GET_FETCH_FEEDBACK](ctx, payload) {
        const { data } = await Api.get('/api/testimonials/fetch', payload)
        return data
    },
    // GET PRESETS
    async [ACTION_GET_PRESETS_FEEDBACK]({ commit }) {
        const data = await Api.get('/api/testimonial/list-presets')
        const tmp = data.data.map((item) => ({
            ...item,
            preset: item.value,
            count: item.counters?.count || 0
        }))
        commit(SET_PRESETS_LIST, tmp)
    }
}
