export const ACTION_GET_WRITERS_LIST = 'ACTION_GET_WRITERS_LIST'

// Writer

export const ACTION_GET_FETCH_WRITER = 'ACTION_GET_FETCH_WRITER'

export const ACTION_WRITER_ACTIVATE = 'ACTION_WRITER_ACTIVATE'

export const ACTION_WRITER_DEACTIVATE = 'ACTION_WRITER_DEACTIVATE'

export const ACTION_WRITER_POST_NOTE = 'ACTION_WRITER_POST_NOTE'

export const ACTION_WRITER_EDIT = 'ACTION_WRITER_EDIT'

export const ACTION_POST_WRITER_SUPPORT_TICKET = 'ACTION_POST_WRITER_SUPPORT_TICKET'

export const ACTION_SET_STATUS_TRUSTED_TO_TRUSTED = 'ACTION_SET_STATUS_TRUSTED_TO_TRUSTED'

export const ACTION_SET_STATUS_TRUSTED_TO_NEW = 'ACTION_SET_STATUS_TRUSTED_TO_NEW'

export const ACTION_DELETE_WRITER_FILE = 'ACTION_DELETE_WRITER_FILE'

export const ACTION_UPLOAD_WRITER_FILE = 'ACTION_UPLOAD_WRITER_FILE'

export const ACTION_CREATE_TICKET_FEEDBACK = 'ACTION_CREATE_TICKET_FEEDBACK'

export const ACTION_GET_WRITER_NOTE_LIST = 'ACTION_GET_WRITER_NOTE_LIST'

export const ACTION_DELETE_WRITER_NOTE = 'ACTION_DELETE_WRITER_NOTE'

export const ACTION_EDIT_WRITER_NOTE = 'ACTION_EDIT_WRITER_NOTE'

export const ACTION_GET_EXAM_LIST = 'ACTION_GET_EXAM_LIST'

export const ACTION_EDIT_UPDATE = 'ACTION_EDIT_UPDATE'

export const ACTION_CHANGE_AVATAR = 'ACTION_CHANGE_AVATAR'

export const ACTION_GET_PRESETS_WRITER = 'ACTION_GET_PRESETS_WRITER'

export const ACTION_GET_WRITER_SHORT_LIST = 'ACTION_GET_WRITER_SHORT_LIST'

export const ACTION_GET_WRITERS_LIST_WITH_PRESET = 'ACTION_GET_WRITERS_LIST_WITH_PRESET'

export const ACTION_GET_WRITERS_LIST_WITHOUT_PRESET = 'ACTION_GET_WRITERS_LIST_WITHOUT_PRESET'

export const ACTION_GET_WRITERS_TOTALS = 'ACTION_GET_WRITERS_TOTALS'

export const ACTION_REFRESH_STATISTIC = 'ACTION_REFRESH_STATISTIC'

export const ACTION_GET_WRITERS_PAYMENTS_LIST = 'ACTION_GET_WRITERS_PAYMENTS_LIST'

export const ACTION_GET_WRITERS_PAYMENTS_TOTALS = 'ACTION_GET_WRITERS_PAYMENTS_TOTALS'

export const ACTION_GET_PAYOUTS_PRESETS_WRITER = 'ACTION_GET_PAYOUTS_PRESETS_WRITER'

export const ACTION_GET_PAYABLE = 'ACTION_GET_PAYABLE'

export const ACTION_CREATE_BATCH = 'ACTION_CREATE_BATCH'

export const ACTION_WRITER_CREDIT = 'ACTION_WRITER_CREDIT'

export const ACTION_PAY_PAYONEER = 'ACTION_PAY_PAYONEER'

export const ACTION_PAY_WIRE = 'ACTION_PAY_WIRE'

export const ACTION_PAY_MPESA = 'ACTION_PAY_MPESA'

// APPLICANT

export const ACTION_APPLICANT_DEACTIVATE = 'ACTION_APPLICANT_DEACTIVATE'

export const ACTION_APPLICANT_OPEN = 'ACTION_APPLICANT_OPEN'

export const ACTION_GET_PRESETS_APPLICANTE = 'ACTION_GET_PRESETS_APPLICANTE'

export const ACTION_APPROVE_APPLICANT = 'ACTION_APPROVE_APPLICANT'

export const GET_BALANCE = 'GET_BALANCE'

export const ACTION_GET_CREATE_PAYROLL_NOTE = 'ACTION_GET_CREATE_PAYROLL_NOTE'
