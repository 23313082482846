import { DashboardMenuBuilder } from '@/services/dashboardService'

import {
    ACTION_GET_COUNTS
} from './action-types'

import {
    SET_COUNTS,
    SET_LOADING_FULL_MENU
} from './mutation-types'

const dashboard = new DashboardMenuBuilder()

export const actions = {
    // GET COUNTS
    async [ACTION_GET_COUNTS]({ commit }) {
        commit(SET_LOADING_FULL_MENU, true)
        await dashboard.getCounters()
        commit(SET_COUNTS, dashboard.fullMenu)
        commit(SET_LOADING_FULL_MENU, false)
    }
}
