<template>
    <v-menu
        v-model="menuState"
        bottom
        offset-y
        :close-on-content-click="false"
        class="header_menu pb-2"
    >
        <template v-slot:activator="{ on, attrs }">
            <div
                v-bind="attrs"
                class="px-4 header_avatar"
                v-on="on"
            >
                <v-icon color="#82b1ff">
                    mdi-bell-outline
                </v-icon>
                <v-avatar
                    class="bookmark-counter"
                    size="25"
                    color="rgba(39, 174, 96, 0.1)"
                >
                    <span
                        class="text-subtitle-2"
                        style="color: rgb(39, 174, 96);"
                    >{{ total }}</span>
                </v-avatar>
            </div>
        </template>
        <template v-slot:default="{ on, attrs }">
            <div
                v-bind="attrs"
                class="tw-bg-white"
                v-on="on"
            >
                <div
                    v-if="list && list.length"
                    class="d-flex justify-space-between align-center w-full pa-5 tw-border-b tw-border-solid"
                >
                    <p class="font-weight-bold text-body-1 mb-0">
                        Notifications ({{ total }})
                    </p>
                    <span
                        style="cursor: pointer"
                        @click="deleteAll"
                    >
                        Clear all
                    </span>
                </div>

                <div
                    v-else
                    class="d-flex flex-column align-center w-full pt-5"
                >
                    <div class="mb-3">
                        <v-icon
                            size="50"
                            color="#82b1ff"
                        >
                            mdi-bell-outline
                        </v-icon>
                    </div>
                    <p class="text-subtitle-2 text-center">
                        The notifications are empty
                    </p>
                </div>
                <v-list class="notification tw-flex tw-flex-col tw-px-2">
                    <div
                        v-for="(item, index) in list"
                        :key="item.render_id"
                        style="cursor: pointer;"
                        @click="goToDetails(item.id)"
                    >
                        <div>
                            <div class="d-flex">
                                <v-avatar
                                    class="mr-2"
                                    color="rgba(39, 217, 31, .2)"
                                >
                                    <v-icon>
                                        {{ getIconByType(item.type) }}
                                    </v-icon>
                                </v-avatar>
                                <p class="mb-0 tw-self-center">
                                    <span
                                        class="font-weight-bold"
                                        style="cursor: pointer;"
                                    >
                                        {{ item.title }}
                                    </span>
                                </p>
                                <span class="ml-auto">
                                    <v-icon
                                        width="10px"
                                        @click.stop="deleteNotification(item.render_id)"
                                    >
                                        mdi-close
                                    </v-icon>
                                </span>
                            </div>
                            <div class="d-flex px-2 py-2 tw-bg-blue-100 tw-rounded-tr-xl tw-rounded-bl-xl tw-rounded-br-xl mt-2 p-2">
                                {{ item.description }}
                            </div>

                            <v-divider
                                v-if="index !== list.length - 1"
                                class="my-2"
                            />
                        </div>
                    </div>
                </v-list>
            </div>
        </template>
    </v-menu>
</template>

<script>
import { mapGetters, createNamespacedHelpers } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'
// import NewTransfer from '@/components/Header/NotificationTypes/NewTransfer'

import {
    ACTION_GET_NOTIFICATIONS,
    ACTION_DELETE_NOTIFICATION,
    ACTION_DELETE_NOTIFICATIONS_ALL
} from '@/store/modules/notifications/action-types';

const {
    mapActions
} = createNamespacedHelpers('notifications');

export default {
    components: {
        // NewTransfer
    },
    data() {
        return {
            menuState: false,
            search: {
                per_page: 15,
                page: 1
            }
        }
    },
    computed: {
        ...mapGetters('notifications', [
            'getterNotifications',
            'getterTotal'
        ]),
        list() {
            return this.getterNotifications || []
        },
        total() {
            return this.getterTotal
        }
    },
    // watch: {
    //     async menuState(val) {
    //         if (val) {
    //             await this.Notification.open()
    //             await this.Notification.getCount()
    //         }
    //     }
    // },
    async created() {
        await this.fetchNotifications()
    },
    methods: {
        ...mapActions({
            ACTION_GET_NOTIFICATIONS,
            ACTION_DELETE_NOTIFICATION,
            ACTION_DELETE_NOTIFICATIONS_ALL
        }),
        async fetchNotifications() {
            try {
                await this[ACTION_GET_NOTIFICATIONS]({ ...this.search })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async deleteNotification(id) {
            try {
                this[ACTION_DELETE_NOTIFICATION]({ id })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async deleteAll() {
            try {
                this[ACTION_DELETE_NOTIFICATIONS_ALL]()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        goToDetails(id) {
            if (!id) return
            const isOrderId = id.includes('S-')
            const isClientTicket = id.includes('TC-')
            const isWriterTicket = id.includes('TW-')
            if (isOrderId) {
                this.$router.push({
                    name: 'order-details',
                    params: {
                        id
                    }
                })
            }
            if (isClientTicket) {
                this.$router.push({
                    name: 'support-details',
                    params: {
                        id,
                        type: 'client'
                    }
                })
            }
            if (isWriterTicket) {
                this.$router.push({
                    name: 'support-details',
                    params: {
                        id,
                        type: 'writer'
                    }
                })
            }
        },
        getIconByType(type) {
            switch (type) {
            case 'default':
                return 'mdi-bell-badge-outline'
            case 'success':
                return 'mdi-check'
            case 'warning':
                return 'mdi-alert-box-outline'
            case 'error':
                return 'mdi-alert-circle-outline'

            default:
                return 'mdi-lightbulb-alert-outline'
            }
        }
    }
}
</script>

<style lang="scss">
.notification{
    right: 40px;
    width: 400px;
    max-height: 450px;
    overflow: auto;
}

</style>
