export default [
    {
        path: '/feedbacks',
        name: 'feedbacks',
        component: () => import(/* webpackChunkName: "feedbacks" */ '@/views/Feedbacks/Feedbacks'),
        meta: {
            requiresAuth: true,
            layout: 'dashboard-layout',
            name: 'Feedbacks',
            scrollToTop: true,
            permissions: [],
            keepAlive: true
        }
    },
    {
        path: '/feedbacks/:id',
        name: 'feedback-detail',
        component: () => import(/* webpackChunkName: "feedbacks" */ '@/views/Feedbacks/FeedbackDetails'),
        meta: {
            requiresAuth: true,
            name: 'feedback-detail',
            layout: 'dashboard-layout',
            breadcrumb() {
                const { id } = this.$route.params;

                return {
                    label: id,
                    parent: 'feedbacks'
                };
            },
            permissions: [],
            scrollToTop: true,
            keepAlive: true
        }
    }
]
