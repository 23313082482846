import Api from '@/helpers/api/index.js'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'

import {
    ACTION_CREATE_BOOKMARK,
    ACTION_DELETE_BOOKMARK,
    ACTION_GET_BOOKMARKS,
    ACTION_GET_BOOKMARKS_LOADMORE
} from './action-types'

import {
    DELETE_BOOKMARK,
    SET_BOOKMARK,
    SET_BOOKMARKS_LIST,
    SET_BOOKMARKS_LIST_LOADMORE
} from './mutation-types'

export const actions = {
    async [ACTION_GET_BOOKMARKS]({ commit, rootGetters }, payload) {
        const { data } = await Api.get('/api/bookmark/fetch-admin-bookmarks', removeEmptyParamsFromRequest({ admin_id: rootGetters['client/getterAdminId'], ...payload }))
        commit(SET_BOOKMARKS_LIST, data)
    },

    async [ACTION_GET_BOOKMARKS_LOADMORE]({ commit, rootGetters }, payload) {
        const { data } = await Api.get('/api/bookmark/fetch-admin-bookmarks', removeEmptyParamsFromRequest({ admin_id: rootGetters['client/getterAdminId'], ...payload }))
        commit(SET_BOOKMARKS_LIST_LOADMORE, data)
    },

    async [ACTION_CREATE_BOOKMARK]({ commit }, payload) {
        const { data } = await Api.post('/api/bookmark/create', payload)
        commit(SET_BOOKMARK, data)
    },

    async [ACTION_DELETE_BOOKMARK]({ commit }, payload) {
        await Api.delete('/api/bookmark/delete', { ...payload })
        commit(DELETE_BOOKMARK, { ...payload })
    }
}
