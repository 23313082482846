export default [
    {
        path: '/hr/clients',
        component: () => import(/* webpackChunkName: "client" */ '@/views/Clients/ClientsList'),
        meta: {
            requiresAuth: true,
            name: 'Clients',
            layout: 'dashboard-layout',
            scrollToTop: true,
            permissions: [],
            keepAlive: true
        },
        name: 'hr-clients'
    },
    {
        path: '/hr/clients/:id',
        component: () => import(/* webpackChunkName: "client" */ '@/views/Clients/ClientDetails'),
        meta: {
            requiresAuth: true,
            name: 'Client',
            layout: 'dashboard-layout',
            breadcrumb() {
                const { id } = this.$route.params;

                return {
                    label: id,
                    parent: 'hr-clients'
                };
            },
            permissions: [],
            scrollToTop: true,
            keepAlive: true
        },
        children: [
            {
                path: '',
                name: 'hr-clients-details',
                component: () => import(/* webpackChunkName: "client" */ '@/views/Clients/Tabs/Orders'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    permissions: [],
                    scrollToTop: true,
                    keepAlive: true
                }
            },
            {
                path: 'tickets',
                name: 'hr-clients-details-tickets',
                component: () => import(/* webpackChunkName: "client" */ '@/views/Clients/Tabs/Tickets'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    permissions: [],
                    keepAlive: true
                }
            },
            {
                path: 'friends',
                name: 'hr-clients-details-friends',
                component: () => import(/* webpackChunkName: "client" */ '@/views/Clients/Tabs/Friends.vue'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    permissions: [],
                    keepAlive: true
                }
            },
            {
                path: 'billing',
                name: 'hr-clients-details-billing',
                component: () => import(/* webpackChunkName: "client" */ '@/views/Clients/Tabs/Billing'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    permissions: [],
                    keepAlive: true
                }
            },
            {
                path: 'credits',
                name: 'hr-clients-details-credits',
                component: () => import(/* webpackChunkName: "client" */ '@/views/Clients/Tabs/Credits'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    permissions: [],
                    keepAlive: true
                }
            },
            {
                path: 'feedbacks',
                name: 'hr-clients-details-feedbacks',
                component: () => import(/* webpackChunkName: "client" */ '@/views/Clients/Tabs/Feedbacks'),
                meta: {
                    requiresAuth: true,
                    layout: 'dashboard-layout',
                    permissions: [],
                    keepAlive: true
                }
            }
        ]
    }
]
