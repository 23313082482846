// import { SchemaAdmin } from '@/models/SchemaAdmin.js'
import { cloneDeep } from 'lodash'

export const initialState = {
    form_data: {},
    emailsList: [],
    selectEmail: null
}

export const state = cloneDeep(initialState)
