/* eslint-disable no-unused-vars */
import { cloneDeep } from 'lodash'
import Api from '@/helpers/api/index.js'
// import supermarket from '@/assets/images/svg/001-supermarket.svg'
// import chat from '@/assets/images/svg/001-chat.svg'
// import user from '@/assets/images/svg/001-user.svg'
// import card from '@/assets/images/svg/001-card.svg'
// import users from '@/assets/images/svg/users.svg'
// import access from '@/assets/images/svg/access.svg'
// import feedback from '@/assets/images/svg/feedback.svg'
import profile from '@/assets/images/svg/profile.svg'
// import email from '@/assets/images/svg/email.svg'
// import promo from '@/assets/images/svg/promo.svg'
// import batch from '@/assets/images/svg/document.svg'
// import settings from '@/assets/images/svg/settings.svg'

import supermarketService from '@/assets/images/svg/services/001-supermarket.svg'
import chatService from '@/assets/images/svg/services/001-chat.svg'
import userService from '@/assets/images/svg/services/001-user.svg'
import usersService from '@/assets/images/svg/services/users.svg'
import accessService from '@/assets/images/svg/services/access.svg'
import cardService from '@/assets/images/svg/services/001-card.svg'
import feedbackService from '@/assets/images/svg/services/feedback.svg'
import promoService from '@/assets/images/svg/services/promo.svg'
// import batchService from '@/assets/images/svg/services/document.svg'
import emailService from '@/assets/images/svg/services/email.svg'
import settingsService from '@/assets/images/svg/services/settings.svg'
import target from '@/assets/images/svg/services/target-account.svg'
import messageLog from '@/assets/images/svg/services/message-log.svg'

import { routes } from '@/router/'

export class DashboardMenuBuilder {
    static instance;

    constructor() {
        this.response = []
    }

    getCounters = async (type) => {
        if (type === 'support') {
            const responses = await Promise.all([
                Api.get(`/api/${type}/client/list-presets`),
                Api.get(`/api/${type}/writer/list-presets`)
            ])
            // eslint-disable-next-line prefer-destructuring
            this.response[`${type}-client`] = responses[0].data
            // eslint-disable-next-line prefer-destructuring
            this.response[`${type}-writer`] = responses[1].data
        } else if (type === 'order') {
            const responses = await Promise.all([
                Api.get('/api/order/list-presets'),
                Api.get('/api/order-batch/list-presets')
            ])
            // eslint-disable-next-line prefer-destructuring
            this.response['order'] = responses[0].data
            // eslint-disable-next-line prefer-destructuring
            this.response['order-batch'] = responses[1].data
        } else if (type === 'client') {
            const responses = await Promise.all([
                Api.get('/api/client/list-presets'),
                Api.get('/api/client-invitation/list-presets')
            ])
            // eslint-disable-next-line prefer-destructuring
            this.response['client'] = responses[0].data
            // eslint-disable-next-line prefer-destructuring
            this.response['client-invitation'] = responses[1].data
        } else {
            const { data } = await Api.get(`/api/${type}/list-presets`)
            this.response[type] = data
        }
    }

    // get orderMenuList() {
    //     const route_name = 'orders'
    //     const orderList = this.response.order?.reduce((acc, curr) => {
    //         acc.push(
    //             {
    //                 route_name,
    //                 text: curr.title,
    //                 counter: curr.counters?.count,
    //                 query: curr.value
    //             }
    //         )
    //         return acc
    //     }, [])
    //     return orderList
    // }

    get orderMenuList() {
        const orders = 'orders'
        const orderList = this.response['order']?.reduce((acc, curr) => {
            acc.push(
                {
                    route_name: orders,
                    text: curr.title,
                    counter: curr.counters?.count,
                    query: curr.value
                }
            )
            return acc
        }, []) || []
        const orderBatch = 'batch'
        const orderBatchList = this.response['order-batch']?.reduce((acc, curr) => {
            acc.push(
                {
                    route_name: orderBatch,
                    text: curr.title,
                    counter: curr.counters?.count,
                    query: curr.value
                }
            )
            return acc
        }, []) || []
        const ordersList = {
            order: {
                route_name: orders,
                text: 'Orders',
                list: orderList
            },
            order_batch: {
                route_name: orderBatch,
                text: 'Billing',
                list: orderBatchList
            }
        }
        return ordersList
    }

    get clientMenuList() {
        const client = 'hr-clients'
        const clientList = this.response.client?.reduce((acc, curr) => {
            acc.push(
                {
                    route_name: client,
                    text: curr.title,
                    counter: curr.counters?.count,
                    query: curr.value
                }
            )
            return acc
        }, []) || []
        const clientInvitation = 'client-invitation'
        const ClientInvitationList = this.response['client-invitation']?.reduce((acc, curr) => {
            acc.push(
                {
                    route_name: clientInvitation,
                    text: curr.title,
                    counter: curr.counters?.count,
                    query: curr.value
                }
            )
            return acc
        }, []) || []
        const clientsList = {
            clients: {
                route_name: client,
                text: 'Clients',
                list: clientList
            },
            clientInvitation: {
                route_name: clientInvitation,
                text: 'Client Invitation',
                list: ClientInvitationList
            }
        }
        return clientsList
    }

    get supportMenu() {
        const writer_route_name = 'support-writer'
        const writerlist = this.response['support-writer']?.reduce((acc, curr) => {
            acc.push(
                {
                    route_name: writer_route_name,
                    text: curr.title,
                    counter: curr.counters?.count,
                    query: curr.value
                }
            )
            return acc
        }, []) || []
        const client_route_name = 'support-client'
        const clientlist = this.response['support-client']?.reduce((acc, curr) => {
            acc.push(
                {
                    route_name: client_route_name,
                    text: curr.title,
                    counter: curr.counters?.count,
                    query: curr.value
                }
            )
            return acc
        }, []) || []
        const supportList = {
            writer: {
                route_name: writer_route_name,
                text: 'Writers',
                list: writerlist
            },
            client: {
                route_name: client_route_name,
                text: 'Clients',
                list: clientlist
            },
            forum: {
                route_name: 'support-forum',
                text: 'Forum',
                cols: 12
            }
        }
        return supportList
    }

    // eslint-disable-next-line class-methods-use-this
    get supportMenuWithoutCount() {
        const writer_route_name = 'support-writer'
        const client_route_name = 'support-client'
        const supportList = {
            writer: {
                route_name: writer_route_name,
                text: 'Writers'
            },
            client: {
                route_name: client_route_name,
                text: 'Clients'
            },
            forum: {
                route_name: 'support-forum',
                text: 'Forum',
                cols: 12
            }
        }
        return supportList
    }

    // eslint-disable-next-line class-methods-use-this
    get creditsMenu() {
        return [
            {
                route_name: 'writer-credits',
                text: 'Writers'
            },
            {
                route_name: 'client-credits',
                text: 'Clients'
            }
        ]
    }

    get writersMenu() {
        const writer_route_name = 'writers'
        const writerlist = this.response.writer?.reduce((acc, curr) => {
            acc.push(
                {
                    route_name: writer_route_name,
                    text: curr.title,
                    counter: curr.counters?.count,
                    query: curr.value
                }
            )
            return acc
        }, []) || []
        return writerlist
    }

    // eslint-disable-next-line class-methods-use-this
    get accessMenu() {
        return [
            {
                route_name: 'permissions',
                text: 'Permissions'
            },
            {
                route_name: 'roles',
                text: 'Roles'
            },
            {
                route_name: 'admins',
                text: 'Admins'
            }
        ]
    }

    // eslint-disable-next-line class-methods-use-this
    get emailMenu() {
        return [
            {
                route_name: 'emails',
                text: 'Create email'
            },
            {
                route_name: 'emails-list',
                text: 'Mailing list'
            }
        ]
    }

    get feedbacksMenu() {
        const route_name = 'feedbacks'
        const feedbacksList = this.response.testimonial?.reduce((acc, curr) => {
            acc.push(
                {
                    route_name,
                    text: curr.title,
                    counter: curr.counters.count,
                    query: curr.value
                }
            )
            return acc
        }, [])
        return feedbacksList
    }

    get Payroll() {
        const route_name = 'payroll'
        const PayrollList = this.response['writer-payout']?.reduce((acc, curr) => {
            acc.push(
                {
                    route_name,
                    text: curr.title,
                    counter: curr.counters.count,
                    query: curr.value
                }
            )
            return acc
        }, [])
        return PayrollList
    }

    // get Batch() {
    //     const route_name = 'batch'
    //     const BatchList = this.response['order-batch']?.reduce((acc, curr) => {
    //         acc.push(
    //             {
    //                 route_name,
    //                 text: curr.title,
    //                 counter: curr.counters.count,
    //                 query: curr.value
    //             }
    //         )
    //         return acc
    //     }, [])
    //     return BatchList
    // }

    get Discount() {
        const route_name = 'promocodes'
        const PromocodesList = this.response.discount?.reduce((acc, curr) => {
            acc.push(
                {
                    route_name,
                    text: curr.title,
                    counter: curr.counters.count,
                    query: curr.value
                }
            )
            return acc
        }, [])
        return PromocodesList
    }

    // eslint-disable-next-line class-methods-use-this
    get crudsMenu() {
        return [
            {
                route_name: 'project-types',
                text: 'Project types'
            }
        ]
    }

    // eslint-disable-next-line class-methods-use-this
    get settingsMenu() {
        return [
            {
                route_name: 'settings-variables',
                text: 'Variables'
            },
            {
                route_name: 'site-settings',
                text: 'Site Settings'
            }
        ]
    }

    // eslint-disable-next-line class-methods-use-this
    get mailMenu() {
        return [
            // {
            //     route_name: 'mails',
            //     text: 'Mails Remarketing'
            // },
            {
                route_name: 'mailing-list',
                text: 'Mailing List'
            },
            {
                route_name: 'mailing-templates',
                text: 'Mailing Templates'
            },
            {
                route_name: 'mails-log',
                text: 'Mailing Log'
            }
        ]
    }

    get LoginSession() {
        const route_name = 'login-session'
        const LoginSession = this.response['login-session']?.reduce((acc, curr) => {
            acc.push(
                {
                    route_name,
                    text: curr.title,
                    counter: curr.counters.count,
                    query: curr.value
                }
            )
            return acc
        }, [])
        return LoginSession
    }

    // eslint-disable-next-line class-methods-use-this
    fullMenu(menu) {
        const storage = JSON.parse(localStorage.getItem('vuex'))
        const user_roles = storage.client.data.roles.map((it) => it.permissions.map((it) => it.name)).flat()

        const data = cloneDeep(menu)
        function deleteIfNoPermission(data) {
            // eslint-disable-next-line no-restricted-syntax
            for (const key in data) {
                if (Object.hasOwnProperty.call(data, key)) {
                    const element = data[key];
                    const find = routes.find((el) => el.name === element.route_name)
                    const { permissions } = find.meta

                    if (permissions && Array.isArray(permissions)) {
                        if (data[key].list && Array.isArray(data[key].list)) {
                            data[key].list = data[key].list.filter((it) => {
                                const find = routes.find((el) => el.name === it.route_name)

                                const { permissions } = find.meta
                                if (!permissions) {
                                    return it
                                }
                                return permissions.every((val) => user_roles.includes(val))
                            })
                        }
                        if (data[key].list && !Array.isArray(data[key].list)) {
                            deleteIfNoPermission(data[key].list)
                        }

                        if (permissions.every((val) => user_roles.includes(val)) === false) {
                            delete data[key]
                        }
                    }
                }
            }
        }
        deleteIfNoPermission(data)
        return data
    }

    // get fullMenuWithoutPermission() {
    //     return {
    //         order: {
    //             title: 'Orders',
    //             route_name: 'orders',
    //             icon: supermarket,
    //             list: this.orderMenuList
    //         },
    //         support: {
    //             title: 'Support',
    //             icon: chat,
    //             list: this.supportMenu
    //         },
    //         hr: {
    //             title: 'Writers',
    //             route_name: 'writers',
    //             icon: user,
    //             list: this.writersMenu
    //         },
    //         clients: {
    //             title: 'Clients',
    //             route_name: 'hr-clients',
    //             icon: users,
    //             list: this.clientMenuList
    //         },
    //         access: {
    //             title: 'Access',
    //             icon: access,
    //             list: this.accessMenu
    //         },
    //         payroll: {
    //             title: 'Payroll',
    //             route_name: 'payroll',
    //             icon: card
    //         },
    //         feedbacks: {
    //             title: 'Feedbacks',
    //             route_name: 'feedbacks',
    //             icon: feedback,
    //             list: this.feedbacksMenu
    //         },
    //         promocodes: {
    //             title: 'Promocodes',
    //             route_name: 'promocodes',
    //             icon: promo
    //         },
    //         // emails: {
    //         //     title: 'Emails',
    //         //     icon: email,
    //         //     list: this.emailMenu
    //         // },
    //         batch: {
    //             title: 'Batch',
    //             route_name: 'batch',
    //             icon: batch
    //         },
    //         credits: {
    //             title: 'Credits',
    //             icon: chat,
    //             list: this.creditsMenu
    //         },
    //         profile: {
    //             title: 'Profile',
    //             route_name: 'user_setting',
    //             icon: profile
    //         },
    //         settingsMenu: {
    //             title: 'Settings',
    //             icon: settings,
    //             list: this.settingsMenu
    //         },
    //         mailMenu: {
    //             title: 'Mail',
    //             icon: email,
    //             list: this.mailMenu
    //         }
    //         // cruds: {
    //         //     title: 'CRUD',
    //         //     icon: supermarket,
    //         //     list: this.crudsMenu
    //         // }
    //     }
    // }
    //
    // get fullMenuWithoutPermissionAndPresets() {
    //     return {
    //         order: {
    //             title: 'Orders',
    //             route_name: 'orders',
    //             icon: supermarket
    //         },
    //         support: {
    //             title: 'Support',
    //             icon: chat,
    //             list: this.supportMenuWithoutCount
    //         },
    //         hr: {
    //             title: 'Writers',
    //             route_name: 'writers',
    //             icon: user
    //         },
    //         clients: {
    //             title: 'Clients',
    //             route_name: 'hr-clients',
    //             icon: users
    //         },
    //         access: {
    //             title: 'Access',
    //             icon: access,
    //             list: this.accessMenu
    //         },
    //         payroll: {
    //             title: 'Payroll',
    //             route_name: 'payroll',
    //             icon: card
    //         },
    //         feedbacks: {
    //             title: 'Feedbacks',
    //             route_name: 'feedbacks',
    //             icon: feedback,
    //             list: this.feedbacksMenu
    //         },
    //         promocodes: {
    //             title: 'Promocodes',
    //             route_name: 'promocodes',
    //             icon: promo
    //         },
    //         // emails: {
    //         //     title: 'Emails',
    //         //     icon: email,
    //         //     list: this.emailMenu
    //         // },
    //         batch: {
    //             title: 'Batch',
    //             route_name: 'batch',
    //             icon: batch
    //         },
    //         credits: {
    //             title: 'Credits',
    //             icon: chat,
    //             list: this.creditsMenu
    //         },
    //         profile: {
    //             title: 'Profile',
    //             route_name: 'user_setting',
    //             icon: profile
    //         },
    //         settingsMenu: {
    //             title: 'Settings',
    //             icon: settings,
    //             list: this.settingsMenu
    //         },
    //         mailMenu: {
    //             title: 'Mail',
    //             icon: email,
    //             list: this.mailMenu
    //         }
    //         // cruds: {
    //         //     title: 'CRUD',
    //         //     icon: supermarket,
    //         //     list: this.crudsMenu
    //         // }
    //     }
    // }

    get categoryHeaderMenu() {
        return {
            order: {
                title: 'Orders',
                // route_name: 'orders',
                icon: supermarketService,
                list: this.orderMenuList,
                type: 'order'
            },
            // batch: {
            //     title: 'Billing',
            //     route_name: 'batch',
            //     icon: batchService,
            //     list: this.Batch,
            //     type: 'order-batch'
            // },
            support: {
                title: 'Support',
                icon: chatService,
                list: this.supportMenu,
                type: 'support'
            },
            clients: {
                title: 'Clients',
                // route_name: 'hr-clients',
                icon: usersService,
                list: this.clientMenuList,
                type: 'client'
            },
            hr: {
                title: 'Writers',
                route_name: 'writers',
                icon: userService,
                list: this.writersMenu,
                type: 'writer'
            },
            payroll: {
                title: 'Payroll',
                route_name: 'payroll',
                icon: cardService,
                list: this.Payroll,
                type: 'writer-payout'
            },
            feedbacks: {
                title: 'Feedbacks',
                route_name: 'feedbacks',
                icon: feedbackService,
                list: this.feedbacksMenu,
                type: 'testimonial'
            },
            access: {
                title: 'User Management',
                icon: accessService,
                list: this.accessMenu,
                type: ''
            },
            promocodes: {
                title: 'Promocodes',
                route_name: 'promocodes',
                icon: promoService,
                type: 'discount',
                list: this.Discount
            },
            // emails: {
            //     title: 'Emails',
            //     icon: email,
            //     list: this.emailMenu
            // },
            credits: {
                title: 'Credits',
                icon: chatService,
                list: this.creditsMenu,
                type: ''
            },
            mailMenu: {
                title: 'Mail',
                icon: emailService,
                list: this.mailMenu,
                type: ''
            },
            settingsMenu: {
                title: 'Settings',
                icon: settingsService,
                list: this.settingsMenu,
                type: ''
            },
            loginSession: {
                title: 'Login Session',
                route_name: 'login-session',
                icon: target,
                list: this.LoginSession,
                type: 'login-session'
            },
            messageLog: {
                title: 'Message Log',
                route_name: 'message-log',
                icon: messageLog,
                list: '',
                type: ''
            }
        }
    }

    // eslint-disable-next-line class-methods-use-this
    get userMenu() {
        return {
            profile: {
                title: 'Profile',
                route_name: 'user_setting',
                icon: profile
            }
        }
    }
}
