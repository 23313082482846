import Api from '@/helpers/api/index.js'

import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'

import {
    ACTION_CREATE_ROLE,
    ACTION_DELETE_ROLE,
    ACTION_GET_ROLES_LIST,
    ACTION_UPDATE_ROLE,
    ACTION_UPDATE_ROLE_PERMISSIONS,
    GET_ALL_PERMISIONS
} from './action-types'

import {
    DELETE_ROLE,
    SET_ROLES_LIST,
    UPDATE_ROLE,
    UPDATE_ROLE_PERMISSIONS,
    SET_ALL_PERMISSIONS
} from './mutation-types'

export const actions = {
    async [GET_ALL_PERMISIONS]({ commit }) {
        const { data } = await Api.get('/api/permissions/permission/list-by-params', {
            page: 1,
            per_page: 100
        })
        commit(SET_ALL_PERMISSIONS, data.data)
    },
    async [ACTION_GET_ROLES_LIST]({ commit }, payload) {
        const { data } = await Api.get('/api/permissions/role/list-by-params', removeEmptyParamsFromRequest(payload))
        commit(SET_ROLES_LIST, data)
    },
    async [ACTION_UPDATE_ROLE_PERMISSIONS]({ commit }, payload) {
        const { data } = await Api.put('/api/permissions/role/update', removeEmptyParamsFromRequest(payload))
        commit(UPDATE_ROLE_PERMISSIONS, data)
    },
    async [ACTION_CREATE_ROLE]({ dispatch }, payload) {
        await Api.post('/api/permissions/role/create', removeEmptyParamsFromRequest(payload.data));
        dispatch(ACTION_GET_ROLES_LIST, payload.query)
    },
    async [ACTION_DELETE_ROLE]({ commit }, payload) {
        await Api.delete('/api/permissions/role/delete', payload);
        commit(DELETE_ROLE, payload.id)
    },
    async [ACTION_UPDATE_ROLE]({ commit }, payload) {
        const { data } = await Api.put('/api/permissions/role/update', removeEmptyParamsFromRequest(payload))
        commit(UPDATE_ROLE, payload)
        return data
    }
}
