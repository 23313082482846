import { getField } from 'vuex-map-fields'

export const getters = {
    getField,
    getterEmailsList(state) {
        return state.emailsList
    },
    getterSelectEmail(state) {
        return state.selectEmail
    }
}
