import { updateField } from 'vuex-map-fields';
import {
    CHANGE_AVATAR,
    UPDATE_SELF,
    LOGOUT_ADMIN,
    SET_ADMIN,
    SET_TOUCH_INTERVAL,
    SET_TWOFA_STATUS,
    CLEAR_TOUCH_INTERVAL
} from './mutation-types'

export const mutations = {
    updateField,
    [SET_ADMIN](state, payload) {
        state.auth = true
        state.data = { ...payload }
    },
    [UPDATE_SELF](state, payload) {
        state.data = {
            ...state.data,
            ...payload
        }
    },
    [CHANGE_AVATAR](state, payload) {
        state.data.avatar = payload.data
    },
    [LOGOUT_ADMIN](state) {
        state.auth = false
        state.data = {}
    },
    [SET_TOUCH_INTERVAL](state, payload) {
        state.touchInterval = payload
    },
    [SET_TWOFA_STATUS](state, payload) {
        state.data.twofa = payload
    },
    [CLEAR_TOUCH_INTERVAL](state) {
        clearInterval(state.touchInterval)
    }
}
