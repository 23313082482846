export const initialState = {
    /* clients */
    clientsCreditsList: [],
    clientsLastPage: 0,
    clientsTotal: 0,
    clientsTotalCredit: 0,
    clientsTotalDebit: 0,
    clientsCreditsListLoading: true,
    clientsCreditsTotalsLoading: true,
    /* clients */

    /* writers */
    writersCreditsList: [],
    writersLastPage: 0,
    writersTotal: 0,
    writersTotalCredit: 0,
    writersTotalDebit: 0,
    writersCreditsListLoading: true,
    writersCreditsTotalsLoading: true
    /* writers */
}

export const state = { ...initialState }
